/* global loggedInUserId */
import axiosInstance from '../../../Api';

var getCreativeBriefs = function (businessProfileId = location.pathname.toString().split('/')[4]) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "GET",
    url: deltax.getDeltaxApiBaseUrl + "businessProfiles/" + businessProfileId + "/brief"
  });
  return promise;
}

var getInputGroupsWithEntitiesByBriefId = function (briefId, briefPhaseId) {
  var promise = axiosInstance(
    {
      headers: { "X-Bypass-Login": "true" },
      method: "GET",
      url: deltax.getDeltaxApiBaseUrl + "businessProfiles/" + location.pathname.toString().split('/')[4] + "/InputGroups",
      params: {
        includeChildEntities: true,
        briefId: briefId,
        briefPhaseId: briefPhaseId
      }
    });
  return promise;
}

var getUserDetails = function (userId) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "GET",
    url: deltax.getDeltaxApiBaseUrl + "Users/" + userId
  });
  return promise;
}

var getBusinessProfileDetails = function () {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "GET",
    url: deltax.getDeltaxApiBaseUrl + "BusinessProfiles/" + location.pathname.toString().split('/')[4]
  });
  return promise;
}

var uploadFiles = function (inputId, formData) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "POST",
    url: deltax.getDeltaxApiBaseUrl + "BusinessProfiles/" + location.pathname.toString().split('/')[4] + "/Inputs/" + inputId + "/UploadFile",
    data: formData,
    params: {
      userId: loggedInUserId
    }
  });
  return promise;
}

var downloadFile = function (relativeFilePath, fileType) {
  var url = deltax.downloadFile;
  $.fileDownload(url, {
    timeout: 180,
    data: {
      relativeFilePath: relativeFilePath,
      fileType: fileType
    },
    httpMethod: 'get'
  }).done(function () {
  });
  return false;
}

var deleteFile = function (inputId, id) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "DELETE",
    url: deltax.getDeltaxApiBaseUrl + "BusinessProfiles/" + location.pathname.toString().split('/')[4] + "/Inputs/" + inputId + "/Attachment/" + id
  });
  return promise;
}

var addInput = function (inputObject) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "POST",
    url: deltax.getDeltaxApiBaseUrl + "BusinessProfiles/" + location.pathname.toString().split('/')[4] + "/Inputs",
    params: {
      businessProfileId: location.pathname.toString().split('/')[4]
    },
    data: {
      Title: inputObject.title,
      InputGroupId: inputObject.inputGroupId,
      Description: inputObject.description
    }
  });
  return promise;
}

var getFilesFromDrive = function (authToken, id) {
  var url = "https://www.googleapis.com/drive/v3/files/" + id;
  var promise = axiosInstance({
    headers: {
      Authorization: "Bearer " + authToken
    },
    method: "GET",
    url: url
  });
  return promise;
}

var uploadFilesByUrl = function (inputId, url) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "POST",
    url: deltax.getDeltaxApiBaseUrl + "BusinessProfiles/" + location.pathname.toString().split('/')[4] + "/Inputs/" + inputId + "/UploadUrl",
    data: {
      userId: loggedInUserId,
      creativeLink: url
    }
  });
  return promise;
}

var getObjectives = function (businessProfileId) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "GET",
    url: deltax.getDeltaxApiBaseUrl + "BusinessProfiles/" + businessProfileId + "/BudgetCenter/Objectives"
  });
  return promise;
}

var getBriefPhases = function (businessProfileId = location.pathname.toString().split('/')[4]) {
  var promise = axiosInstance({
    headers: { "X-Bypass-Login": "true" },
    method: "GET",
    url: deltax.getDeltaxApiBaseUrl + "businessProfiles/" + businessProfileId + "/briefPhases"
  });
  return promise;
}

export const GetCreativeBriefs = getCreativeBriefs;
export const GetUserDetails = getUserDetails;
export const GetBusinessProfileDetails = getBusinessProfileDetails;
export const UploadFiles = uploadFiles;
export const GetInputGroupsWithEntitiesByBriefId = getInputGroupsWithEntitiesByBriefId;
export const AddInput = addInput;
export const DownloadFile = downloadFile;
export const GetFilesFromDrive = getFilesFromDrive;
export const UploadFilesByUrl = uploadFilesByUrl;
export const GetObjectives = getObjectives;
export const DeleteFile = deleteFile;
export const GetBriefPhases = getBriefPhases;
