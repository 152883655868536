<template>
<div>
<div>
   <i-tabs type="card" :class="{'tabs-padding': !$parent.$parent.isSummaryTabOpen}" v-model="activeTab" ref="mediaPlanTabs" :animated="false">
             <i-tabpane label="Digital" name="digital" :icon="(!$parent.$parent.isDigitalLineItemsValid ? 'fa fa-warning font-awesome-style' : '')">
                <div v-if="$parent.$parent.spinner" style="text-align: center">
                  <i class="fa fa-spinner fa-spin fa-3x"></i>
                </div>
                <div :class="{ 'enable-button-padding': !showDigitalLineItems }">
                <button
                    type="button"
                    class="btn create-btn btn-success button-style"
                    v-on:click="enableDigitalHOTtable"
                    :class="{ 'hide-button': $parent.$parent.spinner || showDigitalLineItems }"
                    >Enable</button>
                </div>
                  <bulk-lineItem
                  :class="{ 'hide-element': $parent.$parent.spinner || !showDigitalLineItems }"
                  ref="hotTable"
                  :mediaPlanObject="mediaPlanObject"
                  :dataParameterPricingTypeMapping="dataParameterPricingTypeMapping.digital"
                  :lineItemDataParameters="lineItemDataParameters.digital || [] "
                  :lineItemTypes="lineItemTypes.digital || []"
                  :budgetCenterConfiguration="budgetCenterConfiguration"
                  @setSummaryTabData="setSummaryTabData"
                  @onSaveSuccessOfMediaPlan="onSaveSuccessOfMediaPlan"
                  @onSaveFailOfMediaPlan="onSaveFailOfMediaPlan"
                  @calculateLineItemTotalBudget="calculateLineItemTotalBudget"></bulk-lineItem>
              </i-tabpane>
              <i-tabpane label="TV" name="tv" v-if="isOfflineMediaPlanEnabled" :icon="(!isTelevisionLineItemsValid ? 'fa fa-warning font-awesome-style' : '')">
                <tv-lineItem ref="televisionLineItem"
                :mediaPlanObject="mediaPlanObject"
                @calculateLineItemTotalBudget="calculateLineItemTotalBudget"
                :budgetCenterConfiguration="budgetCenterConfiguration"
                :dataParameterPricingTypeMapping="dataParameterPricingTypeMapping.tv || []"
                :benchMarkList="lineItemDataParameters.tv || []"
                :lineItemTypes="lineItemTypes.tv || []"
                ></tv-lineItem>
              </i-tabpane>
              <i-tabpane label="Radio" name="radio" v-if="isOfflineMediaPlanEnabled" :icon="(!isRadioLineItemsValid ? 'fa fa-warning font-awesome-style' : '')" >
                <radio-lineItem ref="radioLineItem"
                :mediaPlanObject="mediaPlanObject"
                @calculateLineItemTotalBudget="calculateLineItemTotalBudget"
                :budgetCenterConfiguration="budgetCenterConfiguration"
                :dataParameterPricingTypeMapping="dataParameterPricingTypeMapping.radio || []"
                :benchMarkList="lineItemDataParameters.radio || []"
                :lineItemTypes="lineItemTypes.radio || []"
                ></radio-lineItem>
              </i-tabpane>
              <i-tabpane label="Press" name="press" v-if="isOfflineMediaPlanEnabled" :icon="(!isPressLineItemsValid ? 'fa fa-warning font-awesome-style' : '')">
                <press-lineItem
                ref="pressLineItem"
                :mediaPlanObject="mediaPlanObject"
                @calculateLineItemTotalBudget="calculateLineItemTotalBudget"
                :budgetCenterConfiguration="budgetCenterConfiguration"
                :dataParameterPricingTypeMapping="dataParameterPricingTypeMapping.press || []"
                :benchMarkList="lineItemDataParameters.press || []"
                :lineItemTypes="lineItemTypes.press || []"
                ></press-lineItem>
              </i-tabpane>
              <i-tabpane label="OOH" name="ooh" v-if="isOfflineMediaPlanEnabled" :icon="(!isOOHLineItemsValid ? 'fa fa-warning font-awesome-style' : '')">
                <outOfHome-lineItem
                ref="oohLineItem"
                :mediaPlanObject="mediaPlanObject"
                @calculateLineItemTotalBudget="calculateLineItemTotalBudget"
                :budgetCenterConfiguration="budgetCenterConfiguration"
                :dataParameterPricingTypeMapping="dataParameterPricingTypeMapping.ooh || []"
                :benchMarkList="lineItemDataParameters.ooh || []"
                :lineItemTypes="lineItemTypes.ooh || []"
                ></outOfHome-lineItem>
              </i-tabpane>
              <i-tabpane label="Cinema" name="cinema" v-if="isOfflineMediaPlanEnabled" :icon="(!isCinemaLineItemsValid ? 'fa fa-warning font-awesome-style' : '')">
                <cinema-lineItem
                ref="cinemaLineItem"
                :mediaPlanObject="mediaPlanObject"
                @calculateLineItemTotalBudget="calculateLineItemTotalBudget"
                :budgetCenterConfiguration="budgetCenterConfiguration"
                :dataParameterPricingTypeMapping="dataParameterPricingTypeMapping.cinema || []"
                :benchMarkList="lineItemDataParameters.cinema || []"
                :lineItemTypes="lineItemTypes.cinema || []"
                ></cinema-lineItem>
              </i-tabpane>
              <i-tabpane label="Magazine" name="magazine" v-if="isOfflineMediaPlanEnabled" :icon="(!isMagazineLineItemsValid ? 'fa fa-warning font-awesome-style' : '')">
                <magazine-lineItem
                ref="magazineLineItem"
                :mediaPlanObject="mediaPlanObject"
                @calculateLineItemTotalBudget="calculateLineItemTotalBudget"
                :budgetCenterConfiguration="budgetCenterConfiguration"
                :dataParameterPricingTypeMapping="dataParameterPricingTypeMapping.magazine || []"
                :benchMarkList="lineItemDataParameters.magazine || []"
                :lineItemTypes="lineItemTypes.magazine || []"
                ></magazine-lineItem>
              </i-tabpane>

             <div slot="extra" class="side-slots-in-Tabs">
                <span class=" margin-right-five" v-if="activeTab == 'digital'">
                  <i class="fa fa-external-link icon-size"></i>
                </span>
                <i-input
                  v-if="activeTab == 'digital'"
                  class="margin-landing-page modal-label"
                  :disabled="!isEditableMediaPlan()"
                  v-model="mediaPlanLandingPageLink"
                  @on-change="validateMediaPlanDetails"
                  placeholder="Landing Url"
                />
                <span class="margin-landing-page-warning-icon" v-if="activeTab == 'digital'">
                  <i-tooltip content="URL must start from http(s)" placement="bottom">
                    <span v-show="!validMediaPlanLandingPageLink" class="exclamation-sign" style="margin-right: 30px;">!</span>
                  </i-tooltip>
                </span>
                <i-poptip placement="left" v-model="showRecommendationTooltip" v-if="activeTab == 'digital' && isEditableMediaPlan()" :class="isEditableMediaPlan()?'editable-plan':'non-editable'" style="margin-right:18px;">
                  <div slot="title">
                      <h4 class="popover-title popover-title-padding">AI Recommended Plan <a class="cross-icon-style" @click="closeRecommendationsTooltip">x</a>
                      </h4>
                  </div>
                  <div :class="enableUserPromptAnimation == true?'pulse-slow':''" v-if="activeTab == 'digital'" v-show="showRecommendationIcon">
                    <span class="plan-recommendation-bulb" @click="recommendationPopupEnable" v-if="activeTab == 'digital'" :class="isEditableMediaPlan()?'editable-plan':'non-editable'">
                      <i class="fa fa-lightbulb-o recommendation-icon-size"></i>
                      <plan-recommendation
                      ref="planRecommendation"
                      @addLineItemRecommendations="addLineItemRecommendations"
                      :planRecommendationTableData="planRecommendationTableData">
                      </plan-recommendation>
                    </span>
                  </div>
                </i-poptip>
                <button
                  v-if="activeTab !='digital'"
                  type="button"
                  :class="isEditableMediaPlan()?'editable-plan scheduler-button-style':'non-editable scheduler-button-style'"
                  @click="schedulerPopupEnable"
                >
                 <div style="padding: 2px;"><i class="fa fa-calendar"></i>
                  Scheduler</div>
                </button>
             <span :class="isEditableMediaPlan()?'editable-plan':'non-editable'">
              <i-tooltip content="Import LineItems" placement="bottom" v-if="activeTab == 'digital'">
                  <a @click="linkLineItemsPopupEnable">
                    <i class="fa fa-upload icon-size" style="cursor:pointer;"></i>
                  </a>
              </i-tooltip>
             </span>
            <span :class="isEditableMediaPlan()?'editable-plan select-columns-span':'non-editable select-columns-span'">
              <i-tooltip content="Select Columns" placement="bottom">
                  <a @click="selectColumnPopUpEnable">
                    <i class="fa fa-columns select-columns-class select-columns-padding"></i>
                  </a>
              </i-tooltip>
            </span>
            </div>
          </i-tabs>
        </div>
</div>
</template>
<script>
import {
  locale,
  Tabs,
  TabPane,
  Tooltip,
  Input,
  Poptip
} from "iview";
import lang from "iview/dist/locale/en-US"; // iview is default chinese
import BulkLineItem from "./BulkLineItem.vue";
import TelevisionLineItem from "./TelevisionLineItem.vue";
import RadioLineItem from "./RadioLineItem.vue";
import PressLineItem from "./PressLineItem.vue";
import OutOfHomeLineItem from "./OutOfHomeLineItem.vue";
import CinemaLineItem from "./CinemaLineItem.vue";
import MagazineLineItem from "./MagazineLineItem.vue"
import PlanRecommendation from "./PlanRecommendation.vue";
import { ApiService } from ".././Services/mediaPlanBulkCreateApiService.js";
locale(lang);
export default {
  components: {
    "i-tabs": Tabs,
    "i-tabpane": TabPane,
    "i-tooltip": Tooltip,
    "bulk-lineItem": BulkLineItem,
    "tv-lineItem": TelevisionLineItem,
    "radio-lineItem": RadioLineItem,
    "press-lineItem": PressLineItem,
    "outOfHome-lineItem": OutOfHomeLineItem,
    "cinema-lineItem": CinemaLineItem,
    "magazine-lineItem": MagazineLineItem,
    "i-input": Input,
    "plan-recommendation": PlanRecommendation,
    "i-poptip": Poptip
  },
  created () {
    this.apiService = new ApiService();
    this.fetchDataParametersAndPricingTypeMappings()
  },
  data: function () {
    return {
      mediaPlanLandingPageLink: '',
      apiService: {},
      activeTab: "digital",
      offlineMediaPlanFixedColumns: [
        {
          title: "Rate Card Cost",
          data: "perUnitPrice",
          type: "numeric",
          minwidth: "100px",
          width: "115px",
          numericFormat: {
            pattern: {
              thousandSeparated: true,
              mantissa: 2
            }
          },
          validator: (value, cb) => cb(this.gteZero(value))
        },
        {
          title: "Net Cost",
          data: "budget",
          type: "numeric",
          minwidth: "100px",
          width: "115px",
          numericFormat: {
            pattern: {
              thousandSeparated: true,
              mantissa: 2
            }
          },
          validator: (value, cb) => cb(this.gteZero(value))
        }
      ],
      offlineMediaPlanColumns: [
        {
          title: " ",
          data: "deleteRow",
          renderer: "html",
          minwidth: "15px",
          width: "30px"
        },
        {
          title: "Channel",
          data: "channel",
          minwidth: "150px",
          width: "180px",
          wordWrap: false,
          validator: (value, cb) => cb(this.checkForEmpty(value))
        },
        {
          title: "Placement",
          data: "placement",
          minwidth: "150px",
          width: "180px",
          wordWrap: false,
          validator: (value, cb) => cb(this.checkForEmpty(value))
        },
        {
          title: "Segment",
          data: "segment",
          minwidth: "150px",
          width: "180px",
          wordWrap: false,
          validator: (value, cb) => cb(this.checkForEmpty(value))
        },
        {
          title: "Buy Type",
          data: "buyType",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          minwidth: "100px",
          width: "115px",
          wordWrap: false,
          allowEmpty: false
        },
        {
          title: "Buy Metric",
          data: "buyMetric",
          type: "dropdown",
          source: [],
          wordWrap: false,
          minwidth: "100px",
          width: "115px",
          allowEmpty: false
        }
      ],
      offlineMediaPlanEnabled: false,
      isTelevisionLineItemsValid: true,
      isRadioLineItemsValid: true,
      isPressLineItemsValid: true,
      isOOHLineItemsValid: true,
      isCinemaLineItemsValid: true,
      isMagazineLineItemsValid: true,
      showDigitalLineItems: false,
      planRecommendationTableData: [],
      lineItemRecommendationMode: "",
      enableUserPromptAnimation: false,
      showRecommendationIcon: false,
      showRecommendationTooltip: false,
      countRecommendationDataFetch: 0,
      oldBudget: 0,
      lineItemModes: {
        1: 'digital',
        2: 'tv',
        3: 'radio',
        4: 'press',
        5: 'ooh',
        6: 'cinema',
        7: 'magazine'
      },
      lineItemDataParameters: {},
      dataParameterPricingTypeMapping: {},
      lineItemTypes: {}
    }
  },
  computed: {
    activeTabReference: function () {
      if (this.activeTab == "digital") {
        return "hotTable";
      } else if (this.activeTab == "tv") {
        return "televisionLineItem";
      } else if (this.activeTab == "radio") {
        return "radioLineItem";
      } else if (this.activeTab == "press") {
        return "pressLineItem";
      } else if (this.activeTab == "cinema") {
        return "cinemaLineItem";
      } else if (this.activeTab == "magazine") {
        return "magazineLineItem"
      } else {
        return "oohLineItem";
      }
    }
  },
  props: [
    "isOfflineMediaPlanEnabled",
    "validMediaPlanLandingPageLink",
    "mediaPlanObject",
    "budgetCenterConfiguration"
  ],
  watch: {
    mediaPlanObject: function () {
      this.mediaPlanLandingPageLink = this.mediaPlanObject.landingPageLink;
    },
    "mediaPlanObject.objective": {
      handler: async function (newValue, oldValue) {
        if (oldValue != newValue) {
          if (this.lineItemRecommendationMode != "" && this.lineItemRecommendationMode != null && this.lineItemRecommendationMode != undefined) {
            this.getPlanRecommendationTableData();
          }
        }
      }
    },
    "mediaPlanObject.dateFilters": {
      handler: async function (newValue, oldValue) {
        if (oldValue != newValue) {
          if (this.lineItemRecommendationMode != "" && this.lineItemRecommendationMode != null && this.lineItemRecommendationMode != undefined) {
            this.getPlanRecommendationTableData();
          }
        }
      }
    },
    activeTab: function () {
      var disableLineItemCreation = false;
      this.$emit('disableLineItemCreation', disableLineItemCreation);
    }
  },
  methods: {
    budgetChanged: function () {
      if (this.lineItemRecommendationMode != "" && this.lineItemRecommendationMode != null && this.lineItemRecommendationMode != undefined && this.oldBudget != this.mediaPlanObject.mediaCostValue) {
        this.getPlanRecommendationTableData();
      }
    },
    getPlanRecommendationTableData: function () {
      var self = this;
      if (typeof (self.mediaPlanObject.objective) !== "undefined" &&
        typeof (self.mediaPlanObject.dateFilters[0]) !== "undefined" &&
        typeof (self.mediaPlanObject.dateFilters[1]) !== "undefined" &&
        typeof (self.mediaPlanObject.mediaCostValue) !== "undefined" &&
        self.mediaPlanObject.mediaCostValue !== "" && self.mediaPlanObject.mediaCostValue > 0) {
        self.oldBudget = self.mediaPlanObject.mediaCostValue;
        var startDate = moment(self.mediaPlanObject.dateFilters[0]).format("MM/DD/YYYY");
        var endDate = moment(self.mediaPlanObject.dateFilters[1]).format("MM/DD/YYYY");
        this.apiService
          .getLineItemRecommedations(
            startDate,
            endDate,
            self.mediaPlanObject.mediaCostValue,
            self.mediaPlanObject.objective)
          .then(response => {
            self.planRecommendationTableData = response.data["data"];
            if (self.planRecommendationTableData.length > 0 && self.lineItemRecommendationMode && self.lineItemRecommendationMode.toLowerCase() == "autoprompt") {
              if (this.$parent.$parent.spinner == false) {
                self.countRecommendationDataFetch = ++self.countRecommendationDataFetch;
                if (self.countRecommendationDataFetch == 1) {
                  self.recommendationPopupEnable();
                }
                self.showRecommendationTooltip = true;
                /* eslint-disable */
                setTimeout(() => self.showRecommendationTooltip = false, 5000)
                /* eslint-enable */
              }
            }
            if (self.planRecommendationTableData.length > 0 && self.lineItemRecommendationMode && self.lineItemRecommendationMode.toLowerCase() == "userprompt") {
              if (this.$parent.$parent.spinner == false) {
                self.enableUserPromptAnimation = true;
                self.countRecommendationDataFetch = ++self.countRecommendationDataFetch;
                self.showRecommendationTooltip = true;
                /* eslint-disable */
                setTimeout(() => self.showRecommendationTooltip = false, 5000)
                /* eslint-enable */
              }
            }
          });
      } else {
        self.planRecommendationTableData = [];
      }
    },
    addLineItemRecommendations: function (lineItemRecommendations) {
      var self = this;
      if (lineItemRecommendations.length > 0) {
        self.$refs.hotTable.setDataToLineItems(lineItemRecommendations);
        self.showDigitalLineItems = true;
      }
      self.showRecommendationTooltip = false;
    },
    validateMediaPlanDetails: function () {
      this.$emit('validateMediaPlanDetails', this.mediaPlanLandingPageLink)
    },
    gteZero: function (value) {
      let parsedValue = parseFloat(value + "");
      return !isNaN(parsedValue) && parsedValue >= 0;
    },
    checkForEmpty: function (value) {
      if (value != "" && value != null) {
        return true;
      } else {
        return false;
      }
    },
    init: function (intermediateSave) {
      this.showDigitalLineItems = intermediateSave;
      this.$refs.hotTable.init(intermediateSave);
      if (!intermediateSave) {
        const getPortalPromise = this.apiService.getPortalDetails();
        getPortalPromise
          .then(res => {
            var recommendationMode = JSON.parse(res.data.data.featureFlags).lineItemRecommendationMode;
            this.lineItemRecommendationMode = recommendationMode != undefined && recommendationMode != null
              ? recommendationMode
              : ""
            this.showRecommendationIcon = !!(this.lineItemRecommendationMode != undefined && this.lineItemRecommendationMode != null && this.lineItemRecommendationMode != "")
          })
          .catch(x => {
            console.log("Error occured in fetching lineItem recommendation mode");
          });
      }

      if (this.$parent.$parent.mediaPlanId > 0 && this.lineItemRecommendationMode != "" && this.lineItemRecommendationMode != null && this.lineItemRecommendationMode != undefined) {
        var startDate = moment(this.mediaPlanObject.dateFilters[0]).format("MM/DD/YYYY");
        var endDate = moment(this.mediaPlanObject.dateFilters[1]).format("MM/DD/YYYY");
        this.apiService
          .getLineItemRecommedations(
            startDate,
            endDate,
            this.mediaPlanObject.mediaCostValue,
            this.mediaPlanObject.objective)
          .then(response => {
            this.planRecommendationTableData = response.data["data"];
          })
          .catch(x => {
            console.log("Error occured in fetching lineItem recommendations");
          });
      }

      if (this.isOfflineMediaPlanEnabled) {
        this.isTelevisionLineItemsValid = true;
        this.isRadioLineItemsValid = true;
        this.isPressLineItemsValid = true;
        this.isOOHLineItemsValid = true;
        this.isCinemaLineItemsValid = true;
        this.isMagazineLineItemsValid = true;
        this.$refs.televisionLineItem.init(intermediateSave);
        this.$refs.radioLineItem.init(intermediateSave);
        this.$refs.pressLineItem.init(intermediateSave);
        this.$refs.oohLineItem.init(intermediateSave);
        this.$refs.cinemaLineItem.init(intermediateSave);
        this.$refs.magazineLineItem.init(intermediateSave);

        let digitalLineItems = this.$parent.$parent.mediaPlanData.lineItems;
        let offlineLineItems = this.$parent.$parent.offlineLineItems;
        if ((digitalLineItems && digitalLineItems.length > 0) || (!offlineLineItems || offlineLineItems.length == 0)) {
          this.activeTab = "digital"
        } else {
          switch (offlineLineItems[0].lineItemMode) {
            case 2:
              this.activeTab = "tv";
              break;
            case 3:
              this.activeTab = "radio";
              break;
            case 4:
              this.activeTab = "press";
              break;
            case 5:
              this.activeTab = "ooh";
              break;
            case 6:
              this.activeTab = "cinema";
              break;
            default:
              this.activeTab = "magazine";
          }
        }
      }
    },
    cancelAction: function () {
      this.$refs.hotTable.cancelAction();
      this.activeTab = "digital";
      this.planRecommendationTableData = [];
      this.showRecommendationTooltip = false;
      this.enableUserPromptAnimation = false;
      this.countRecommendationDataFetch = 0;
      this.mediaPlanObject.typeId = 1;
    },
    selectColumnPopUpEnable: function () {
      var activeTabRef = this.activeTabReference;
      this.$refs[activeTabRef].selectColumnPopUpEnable();
    },
    linkLineItemsPopupEnable: function () {
      this.$refs.hotTable.linkLineItemsPopupEnable();
    },
    schedulerPopupEnable: function () {
      var activeTabRef = this.activeTabReference;
      this.$refs[activeTabRef].schedulerPopupEnable();
    },
    addRows: function () {
      var activeTabRef = this.activeTabReference;
      this.$refs[activeTabRef].addRows();
    },
    mergeCells: function () {
      var activeTabRef = this.activeTabReference;
      this.$refs[activeTabRef].mergeCells();
    },
    unmergeCells: function () {
      var activeTabRef = this.activeTabReference;
      this.$refs[activeTabRef].UnmergeCells();
    },
    calculateLineItemTotalBudget: function () {
      this.$parent.$parent.calculateLineItemTotalBudget();
    },
    setSummaryTabData: function () {
      this.$emit('setSummaryTabData');
    },
    onSaveSuccessOfMediaPlan: function () {
      this.$emit('onSaveSuccessOfMediaPlan');
    },
    onSaveFailOfMediaPlan: function () {
      this.$emit('onSaveFailOfMediaPlan');
    },
    getOfflineLineItems: async function () {
      return new Promise((resolve, reject) => {
        var offlineLineItems = [];
        let promises = [];

        const tvLineItemsPromise = this.$refs.televisionLineItem.validateLineItems();
        tvLineItemsPromise.then(res => {
          this.isTelevisionLineItemsValid = res;
          if (res == true) {
            offlineLineItems = offlineLineItems.concat(this.$refs.televisionLineItem.saveLineItems());
          }
        }).catch(err => { console.log(err) })
        promises.push(tvLineItemsPromise);

        const radioLineItemsPromise = this.$refs.radioLineItem.validateLineItems();
        radioLineItemsPromise.then(res => {
          this.isRadioLineItemsValid = res;
          if (res == true) {
            offlineLineItems = offlineLineItems.concat(this.$refs.radioLineItem.saveLineItems());
          }
        }).catch(err => { console.log(err) })
        promises.push(radioLineItemsPromise);

        const pressLineItemsPromise = this.$refs.pressLineItem.validateLineItems();
        pressLineItemsPromise.then(res => {
          this.isPressLineItemsValid = res;
          if (res == true) {
            offlineLineItems = offlineLineItems.concat(this.$refs.pressLineItem.saveLineItems());
          }
        }).catch(err => { console.log(err) })
        promises.push(pressLineItemsPromise);

        const oohLineItemsPromise = this.$refs.oohLineItem.validateLineItems();
        oohLineItemsPromise.then(res => {
          this.isOOHLineItemsValid = res;
          if (res == true) {
            offlineLineItems = offlineLineItems.concat(this.$refs.oohLineItem.saveLineItems());
          }
        }).catch(err => { console.log(err) })
        promises.push(oohLineItemsPromise);

        const cinemaLineItemsPromise = this.$refs.cinemaLineItem.validateLineItems();
        cinemaLineItemsPromise.then(res => {
          this.isCinemaLineItemsValid = res;
          if (res == true) {
            offlineLineItems = offlineLineItems.concat(this.$refs.cinemaLineItem.saveLineItems());
          }
        }).catch(err => { console.log(err) })
        promises.push(cinemaLineItemsPromise);

        const magazineLineItemsPromise = this.$refs.magazineLineItem.validateLineItems();
        magazineLineItemsPromise.then(res => {
          this.isMagazineLineItemsValid = res;
          if (res == true) {
            offlineLineItems = offlineLineItems.concat(this.$refs.magazineLineItem.saveLineItems());
          }
        }).catch(err => { console.log(err) })
        promises.push(magazineLineItemsPromise);

        Promise.all(promises).then(() => {
          this.$parent.$parent.isOfflineMediaPlanValid = this.isTelevisionLineItemsValid && this.isRadioLineItemsValid && this.isPressLineItemsValid && this.isOOHLineItemsValid && this.isCinemaLineItemsValid && this.isMagazineLineItemsValid;
          resolve(offlineLineItems);
        }).catch(err => { console.log(err) })
      })
    },
    enableDigitalHOTtable: function () {
      this.$refs.hotTable.enableHOTtable();
      this.showDigitalLineItems = true;
    },
    recommendationPopupEnable: function () {
      this.$refs.planRecommendation.setRecommendationData();
      /* eslint-disable */
      setTimeout(() => this.showRecommendationTooltip = false, 0)
      /* eslint-enable */
    },
    closeRecommendationsTooltip: function () {
      this.showRecommendationTooltip = false;
    },
    isEditableMediaPlan: function () {
      if (!this.$parent.$parent.latestMediaPlanVersionSelected) {
        return false;
      }
      if (this.$parent.$parent.isEstimateEnabled) {
        return false;
      } else {
        return true;
      }
    },
    async fetchDataParametersAndPricingTypeMappings () {
      let promises = [
        this.apiService.getLineItemDataParameters(),
        this.apiService.getDataParameterPricingTypeMapping(),
        this.apiService.getLineItemTypes()
      ];

      let [dataParameters, pricingTypeMappings, lineItemTypesData] = await Promise.all(promises);

      let lineItemDataParameters = {};
      let dataParameterPricingTypeMapping = {};
      let lineItemTypes = {};

      dataParameters.data.forEach(dp => {
        dp.name = dp.name.replace(/\./g, '');
        let mode = this.lineItemModes[dp.lineItemModeId];

        if (lineItemDataParameters[mode]) {
          lineItemDataParameters[mode].push(dp);
        } else {
          lineItemDataParameters[mode] = [dp];
        }
      });

      for (let modeId in this.lineItemModes) {
        modeId = parseInt(modeId)
        let data = JSON.parse(JSON.stringify(pricingTypeMappings.data.data));
        dataParameterPricingTypeMapping[this.lineItemModes[modeId]] =
          data.map(costSourceMappings => {
            costSourceMappings.lineItemPricing.forEach(pricing => {
              pricing.DataParameter = pricing.DataParameter.filter(dp => dp.lineItemModeId == modeId);
              pricing.DataParameter.forEach(dp => {
                dp.name = dp.name.replace(/\./g, '');
              });
            });
            costSourceMappings.lineItemPricing = costSourceMappings.lineItemPricing.filter(pricing => pricing.DataParameter.length > 0);

            return costSourceMappings;
          });
      }

      lineItemTypesData.data.forEach(type => {
        let mode = this.lineItemModes[type.lineItemModeId];

        if (lineItemTypes[mode]) {
          lineItemTypes[mode].push(type);
        } else {
          lineItemTypes[mode] = [type];
        }
      });

      this.lineItemDataParameters = lineItemDataParameters;
      this.dataParameterPricingTypeMapping = dataParameterPricingTypeMapping;
      this.lineItemTypes = lineItemTypes;
    }
  }
}
</script>
<style scoped>
::v-deep .ivu-tabs-nav .ivu-tabs-tab:hover{
  color: black !important;
}
::v-deep .ivu-tabs-nav-container{
 font-size: 12px !important;
}
::v-deep .ivu-tabs-bar {
    margin-bottom: -1px !important;
}
.attachments-text-style{
  font-size: 15px;
  margin-top: 7px;
  margin-left: -25px;
  color: black;
}
.tabs-padding{
  margin-right: 20px;
}
.hide-element {
  visibility: hidden;
}
::v-deep textarea.ivu-input{
  max-height: 800px !important;
  font-size: 12px !important;
}
::v-deep .ivu-upload-list-file {
    color: black !important;
}
::v-deep .ql-editor>* {
    color: #000 !important;
    font-size: 15px !important;
}
.import-select-icons-padding{
  float: right;
}
.icon-size {
  font-size: 20px;
  cursor: initial;
}
.select-columns-padding {
  margin-left: 7px;
}
.select-columns-span {
  padding-left: 10px;
}
.select-columns-class {
  font-size: 25px;
  margin-top: 5px;
}
.ivu-input-wrapper {
  width: 55%;
}
::v-deep .ivu-input {
  height:32px;
}
.margin-right-five{
  margin-right: 5px;
}
.ivu-tooltip {
  display: inline;
}
::v-deep .ivu-tabs-nav-right{
  width: 415px;
  margin-top: -5px;
  text-align: right;
}
.plan-recommendation-bulb {
  display: inline-block;
  width: 5px;
  min-width: 5px;
  max-width: 5px;
  margin-right: 6px;
}
.margin-landing-page-warning-icon{
  width: 5px;
  display: inline-block;
  min-width: 5px;
  max-width: 5px;
  padding-right: 20px;
  margin-left: -8px;
}
.margin-landing-page{
  margin-top: -10px;
  margin-right: 10px;
}
.exclamation-sign{
  color: #b94a48;
  font-size: 20px;
  padding-right: 0px;
  padding-left: 0px;
}
::v-deep .ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab-active{
  color: black !important;
  border-right-color: darkgrey !important;
  border-left-color: darkgrey !important;
  border-top: 2px solid #B1B2B2 !important;
  border-bottom: none !important;
  background-color: #e9eaed !important;
  font-weight: bold;
  margin-right: 10px;
}
::v-deep .ivu-tabs.ivu-tabs-card>.ivu-tabs-bar .ivu-tabs-tab{
  height: 32px !important;
  border-bottom: 2px solid #dcdee2;
  border-radius: 0 !important;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  margin-right: 10px;
  background-color: #f8f8f9;
}
.scheduler-button-style{
  background: #f5f5f5;
  border: 1px solid #ddd;
  color: black;
  padding: 2px 12px;
  padding-bottom: 0px;
  font-size: 13px;
}
.side-slots-in-Tabs{
  margin-right: 10px;
  margin-bottom: -5px;
}
::v-deep .ivu-tabs-nav .ivu-tabs-tab .ivu-icon{
  margin-right: 3px !important;
  width: auto !important;
}
::v-deep .font-awesome-style{
  font-family: FontAwesome !important;
  color: #b94a48 !important;
  font-size: 11px !important;
}
.enable-button-padding{
  padding-top: 10px;
}
.hide-button{
  display:none;
}
::v-deep .approach-notes.non-editable .quillWrapper{
  background: #f3f3f3;
}
.non-editable{
  pointer-events: none;
}
.recommendation-icon-size{
  font-size: 22px;
  cursor: pointer;
  color: black !important;
}
::v-deep .popover-title{
  width: 165px !important;
  padding: 10px 9px !important;
  font-size: 12px !important;
  background-color: black !important;
  color: white !important;
}
::v-deep .popover-content {
  padding: 4px 10px !important;
  background-color: black !important;
  color: white !important;
}
::v-deep .popover.left{
  top: 12px !important;
  animation: none !important;
  margin-left: -20px !important;
  cursor: pointer !important;
}
.cross-icon-style{
  color: white;
  text-decoration: none;
  padding-left: 8px;
  font-weight: bold;
  cursor: pointer;
  font-size: 11px;
}
.recommendation-tooltip-style{
  top: -5px;
  left: 1001.88px;
  display: block;
}
.recommendation-icon-style{
  left: 310px;
  padding-top: 3px;
  cursor: pointer;
}
.merge-icon-size{
  margin-left:14px;
  font-size: 20px;
}
::v-deep .ivu-poptip-inner {
  width: 100% !important;
  background-color: black !important;
}
::v-deep .ivu-poptip-title-inner {
  color: white !important;
  font-size: 11px !important;
}
::v-deep .ivu-poptip-title:after{
	background-color: black !important;
}
::v-deep .ivu-poptip-popper .ivu-poptip-arrow {
  font-size: 11px !important;
}
::v-deep .ivu-poptip-title {
  padding: 4px 10px !important;
}
::v-deep .ivu-poptip-body {
  display: none !important;
}
::v-deep .ivu-poptip-body-content-inner{
  color: white !important;
}
::v-deep .ivu-poptip-popper{
  top: 1px !important;
}
::v-deep .ivu-poptip-inner{
  border-radius: 0 !important;
}
::v-deep .ivu-poptip-arrow{
  border-style: hidden !important;
  border-top: 10px solid transparent !important;
  border-bottom: 10px solid transparent !important;
  border-left: 10px solid black !important;
  right: 3px !important;
  position: absolute !important;
}
::v-deep .ivu-poptip-arrow, .ivu-poptip-arrow:after{
  border-style:hidden !important;
}
::v-deep .ivu-poptip-title{
    color: white !important;
    font-size: 11px !important;
    padding: 0px 3px !important;
}
::v-deep .popover-title{
  border-bottom: none !important;
}
::v-deep .ivu-poptip-popper .ivu-poptip-arrow:after {
    border-left-color: black !important;
}
::v-deep .ivu-poptip-popper .ivu-poptip-arrow {
    top: 70% !important;
    margin-top: -7px !important;
}
.popover-title-padding{
  margin-left: -3px !important;
}
</style>
<style src="../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
<style src="../../../Styles/dx-iview.css"></style>
<style src="../../../../../../Content/CustomCss/all-animation.css"></style>
