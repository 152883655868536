<template>
    <div>
        <i-radio-group
        v-model="attachmentType"
        class="attachment-radio-group">
            <i-radio label="Attach">
            </i-radio>
            <i-radio label="Upload Url">
            </i-radio>
            <i-radio label="Drive">
            </i-radio>
        </i-radio-group>

        <div
        class="icon-wrapper"
        v-show="uploading">
          <i
          class="fa fa-lg fa-spinner fa-spin pull-right upload-icon"/>
        </div>
        <div
        class="attachment-types"
        v-show="!uploading">
            <i-upload
                ref="uploadAttachments"
                multiple
                type="drag"
                :before-upload="uploadFiles"
                action=""
                name="attachments"
                v-if="attachmentType=='Attach'"
                class="upload"
                :show-upload-list="false"
                :default-file-list="files">
                <div class="upload-text">
                    <p>Click or Drag files here</p>
                </div>
            </i-upload>

            <div>
              <div class="url-wrapper">
                <i-input
                class="url-input"
                v-model="creativeUrl"
                v-if="attachmentType=='Upload Url'">
                </i-input>
                <i-button
                class="url-submit"
                v-on:click="uploadByUrl"
                v-if="attachmentType=='Upload Url'"
                slot="append">
                  <i class="fas fa-arrow-right"></i>
                </i-button>
              </div>
              <span v-if="attachmentType=='Upload Url'">
                <label
                ref="invalidMessage"
                v-show="!validUrl"
                class="required-message-style">* Invalid Url</label>
              </span>
            </div>

            <div
            class="google-drive-upload"
            v-if="attachmentType=='Drive'">
                <google-drive-picker v-on:filesDownloaded="uploadFiles"></google-drive-picker>
            </div>
        </div>
    </div>
</template>

<script>
import {
  locale,
  RadioGroup,
  Radio,
  Upload,
  Input,
  Button
} from "iview";
import GoogleDriveFilePicker from './GoogleDriveFilePicker.vue';
import lang from "iview/dist/locale/en-US";

var apiService = require("../Services/InputListingApiServices.js");

locale(lang);

export default {
  components: {
    "i-radio-group": RadioGroup,
    "i-radio": Radio,
    "i-upload": Upload,
    "i-input": Input,
    "i-button": Button,
    "google-drive-picker": GoogleDriveFilePicker
  },
  data () {
    return {
      uploading: false,
      attachmentType: "Attach",
      creativeUrl: '',
      files: [],
      attachmentStatus: [
        { id: 1, value: "Awaited" },
        { id: 2, value: "New" },
        { id: 3, value: "Accepted" },
        { id: 4, value: "Rejected" }
      ],
      validUrl: true
    }
  },
  props: {
    inputId: Number
  },
  methods: {
    uploadByUrl () {
      var self = this;
      this.uploading = true;
      if (!self.isValidUrl()) {
        this.uploading = false;
        return;
      }
      if (this.inputId == 0) {
        var extension = self.creativeUrl.substring(self.creativeUrl.lastIndexOf('.') + 1);
        if (self.creativeUrl.lastIndexOf('/') > self.creativeUrl.lastIndexOf('.')) {
          extension = extension.substring(0, extension.length - 1);
        }
        var file = {
          type: extension,
          size: 0,
          path: '',
          creativeUrl: self.creativeUrl
        };
        self.$store.commit('set_uploadFile', { file: file, inputId: self.inputId });
        this.creativeUrl = '';
        this.uploading = false;
      } else {
        const uploadFilesByUrlPromise = apiService.UploadFilesByUrl(this.inputId, this.creativeUrl);
        Promise.all([uploadFilesByUrlPromise])
          .then(value => {
            this.creativeUrl = '';
            if (value[0].status == 204) {
              this.uploading = false;
              this.$refs.invalidMessage.textContent = "Download failed";
              this.validUrl = false;
              return;
            }

            var responseData = value[0].data.attachmentUploaded[0]
            var file = new Object();
            file.id = responseData.id;
            file.name = responseData.name;
            file.path = responseData.path;
            file.size = responseData.size;
            file.status = this.attachmentStatus.find(i => i.id == responseData.status).id;
            file.type = responseData.type;
            file.uploadedAt = responseData.createdAt;
            self.$store.commit('set_uploadFile', { file: file, inputId: self.inputId });
            this.uploading = false;
          })
          .catch(() => {
            this.uploading = false;
            this.$refs.invalidMessage.textContent = "Download failed";
            this.validUrl = false;
            this.creativeUrl = '';
          });
      }
    },

    uploadFiles (file) {
      var self = this;
      this.uploading = true;
      if (this.inputId == 0) {
        file.path = '';
        self.$store.commit('set_uploadFile', { file: file, inputId: self.inputId });
        this.uploading = false;
      } else {
        var formData = new FormData();
        formData.append("attachments", file);
        const uploadFilesPromise = apiService.UploadFiles(this.inputId, formData);
        Promise.all([uploadFilesPromise])
          .then(value => {
            file.id = value[0].data.attachmentUploaded[0].id;
            file.path = value[0].data.attachmentUploaded[0].path;
            self.$store.commit('set_uploadFile', { file: file, inputId: self.inputId });
            this.uploading = false;
          });
      }
    },

    isValidUrl () {
      var self = this;
      try {
        var vurl = new URL(self.creativeUrl);
      } catch (error) {
        this.$refs.invalidMessage.textContent = "* Invalid Url"
        this.validUrl = false;
        return false;
      }
      this.validUrl = true;
      return true;
    }
  }
}
</script>

<style scoped>
.attachment-types {
    min-height: 60px;
    display: flex;
    align-items: center;
}

.upload {
    width: 100%;
    height: 100%;
}

.attachment-radio-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

::v-deep .ivu-radio-wrapper {
    text-align: center;
}

.upload-text p {
    margin: 0px !important;
    padding-top: 8px;
    padding-bottom: 8px;
}

::v-deep .ivu-upload-list {
    margin: 0px !important;
}

#authorizeBtn {
  width: 100%;
}

.google-drive-upload {
  margin-top: -16px;
  width: 100%;
  text-align: center;
}

.upload-icon {
  position: relative;
  right: 50%;
  top: 18px;
}

.font-color-gray {
  color: gray;
}

.required-message-style{
  color:#CC3F44;
  font-size: 12px;
}

.url-wrapper {
  display: flex;
}

.icon-wrapper {
  min-height: 60px;
}
</style>
