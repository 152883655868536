<template>
    <div class="input-list-container">
      <div class="header-wrapper">
        <div>
                <i-select
                class="select-dropdown-class"
                v-model="briefSelected"
                placeholder="Select Brief"
                v-on:on-change="briefChanged">
                    <i-option
                    v-for="brief in briefs"
                    :value="brief.id"
                    :key="brief.id">
                        {{ brief.name }}
                    </i-option>
                </i-select>
                <i-select
                class="select-dropdown-class"
                v-model="briefPhaseSelected"
                placeholder="Select Phase"
                v-on:on-change="briefPhaseChanged">
                    <i-option
                    v-for="phase in phasesByBrief"
                    :value="phase.id"
                    :key="phase.id">
                        {{ phase.name }}
                    </i-option>
                </i-select>
              <div class="align-tag-center select-dropdown-class">
                  <i-select
                  v-model="inputGroupSelected"
                  placeholder="Select Input Group"
                  v-on:on-change="inputGroupChanged">
                      <i-option
                      v-for="inputGroup in inputGroups"
                      :key="inputGroup.id"
                      :value="inputGroup.id">
                          {{inputGroup.name}}
                      </i-option>
                  </i-select>
              </div>
        </div>
          </div>
        <div class="display-grid">
            <div class="briefs-panel">
                <h4 class="brief-section">{{briefDisplayed.name}}</h4>
                <div class="brief-section"><strong>Business Lead:</strong> {{briefDisplayed.businessLeadName}}</div>
                <div class="brief-section"><strong>Campaign date:</strong> {{briefDisplayed.startDate}}</div>
                <div class="brief-section"><strong>Budget:</strong> {{briefDisplayed.budget}}</div>
                <div class="brief-section">
                    <h5>Summary:</h5>
                    <div>
                        <span v-html="briefDisplayed.description">
                        </span>
                    </div>
                </div>
                <div class="brief-section">
                    <h5>Objective:</h5>
                    <div>{{briefDisplayed.objective}}</div>
                </div>
            </div>
            <div class="creative-specs-panel">
                <div v-if="inputGroupDisplayed">
                    <h4 class="creative-specs-section">{{inputGroupDisplayed.name}}</h4>
                    <div v-for="input in inputGroupDisplayed.inputs" :key="input.id" class="input-item">
                        <input-item
                        :input="input">
                        </input-item>
                    </div>
                    <Button
                    class="btn create-btn btn-success button-style"
                    v-on:click="showCreativeSpecsPanel=!showCreativeSpecsPanel"
                    v-if="!showCreativeSpecsPanel">
                        Additional Submission
                    </Button>
                    <div v-if="showCreativeSpecsPanel">
                        <additional-submission
                        :inputGroupId="inputGroupDisplayed.id"
                        v-on:refresh="briefChanged">
                        </additional-submission>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
  locale,
  Select,
  Option
} from "iview";
import lang from "iview/dist/locale/en-US";
import moment from 'moment';

import Input from './Input.vue';
import AdditionalSubmission from './AdditionalSubmission.vue';
var apiService = require("../Services/InputListingApiServices.js");

locale(lang);

export default {
  components: {
    "i-select": Select,
    "i-option": Option,
    "input-item": Input,
    "additional-submission": AdditionalSubmission
  },
  created: function () {
    var self = this;

    const getCreativeBriefsPromise = apiService.GetCreativeBriefs();
    const getBusinessProfileDetails = apiService.GetBusinessProfileDetails();
    const getBriefPhasesPromise = apiService.GetBriefPhases();

    Promise.all([getCreativeBriefsPromise, getBusinessProfileDetails, getBriefPhasesPromise])
      .then(values => {
        if (values[0].data.data.length > 0) {
          self.briefs = values[0].data.data;
          self.briefPhases = values[2].data.data;

          if (self.$route.query.briefId == null) {
            self.briefDisplayed = this.briefs[0];
          } else {
            var briefValid = this.briefs.find(brief => brief.id == this.$route.query.briefId)
            if (briefValid) {
              self.briefDisplayed = this.briefs.find(brief => brief.id == this.$route.query.briefId)
            }
          }

          if (self.briefDisplayed && Object.keys(self.briefDisplayed).length > 0) {
            self.briefSelected = self.briefDisplayed.id

            self.phasesByBrief = self.briefPhases.filter(x => x.briefId == self.briefSelected);

            if (self.briefPhases.length > 0) {
              if (self.$route.query.briefPhaseId == null) {
                var briefPhase = this.briefPhases.find(phase => phase.briefId == self.briefSelected);
                if (briefPhase) {
                  self.briefPhaseSelected = briefPhase.id;
                }
              } else {
                var phaseValid = self.briefPhases.find(phase => phase.id == self.$route.query.briefPhaseId)
                if (phaseValid) {
                  var phasePresentInBrief = this.briefPhases.find(x => x.id == this.$route.query.briefPhaseId).briefId == self.briefSelected;
                  if (phasePresentInBrief) {
                    self.briefPhaseSelected = this.briefPhases.find(phase => phase.id == this.$route.query.briefPhaseId).id
                  }
                }
              }
            }

            var getUserDetailsPromise = 0;

            if (this.briefDisplayed.businessLeadId) {
              getUserDetailsPromise = apiService.GetUserDetails(this.briefDisplayed.businessLeadId);
            }
            const getInputGroupsWithEntitiesByBriefId = apiService.GetInputGroupsWithEntitiesByBriefId(this.briefSelected, this.briefPhaseSelected);

            Promise.all([getUserDetailsPromise, getInputGroupsWithEntitiesByBriefId])
              .then(val => {
                if (Object.keys(val[1].data.data).length == 0) {
                  return;
                }
                if (val[0]) {
                  this.briefDisplayed.businessLeadName = val[0].data.data.firstName + " " + val[0].data.data.lastName;
                }
                if (!self.briefDisplayed.objectiveId) {
                  self.briefDisplayed.objective = '';
                }
                this.briefDisplayed.startDate = moment(self.briefDisplayed.startDate).format('Do, MMMM YYYY');
                this.inputGroups = val[1].data.data;

                if (this.$route.query.inputGroupId == null) {
                  this.inputGroupDisplayed = this.inputGroups[0];
                } else {
                  this.inputGroupDisplayed = this.inputGroups.find(inputGroup => inputGroup.id == this.$route.query.inputGroupId);
                }

                if (this.briefPhaseSelected > 0) {
                  this.$router.push({
                    name: 'InputListingLandingPage',
                    query: { briefId: self.briefDisplayed.id, inputGroupId: self.inputGroupDisplayed.id, briefPhaseId: this.briefPhaseSelected }
                  });
                } else {
                  this.$router.push({
                    name: 'InputListingLandingPage',
                    query: { briefId: self.briefDisplayed.id, inputGroupId: self.inputGroupDisplayed.id }
                  });
                }

                if (this.inputGroupDisplayed != null) {
                  this.inputGroupSelected = this.inputGroupDisplayed.id;
                  if (values[1].data.data.thumbnailLogo != null) {
                    this.logo = deltax.getBusinessProfileLogoBasePath + values[1].data.data.thumbnailLogo;
                  }
                }
              });
          }
        }
      });
  },
  data () {
    return {
      businessProfileId: 1,
      businessProfileName: '',
      logo: '',
      briefSelected: '',
      inputGroupSelected: '',
      briefs: [],
      currentDate: moment().format('Do, MMMM YYYY'),
      briefDisplayed: Object,
      inputGroups: [],
      inputGroupDisplayed: Object,
      inputs: [],
      showCreativeSpecsPanel: false,
      briefPhases: [],
      briefPhaseSelected: '',
      phasesByBrief: []
    }
  },
  methods: {
    briefChanged: function () {
      var self = this;
      if (this.briefs.find(brief => brief.id == this.briefSelected)) {
        this.briefDisplayed = this.briefs.find(brief => brief.id == this.briefSelected);
        this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected } });
      }
      if (this.briefDisplayed && Object.keys(self.briefDisplayed).length > 0) {
        var getUserDetailsPromise = 0;
        if (this.briefDisplayed.businessLeadId) {
          getUserDetailsPromise = apiService.GetUserDetails(this.briefDisplayed.businessLeadId);
        }

        var briefsByPhase = this.briefPhases.filter(phase => phase.id == self.briefPhaseSelected).map(x => x);
        if (!briefsByPhase.map(x => x.briefId).includes(self.briefSelected)) {
          var briefPhases = this.briefPhases.filter(x => x.briefId == this.briefSelected);
          this.briefPhaseSelected = briefPhases.length > 0 ? briefPhases[0].id : 0;
          this.phasesByBrief = briefPhases;
          if (this.briefPhaseSelected > 0) {
            this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected, briefPhaseId: this.briefPhaseSelected } });
          }
        }

        const getInputGroupsWithEntitiesByBriefIdPromise = apiService.GetInputGroupsWithEntitiesByBriefId(this.briefSelected, this.briefPhaseSelected);

        Promise.all([getUserDetailsPromise, getInputGroupsWithEntitiesByBriefIdPromise])
          .then(values => {
            if (values[0] && Object.keys(values[0].data.data).length != 0) {
              this.briefDisplayed.businessLeadName = values[0].data.data.firstName + " " + values[0].data.data.lastName;
            }
            this.inputGroups = values[1].data.data;
            if (this.inputGroups.length > 0) {
              this.inputGroupSelected = this.inputGroups[0].id;
              if (this.briefPhaseSelected > 0) {
                this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected, inputGroupId: this.inputGroupSelected, briefPhaseId: this.briefPhaseSelected } });
              } else {
                this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected, inputGroupId: this.inputGroupSelected } });
              }
            }
            this.inputGroupChanged();
          });
      } else {
        this.briefSelected = '';
      }
    },
    inputGroupChanged: function () {
      this.showCreativeSpecsPanel = false;
      this.inputGroupDisplayed = this.inputGroups.find(inputGroup => inputGroup.id == this.inputGroupSelected);
      if (!this.inputGroupDisplayed) {
        this.inputGroupSelected = '';
      } else {
        if (this.briefPhaseSelected > 0) {
          this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected, inputGroupId: this.inputGroupSelected, briefPhaseId: this.briefPhaseSelected } });
        } else {
          this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected, inputGroupId: this.inputGroupSelected } });
        }
      }
    },
    briefPhaseChanged: function () {
      var inputGroupPromise = apiService.GetInputGroupsWithEntitiesByBriefId(this.briefSelected, this.briefPhaseSelected);
      inputGroupPromise.then(res => {
        this.inputGroups = res.data.data;
        if (this.inputGroups.length > 0) {
          this.inputGroupSelected = this.inputGroups[0].id;
          this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected, inputGroupId: this.inputGroupSelected, briefPhaseId: this.briefPhaseSelected } });
        } else {
          this.inputGroupSelected = ''
          this.$router.push({ name: 'InputListingLandingPage', query: { briefId: this.briefSelected, briefPhaseId: this.briefPhaseSelected } });
        }
        this.inputGroupChanged();
      })
    }
  }
}
</script>

<style scoped>
.input-list-container {
    margin-top: -16px;
}

.display-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 10px;
}

.header-wrapper {
    background-color: #f6f7f8;
    border: 1px solid #cecdcd;
    display: grid;
    grid-template-columns: 3fr 3fr;
}

.thumbnail {
    max-height: 25px;
    max-width: 60px;
    margin-bottom: 0px;
    padding: 0px;
}

.select-dropdown-class {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 8px;
  float: left;
  width: 30%;
  margin-right: 0px;
}

.select-input-group {
  width: 95%;
  margin-left: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.align-tag-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.briefs-panel {
    background-color: white;
    margin-top: 5px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    border: solid #cecdcd 1px;
    min-height: 80vh;
}

h4, h5 {
    font-weight: bold;
}

.brief-section {
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: justify;
}

.creative-specs-section {
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: justify;
}

.input-group {
    width: 50%;
}

.creative-specs-panel {
    background-color: white;
    margin-top: 5px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    border: solid #cecdcd 1px;
    min-height: 80vh;
}

.input-item > *{
    margin-bottom: 8px;
}

::v-deep .ivu-select-selected-value, ::v-deep .ivu-select-item{
  font-size: 13px !important;
}

::v-deep .ivu-input-group-append {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
</style>
