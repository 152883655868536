<template>
  <div>
    <hot-table ref="table" :settings="settings"></hot-table>
    <div v-if="showSelectColumnsPopup">
      <select-columns
        :checkboxAttributeList="checkboxAttributeList"
        :checkboxBenchMarkList="benchMarkList"
        :selectedAttributes="selectedAttributes"
        :selectedBenchmarks="selectedBenchmarks"
        :benchmarksToBeDisabled="benchmarksToBeDisabled"
        @cancelActionForSelectColumns="cancelActionForSelectColumns"
        @addColumnIfCheckboxChecked="addColumnIfCheckboxChecked">
      </select-columns>
    </div>
    <div v-if="lineItemLinkPopup">
      <line-item-link
        :allMediaPlans="allMediaPlans"
        :popUpName="'Import Line Items'"
        :selectedEntities="[]"
        :disabledEntities="[]"
        :lineItemLinkPopup="lineItemLinkPopup"
        :retainLinkingCheckbox="true"
        @linkLineItems="linkLineItems"
        @linkLineItemCancel="linkLineItemCancel"
      ></line-item-link>
    </div>
    <div style="margin:-25px">
      <entity-linking
        ref="linkEntity"
        @hideEntityLinking="hideEntityLinking"
        @saveLinkedEntities="saveLinkedEntities"
      ></entity-linking>
    </div>
    <div v-if="showFractionsModal" style="margin:-25px">
      <add-fractions
        :lineItemOrderId="lineItemOrderId"
        :isFractionEnabled="isFractionEnabled"
        :lineItemFractions="lineItemFractions"
        :lineItemId="lineItemId"
        :allNomenclatureSettings="nomenclatureSettings"
        :labels="labels"
        :labelTypes="labelTypes"
        :lineItemData="lineItemData"
        :lineItemPublisher="lineItemPublisher"
        :mediaPlanObject="mediaPlanObject"
        @hideFractionsModal="hideFractionsModal"
        @saveFractionDetails="saveFractionDetails"
      >
      </add-fractions>
    </div>
    <div v-if="showCostPacingModal" style="margin: -25px">
      <cost-pacing
        :lineItemName="lineItemName"
        :startdate="startdate"
        :enddate="enddate"
        :lineItemBudget="cost"
        :stringCostPacingJson="costPacingJson"
        :orderId="orderId"
        :costPacingTypeId="costPacingTypeId"
        @hideCostPacingModal="hideCostPacingModal"
        @saveCostPacingDetails="saveCostPacingDetails">
      </cost-pacing>
    </div>
      <div>
        <i-modal
        v-model="enableConfirmPopup"
        :footer-hide="false"
        :closable="false"
        :mask-closable="false"
        class-name="vertical-center-modal"
        >
        <h4 slot="header">
            {{ confirmBoxHeader }}
        </h4>
        <div>
          <p class="confirm-box-text-style">{{ confirmBoxText }}</p>
        </div>
        <div slot="footer">
            <button
               type="button"
               class="btn create-btn btn-white button-style"
               v-on:click="confirmBoxCancel"
               v-show="confirmBoxShowCancel"
               >Cancel</button>
            <button
               type="button"
               class="btn create-btn btn-success button-style"
               v-on:click="confirmAction"
               >OK</button>
         </div>
        </i-modal>
      </div>
  </div>
</template>
<script>
import { Modal } from "iview";
import { HotTable } from "@handsontable/vue";
import { ApiService } from ".././Services/mediaPlanBulkCreateApiService.js";
import lineItemLinkPopUp from "../../CampaignMonitor/Components/LineItemLinkPopUp.vue";
import AddFractions from "./AddFractions.vue";
import EntityLinking from "./LinkEntity.vue";
import SelectColumns from "./SelectColumns.vue";
import CostPacing from "./CostPacing.vue";

export default {
  components: {
    "i-modal": Modal,
    "hot-table": HotTable,
    "add-fractions": AddFractions,
    "entity-linking": EntityLinking,
    "line-item-link": lineItemLinkPopUp,
    "select-columns": SelectColumns,
    "cost-pacing": CostPacing
  },
  created () {
    this.apiService = new ApiService();
  },
  data: function () {
    return {
      lineItemData: [],
      staticPublishersEnabled: false,
      alreadyLinkedCampaignIds: [],
      alreadyLinkedAdgroupIds: [],
      lineItemOrderId: 0,
      showEntityLinkingModal: false,
      showFractionsModal: false,
      showCostPacingModal: false,
      selectedRange: [],
      isFractionEnabled: 1,
      lineItemFractions: [],
      mergedRow: [],
      entityEnum: [
        {
          name: "Campaign",
          id: 1
        },
        {
          name: "AdGroup",
          id: 2
        }
      ],
      deletedLIForEdit: [],
      lineItemId: 0,
      lineItemName: "",
      mediaPlanName: "",
      lineItemLinkedEntities: [],
      idEditRowDeleted: false,
      skipDataChanged: false,
      skipSettingMediaPlanDateFilter: false,
      attributeToRemove: [],
      showSelectColumnsPopup: false,
      apiService: {},
      startdate: "",
      enddate: "",
      checkboxAttributeList: [
        { id: "creativeformat", name: "Creative Format" },
        { id: "note1", name: deltax.aliasNote.Note1 },
        { id: "note2", name: deltax.aliasNote.Note2 },
        { id: "startdate", name: "Start Date" },
        { id: "enddate", name: "End Date" },
        { id: "ronumber", name: "Ref" },
        { id: "targeting", name: "Targeting" },
        { id: "nickname", name: "Nickname" },
        { id: "universe", name: "Universe" },
        { id: "reachPercentage", name: "ReachPercentage" }
      ],
      isCreatePopUp: true,
      modalOkSpinnerClass: "",
      mappingForCostSource: [],
      bundleColumns: [],
      fixedDependentColumns: [
        {
          title: " ",
          data: "deleteRow",
          renderer: "html",
          minwidth: "60px",
          width: "70px"
        },
        {
          title: "Id",
          data: "liId",
          readOnly: true,
          minwidth: "80px",
          width: "80px",
          wordWrap: false
        },
        {
          title: "Channel Type",
          data: "channelType",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          minwidth: "100px",
          width: "100px",
          wordWrap: false,
          allowEmpty: false
        },
        {
          title: parseInt(deltax.BillingPortalId) == 259 ? "Media Title" : "Publisher",
          data: "publisher",
          type: "autocomplete",
          source: [],
          filter: true,
          minwidth: "150px",
          width: "150px",
          wordWrap: false,
          validator: (value, cb) => cb(this.checkForEmpty(value))
        },
        {
          title: deltax.aliasNote.Theme,
          data: "theme",
          minwidth: "150px",
          width: "150px",
          wordWrap: false
        },
        {
          title: "Entity",
          data: "entity",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          wordWrap: false,
          minwidth: "100px",
          width: "100px",
          allowEmpty: false
        }
      ],
      fixedColumns: [
        {
          title: "Buy Type",
          data: "buyType",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          minwidth: "100px",
          width: "100px",
          wordWrap: false,
          allowEmpty: false,
          validator: (value, cb) => cb(this.isValidBuyType(value))
        },
        {
          title: "Buy Metric",
          data: "buyMetric",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          wordWrap: false,
          minwidth: "100px",
          width: "100px",
          allowEmpty: false,
          validator: (value, cb) => cb(this.checkIfExistsInKpiMetric(value))
        },
        {
          title: "Unit Cost",
          data: "unitCost",
          type: "numeric",
          minwidth: "100px",
          width: "100px",
          numericFormat: {
            pattern: {
              thousandSeparated: true,
              mantissa: 2
            }
          },
          validator: (value, cb) => cb(this.gteZero(value))
        },
        {
          title: "KPI Metric",
          data: "kpiMetric",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          minwidth: "150px",
          wordWrap: false,
          width: "150px",
          allowEmpty: false
        },
        {
          title: "KPI Value",
          data: "kpiValue",
          type: "numeric",
          minwidth: "100px",
          width: "100px",
          numericFormat: {
            pattern: {
              thousandSeparated: true
            }
          },
          validator: (value, cb) => cb(this.gteZero(value))
        }
      ],
      fixedCostColumns: [
        {
          title: "Cost Source",
          data: "costSource",
          type: "dropdown",
          source: [],
          allowInvalid: false,
          minwidth: "130px",
          width: "130px",
          wordWrap: false,
          allowEmpty: false
        },
        {
          title: "Cost",
          data: "cost",
          type: "numeric",
          minwidth: "100px",
          width: "100px",
          numericFormat: {
            pattern: {
              thousandSeparated: true,
              mantissa: 2
            }
          },
          validator: (value, cb) => cb(this.gteZero(value))
        }
      ],
      benchMarkColumns: [],
      attributeColumns: [],
      lineItemPricingMapping: [],
      mappingForBuyMetric: [],
      benchMarkList: [],
      selectedBenchmarks: [],
      selectedAttributes: [],
      labels: [],
      labelTypes: [],
      benchmarkToRemove: [],
      lineItemLinkPopup: false,
      allMediaPlans: [],
      importOrderId: 0,
      isReadOnlyEntityDropdown: false,
      campaignOrAdGroupLinked: [],
      benchmarksOrdering: [],
      mediaPlanLabels: [],
      edsColumns: [],
      costSourceEnums: [
        { id: "Publisher", name: "Publisher" },
        { id: "Ad Server", name: "Advanced" },
        { id: "Ad Server (Cost)", name: "Advanced" },
        { id: "Ad Server (Cost & Discount Delivery)", name: "Advanced" },
        { id: "Others", name: "Others" },
        { id: "Others (Cost)", name: "Others" },
        { id: "Others (Cost & Discount Delivery)", name: "Others" }
      ],
      benchmarksToBeDisabled: [],
      enableConfirmPopup: false,
      isDeleteEnabled: false,
      rowtoBeDeleted: 0,
      onSaveOfMediaPlan: false,
      onFailToSaveMediaPlan: false,
      confirmBoxText: '',
      confirmBoxHeader: '',
      confirmBoxShowCancel: false,
      allBuyMetrics: [],
      copiedLineItems: [],
      selectedLineItemDataParameters: [],
      lineItemDataParametersOrder: [],
      showLineItems: false,
      nomenclatureSettings: [],
      lineItemPublisher: '',
      bundleIdsCount: new Map(),
      attributeColumnMapper: {}
    };
  },
  watch: {
    'mergedRow': function (value) {
      if (value.length != 0) {
        if (this.bundleColumns == 0) {
          this.bundleColumns.push({
            title: "Package Cost",
            data: "bundleCost",
            type: "numeric",
            minwidth: "100px",
            width: "100px",
            validator: 'numeric',
            numericFormat: {
              pattern: {
                thousandSeparated: true,
                mantissa: 2
              }
            }
          },
          {
            title: "Package Discount",
            data: "bundleDiscount",
            type: "numeric",
            minwidth: "100px",
            width: "110px",
            validator: 'numeric',
            numericFormat: {
              pattern: {
                thousandSeparated: true,
                mantissa: 2
              }
            }
          })
        }
      } else {
        this.bundleColumns = [];
      }
      this.hot.updateSettings({
        mergeCells: [...this.mergedRow],
        invalidCellClassName: "",
        columns: [
          ...this.fixedDependentColumns,
          ...this.bundleColumns,
          ...this.fixedCostColumns,
          ...this.attributeColumns,
          ...this.fixedColumns,
          ...this.benchMarkColumns
        ]
      })
    }
  },
  props: ["mediaPlanObject", "dataParameterPricingTypeMapping", "lineItemDataParameters", "budgetCenterConfiguration", "lineItemTypes"],
  computed: {
    settings: function () {
      var self = this;
      return {
        data: [],
        height: 370,
        selectionMode: 'multiple',
        columns: [...this.fixedDependentColumns, ...this.fixedCostColumns, ...this.fixedColumns],
        colWidths: [200],
        afterChange: function (changes, source) {
          self.lineItemDataChange(changes, source)
          if (self.$root.$children[0] != undefined && self.$root.$children[0].mediaPlanVersionSpinner != true) {
            self.$root.$children[0].spinner = false;
          }
        },
        beforePaste (data, coords) {
          self.$root.$children[0].spinner = true;
          // diable spinner for if entity value is copy - pasted and entity column is disabled
          if (coords[0].startCol == 5 && coords[0].endCol == 5 && self.isReadOnlyEntityDropdown == true) {
            self.$root.$children[0].spinner = false;
          }
        },
        manualColumnResize: true,
        manualRowResize: true,
        customBorders: [],
        mergeCells: [],
        fixedColumnsLeft: 5,
        readOnlyCellClassName: "read-only-class",
        invalidCellClassName: "invalid-cell-data",
        observeDOMVisibility: true,
        rowHeaders: this.setRowHeader,
        rowHeaderWidth: 25,
        manualRowMove: true,
        afterRowMove: this.rowMoved,
        afterSelectionEnd: function (row, column, row2, column2, preventScrolling, selectionLayerLeve) {
          self.setValidMergefalse();
          self.setValidUnmergefalse()
          var recentSelection = [row, column, row2, column2];
          self.selectedRange = [];
          self.selectedRange.push(recentSelection);
          let totalRows = this.countRows();
          if (totalRows > 1) {
            const selected = self.selectedRange || [];
            let data = [];

            if (selected.length === 1) {
              data = this.getData(...selected[0]);
            } else {
              for (let i = 0; i < selected.length; i += 1) {
                const item = selected[i];
                data.push(this.getData(...item));
              }
            }
            if (selected.length > 0 && selected[0][1] == 3 && selected[0][3] == 3 && selected[0][0] != selected[0][2]) {
              const rowToMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1], rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
              const exists = self.mergedRow.some(existingArray => JSON.stringify(existingArray) === JSON.stringify(rowToMerge));
              const firstData = data[0][0];
              const validmerge = data.every(array => array[0] === firstData);
              if (!exists && validmerge) {
                self.setValidMergetrue();
              }
              if (exists) {
                self.setButtonToUnmerge();
              }
            }
          }
        },

        afterOnCellMouseDown: function (e, coords, TD) {
          if (coords.col === 0) {
            let rowData = self.getRowSourceData(coords.row);
            rowData.entityTypeId = self.entityEnum.find(x => x.name == rowData.entity).id
            if ($(e.toElement).attr("id") == "linkEntity") {
              var handsonData = self.getTableSourceData();
              var mediaPlanLinkedCampaigns = [];
              var mediaPlanLinkedAdgroups = [];
              handsonData.forEach(function (lineItemData) {
                var entityTypeId = lineItemData.entityTypeId;
                if (entityTypeId == 1) {
                  var lineItemLinkedCampaigns = lineItemData.linkedEntities;
                  lineItemLinkedCampaigns.forEach(function (entity) {
                    var lineItemInformation = [];
                    lineItemInformation.push({
                      lineItemId: lineItemData.lineItemId,
                      lineItemName: lineItemData.channelType + "-" + lineItemData.publisher + "-" + lineItemData.theme
                    })
                    mediaPlanLinkedCampaigns.push({
                      entityId: entity.entityId,
                      lineItemInformation: lineItemInformation,
                      lineItemId: lineItemData.lineItemId,
                      lineItemName: lineItemData.channelType + "-" + lineItemData.publisher + "-" + lineItemData.theme
                    });
                  });
                }
                if (entityTypeId == 2) {
                  var lineItemLinkedAdgroups = lineItemData.linkedEntities;
                  lineItemLinkedAdgroups.forEach(function (entity) {
                    var lineItemInformation = [];
                    lineItemInformation.push({
                      lineItemId: lineItemData.lineItemId,
                      lineItemName: lineItemData.channelType + "-" + lineItemData.publisher + "-" + lineItemData.theme
                    })
                    mediaPlanLinkedAdgroups.push({
                      entityId: entity.entityId,
                      lineItemInformation: lineItemInformation
                    });
                  });
                }
              });
              var linkedEntities = rowData.linkedEntities;
              var linkedEntityIds = [];
              linkedEntities.forEach(function (entity) {
                linkedEntityIds.push(entity.entityId);
              });
              self.alreadyLinkedCampaignIds = mediaPlanLinkedCampaigns;
              self.alreadyLinkedAdgroupIds = mediaPlanLinkedAdgroups;
              if (rowData.entityTypeId == 1) {
                var filteredCampaignArray = mediaPlanLinkedCampaigns.filter(
                  function (x) {
                    return linkedEntityIds.indexOf(x.entityId) < 0;
                  }
                );
                self.alreadyLinkedCampaignIds = filteredCampaignArray;
              }
              if (rowData.entityTypeId == 2) {
                var filteredAdgroupArray = mediaPlanLinkedAdgroups.filter(
                  function (x) {
                    return linkedEntityIds.indexOf(x.entityId) < 0;
                  }
                );
                self.alreadyLinkedAdgroupIds = filteredAdgroupArray;
              }
              var mediaPlanName = self.mediaPlanName;
              var lineItemName =
                rowData.channelType +
                "-" +
                rowData.publisher +
                "-" +
                rowData.theme;
              self.lineItemName = lineItemName;
              self.mediaPlanName = mediaPlanName;
              self.lineItemLinkedEntities = linkedEntityIds;
              self.linkedEntityTypeId = rowData.entityTypeId.toString();
              self.lineItemOrderId = rowData.orderId;
              self.publisherName = rowData.publisher;
              self.autoSave = false;
              if (self.linkedEntityTypeId == 1) {
                self.$refs.linkEntity.setLinkEntity(true, self.linkedEntityTypeId, self.lineItemName, self.lineItemLinkedEntities, self.alreadyLinkedCampaignIds, self.lineItemOrderId, self.publisherName, false);
              }
              if (self.linkedEntityTypeId == 2) {
                self.$refs.linkEntity.setLinkEntity(true, self.linkedEntityTypeId, self.lineItemName, self.lineItemLinkedEntities, self.alreadyLinkedAdgroupIds, self.lineItemOrderId, self.publisherName, false);
              }
            }
            if ($(e.toElement).attr("id") == "delete-lineItem") {
              if (!(self.$root.$children[0].isEstimateEnabled)) {
                self.rowtoBeDeleted = coords.row;
                self.isDeleteEnabled = true;
                self.confirmBoxHeader = "Delete Line Item"
                self.confirmBoxShowCancel = true;
                self.confirmBoxText = 'Are you sure you want to delete?'
                self.enableConfirmPopup = true;
              }
            }
            if ($(e.toElement).attr("id") == "addFractions") {
              if (!(self.$root.$children[0].isEstimateEnabled)) {
                let rowData = self.getRowSourceData(coords.row);
                self.lineItemOrderId = rowData.orderId;
                self.lineItemFractions = rowData.lineItemFractions;
                self.lineItemId = rowData.lineItemId;
                self.isFractionEnabled = rowData.isFractionEnabled;
                self.lineItemPublisher = rowData.onlyPublisher;
                self.showFractionsModal = true;
              }
            }
            if ($(e.toElement).attr("id") == "costPacingOptions") {
              if (!(self.$root.$children[0].isEstimateEnabled)) {
                let rowData = self.getRowSourceData(coords.row);
                lineItemName =
                  rowData.channelType +
                  "-" +
                  rowData.publisher +
                  "-" +
                  rowData.theme;
                self.lineItemName = lineItemName;
                self.startdate = rowData.startdate == undefined || rowData.startdate == null ? moment(self.$root.$children[0].mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY") : rowData.startdate;
                self.enddate = rowData.enddate == undefined || rowData.enddate == null ? moment(self.$root.$children[0].mediaPlanObj.dateFilters[1]).format("MMM DD,YYYY") : rowData.enddate;
                self.cost = rowData.cost;
                self.costPacingJson = rowData.costPacingJson;
                self.orderId = rowData.orderId;
                self.costPacingTypeId = rowData.costPacingTypeId;
                self.showCostPacingModal = true;
              }
            }
          }
        },
        cells: function (row, col) {
          const cellPrp = {};
          if (col === 0) {
            cellPrp.readOnly = true;
            cellPrp.renderer = function (
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) {
              td.innerHTML =
                '<div><i class="fa fa-trash" id ="delete-lineItem" title="Delete"></i>' +
                '<i class= "fa fa-link" id= "linkEntity" title="Link Entities"></i>' +
                '<i class="fa fa-key" id="addFractions" title="Add Child"></i>' +
                '<i class="fa fa-line-chart" id="costPacingOptions" title="Cost Pacing"></i></div>';
            };
          }
          return cellPrp;
        }
      };
    },
    hot: function () {
      return this.$refs.table.hotInstance;
    }
  },
  mounted () {
    $(".perfect-scroll-bar").perfectScrollbar({ suppressScrollX: true });
  },
  methods: {
    init: function (intermediateSave) {
      var self = this;
      this.addFieldColumns();
      let promises = [];

      self.isReadOnlyEntityDropdown = false;
      self.fixedDependentColumns[5].source = self.entityEnum.map(x => x.name);

      self.lineItemPublishersAPIData = [];
      self.publisherwhiteListEnabled = false;
      var portalPromise = this.apiService.getPortalDetails();
      portalPromise.then(response => {
        var portalId = response.data.data.id;
        self.publisherwhiteListEnabled = response.data.data.enablePublisherWhiteList == undefined ? false : response.data.data.enablePublisherWhiteList;
        self.staticPublishersEnabled = response.data.data.staticPublisherListEnabled == undefined ? false : response.data.data.staticPublisherListEnabled;
        if (self.staticPublishersEnabled) {
          var lineitemPublishers = this.apiService.getStaticLineItemPublishers(portalId);
          lineitemPublishers
            .then(res => {
              res.data.publishers.forEach(element => {
                element.publisher = element.name
              });
              self.lineItemPublishersAPIData = res.data.publishers;
              self.fixedDependentColumns[3] = {
                title: parseInt(deltax.BillingPortalId) == 259 ? "Media Title" : "Publisher",
                data: "publisher",
                type: "dropdown",
                source: [],
                allowInvalid: false,
                wordWrap: false,
                filter: true,
                minwidth: "150px",
                width: "150px",
                allowEmpty: false
              };
              self.fixedDependentColumns[3].source = self.lineItemPublishersAPIData.map(
                x => x.displayName
              );
            })
            .catch(x => {
              console.log("Error occured in getting channel type");
            });
          promises.push(lineitemPublishers);
        } else {
          var lineItemPublishersPromise = this.apiService.getLineItemPublishers();
          lineItemPublishersPromise
            .then(res => {
              res.data.publishers.forEach(element => {
                element.name = element.publisher
              });
              self.lineItemPublishersAPIData = res.data.publishers;
              self.fixedDependentColumns[3].source = self.lineItemPublishersAPIData.map(
                x => x.displayName
              );
            })
            .catch(x => {
              console.log("Error occured in getting channel type");
            });
          promises.push(lineItemPublishersPromise);
        }

        const labels = this.apiService.getLabels();
        labels.then(res => {
          self.labels = res.data.result;
          if (self.labels.length > 0) {
            self.labels.forEach(item => {
              var description = item.description;
              if (description != undefined && description != "") {
                item.name = item.name + "(" + description + ")";
              }
            });
          }
        });
        const labelDimensions = this.apiService.getLabelTypes();
        labelDimensions.then(res => {
          var labelTypes = res.data.result;
          self.labelTypes = labelTypes;

          self.labelTypes.forEach(element => {
            var object = {
              id: element.name + "-" + element.id,
              name: element.name
            };
            if (
              !self.checkboxAttributeList.some(
                el => el.id == object.id
              )
            ) {
              this.checkboxAttributeList.push(object);
            }
          });
        });

        self.fixedDependentColumns[2].source = self.lineItemTypes.map(
          x => x.name
        );

        self.benchMarkList = self.lineItemDataParameters
          .filter(x => x.name != "Fixed" && x.name != "Days")
          .map(x => x);
        self.fixedColumns[3].source = self.lineItemDataParameters
          .filter(x => x.name != "Fixed" && x.name != "Days")
          .map(x => x.name);

        self.allBuyMetrics = [];

        self.fixedCostColumns[0].source = self.costSourceEnums.map(x => x.id);
        let firstCostSource = self.costSourceEnums.map(x => x.id)[0];
        self.fixedColumns[0].source = self.dataParameterPricingTypeMapping
          .filter(x => x.name == firstCostSource)[0]
          .lineItemPricing.map(x => x.name);

        var buyMetricMapping = self.dataParameterPricingTypeMapping.filter(x => x.id).map(x => x.lineItemPricing.filter(y => y.name))
        buyMetricMapping.forEach(lineItemPricing => {
          lineItemPricing.forEach(buyType => {
            buyType.DataParameter.forEach(metric => { if (metric.name != undefined) { self.allBuyMetrics.push(metric.name) } })
          })
        })

        const edsColumnsPromise = this.apiService.getEDSReferenceKeys();
        edsColumnsPromise.then(res => {
          var edsResponse = res.data.data;
          self.edsColumns = Array.from(new Set(edsResponse.map(s => s.edsId))).map(edsId => {
            return {
              edsId: edsId,
              edsName: edsResponse.find(s => s.edsId == edsId).edsName,
              businessProfileId: parseInt(deltax.businessProfileId),
              edsTypeId: edsResponse.find(s => s.edsId == edsId).edsTypeid,
              id: edsId,
              name: edsResponse.find(s => s.edsId == edsId).edsName
            }
          })

          self.edsColumns.forEach(element => {
            var object = {
              id: 'eds' + "-" + element.id,
              name: element.name
            };
            if (
              !self.checkboxAttributeList.some(
                el => el.id == object.id
              )
            ) {
              self.checkboxAttributeList.push(object);
            }
          });
        });
        promises.push(edsColumnsPromise);

        Promise.all(promises).then(responses => {
          self.lineItemData = [];
          this.attributeColumns = [];
          var visibleAttributes = self.$root.$children[0].visibleAttributes;
          if (visibleAttributes != undefined && visibleAttributes != null && visibleAttributes != "") {
            var attributes = visibleAttributes.split(',').filter(x => self.checkboxAttributeList.map(x => x.name).includes(x));
            this.pushIntoAttributeColumns(attributes);
            this.selectedAttributes = this.attributeColumns.map(x => x.title);
          }
          if (this.$root.$children[0].mediaPlanId > 0) {
            var mediaplanData = self.$root.$children[0].mediaPlanData.lineItems;
            this.MergeRowsoninit(mediaplanData, false);

            this.setDataToLineItems(mediaplanData);
            if (this.getTableSourceData().length > 0) {
              this.showLineItems = true;
              this.$parent.$parent.$parent.showDigitalLineItems = true;
            } else {
              this.lineItemData = [];
              this.hot.updateSettings({
                data: this.lineItemData
              })
              this.showLineItems = false;
              this.$parent.$parent.$parent.showDigitalLineItems = false;
            }
            self.$root.$children[0].spinner = false;
            self.$root.$children[0].mediaPlanVersionSpinner = false;
            self.$root.$children[0].openLIMapper = true;
            self.$root.$children[0].clicked = false;
          } else {
            this.lineItemData = [];
            this.hot.updateSettings({
              data: this.lineItemData
            })
            this.showLineItems = false;
            this.$parent.$parent.$parent.showDigitalLineItems = false;
          }
          self.$root.$children[0].spinner = false;
          self.$root.$children[0].mediaPlanVersionSpinner = false;
          self.$root.$children[0].clicked = false;
        });
      }).catch(i => {});

      this.apiService.getNomenclatureSettingsData().then((response) => {
        if (response.data.data.length == 0) {
          self.nomenclatureSettings = [];
        } else {
          self.nomenclatureSettings = response.data.data;
        }
      });
    },
    MergeRowsoninit (mediaplanData, isLineItemImported) {
      this.bundleIdsCount = new Map()
      mediaplanData.forEach(li => {
        if (li.bundleId != undefined || li.bundleId != null) {
          if (this.bundleIdsCount.has(li.bundleId)) {
            var bundleCount = this.bundleIdsCount.get(li.bundleId);
            bundleCount = bundleCount + 1;
            this.bundleIdsCount.set(li.bundleId, bundleCount);
          } else {
            this.bundleIdsCount.set(li.bundleId, 1);
          }
        }
      });
      if (this.bundleIdsCount.size != 0) {
        this.mergedRow = [];
        for (var [key, value] of this.bundleIdsCount) {
          if (value > 1) {
            var rowNumber = isLineItemImported ? mediaplanData.map(x => x.orderId).indexOf(key) : mediaplanData.length - mediaplanData.map(x => x.orderId).indexOf(key) - 1;
            const rowToMerge = { row: rowNumber, col: 3, rowspan: value, colspan: 1 };
            const bundleCostMerge = { row: rowNumber, col: 6, rowspan: value, colspan: 1 };
            const bundleDiscountMerge = { row: rowNumber, col: 7, rowspan: value, colspan: 1 };
            this.mergedRow.push(rowToMerge, bundleCostMerge, bundleDiscountMerge);
          }
        }
      }
    },
    setValidMergetrue () {
      this.$root.$children[0].isValidSelectionforMerge = true;
    },
    setButtonToUnmerge () {
      this.$root.$children[0].isValidSelectionforUnmerge = true;
    },
    setValidMergefalse () {
      this.$root.$children[0].isValidSelectionforMerge = false;
    },
    setValidUnmergefalse () {
      this.$root.$children[0].isValidSelectionforUnmerge = false;
    },

    lineItemDataChange: function (changes, source) {
      if (this.skipDataChanged) {
        this.skipDataChanged = false;
        return;
      }
      if (source == "Custom.Computation") {
        this.hot.updateSettings({
          columns: [
            ...this.fixedDependentColumns,
            ...this.bundleColumns,
            ...this.fixedCostColumns,
            ...this.attributeColumns,
            ...this.fixedColumns,
            ...this.benchMarkColumns
          ]
        });
        return;
      }
      if (
        source == "CopyPaste.paste" || source == "edit" || source == "Autofill.fill"
      ) {
        this.$root.$children[0].isDigitalLineItemsValid = true;
        var tempLineItems = [];
        changes.forEach(([row, prop, oldValue, newValue]) => {
          if (!tempLineItems.map(x => x).includes(row)) {
            tempLineItems.push(row);
            let li = this.getRowSourceData(row);
            if (Object.keys(li).includes("universe")) {
              li.universe = parseFloat(li.universe) >= 0 ? parseFloat(li.universe) : li.universe
            }
            if (Object.keys(li).includes("reachPercentage")) {
              li.reachPercentage = parseFloat(li.reachPercentage) >= 0 ? parseFloat(li.reachPercentage) : li.reachPercentage
            }
            this.setCopiedDataToLineItems(li, row, prop, source);
            if (this.$root.$children[0].isSummaryTabOpen) {
              this.$emit('setSummaryTabData');
            }
          }
        });
      }

      if (changes != null && source != "loadData") {
        if (this.copiedLineItems.length != 0) {
          this.setDataToLineItems(this.copiedLineItems);
          this.setDateRange();
        }
        this.copiedLineItems = [];
      }

      if (this.hot != null) {
        this.$emit("calculateLineItemTotalBudget");
      }
    },
    setCopiedDataToLineItems: function (li, row, prop, source) {
      var displayNames = this.lineItemPublishersAPIData.map(x => x.displayName)
      var self = this;
      self.selectedLineItemDataParameters = [];
      var mediaPlanStartDate = self.$root.$children[0].mediaPlanObj.dateFilters[0];
      var mediaPlanEndDate = self.$root.$children[0].mediaPlanObj.dateFilters[1];
      let lineItemRow = {
        type: li.channelType
          ? self.lineItemTypes.find(x => x.name == li.channelType).id
          : li.channelType,
        publisher:
          li.publisher
            ? displayNames.includes(li.publisher)
              ? (self.lineItemPublishersAPIData.find(x => x.displayName == li.publisher).name).replace(/[\t\n]/gm, ' ').trim()
              : li.publisher.replace(/[\t\n]/gm, ' ').trim()
            : li.publisher,
        onlyPublisher: li.onlyPublisher,
        theme: li.theme ? li.theme.replace(/[\t\n]/gm, ' ').trim() : "",
        entity: li.entity ? li.entity : this.setLineItemEntity(li),
        entityTypeId: li.entity
          ? self.entityEnum.find(x => x.name == li.entity).id
          : 1,

        costSource: li.costSource
          ? this.setPricingTypeDataForGivenCostSource(li, row, prop, source)
          : li.buyType
            ? this.setCostSource(li, prop, source)
            : li.costSource,

        buyType: li.buyType ? this.setBuyType(li, prop, source) : li.buyType,
        buyMetric: li.buyMetric,
        pricingTypeReferenceValueId: li.pricingTypeReferenceValueId != undefined
          ? li.pricingTypeReferenceValueId
          : li.costSource && li.buyType
            ? self.dataParameterPricingTypeMapping
              .filter(x => x.name == self.costSourceEnums.find(y => y.id == li.costSource).name)[0]
              .lineItemPricing.filter(x => x.name == li.buyType)
              .map(x => x.id)[0]
            : 0,
        pricingTypeId: li.pricingTypeId != undefined && li.pricingTypeId != ""
          ? li.pricingTypeId
          : li.costSource && li.buyMetric && li.buyType
            ? self.dataParameterPricingTypeMapping
              .filter(x => x.name == self.costSourceEnums.find(y => y.id == li.costSource).name)[0]
              .lineItemPricing.filter(x => x.name == li.buyType)[0]
              .DataParameter.filter(x => x.name == li.buyMetric)
              .map(x => x.id)[0]
            : 0,

        kpiMetricId: li.kpiMetric ? self.benchMarkList.find(x => x.name == li.kpiMetric).id : li.kpiMetric,
        kpiValue: li.kpiValue,
        budget: li.cost,
        perUnitPrice: li.unitCost,
        mediaPlanVersionId: li.mediaPlanVersionId ? li.mediaPlanVersionId : 0,
        orderId: li.orderId ? li.orderId : li.orderId = this.getOrderId(li),
        costPacingTypeId: li.costPacingTypeId ? li.costPacingTypeId : 1,
        costPacingJson: li.costPacingJson,
        creativeFormat: li.creativeformat ? li.creativeformat.replace(/[\t\n]/gm, ' ').trim() : "",
        note1: li.note1 ? li.note1.replace(/[\t\n]/gm, ' ').trim() : "",
        note2: li.note2 ? li.note2.replace(/[\t\n]/gm, ' ').trim() : "",
        startDate: li.startdate == undefined || li.startdate == "" || li.startdate == null ? moment(mediaPlanStartDate).format("MMM DD, YYYY") : moment(li.startdate).format("MMM DD, YYYY"),
        endDate: li.enddate == undefined || li.enddate == "" || li.enddate == null ? moment(mediaPlanEndDate).format("MMM DD, YYYY") : moment(li.enddate).format("MMM DD, YYYY"),
        roNumber: li.ronumber ? li.ronumber.replace(/[\t\n]/gm, ' ').trim() : "",
        isFractionEnabled: li.isFractionEnabled ? li.isFractionEnabled : 0,
        lineItemFractions: li.lineItemFractions ? li.lineItemFractions : "",
        targeting: li.targeting,
        nickname: li.nickname,
        objective: li.objective ? this.$root.$children[0].mediaPlanObjectivesSource.find(x => x.name == li.objective).id : li.objective,
        id: li.lineItemId ? li.lineItemId : 0,
        universe: li.universe,
        reachPercentage: li.reachPercentage,
        linkedEntities:
                  li.linkedEntities ? li.linkedEntities : [],
        isCostCalcuated:
                  li.costSource
                    ? li.costSource == "Ad Server (Cost)" ||
                      li.costSource == "Others (Cost)" ||
                      li.costSource == "Ad Server (Cost & Discount Delivery)" ||
                      li.costSource == "Others (Cost & Discount Delivery)"
                      ? 1
                      : 0
                    : 0,
        isDiscountOverdelivery:
                  li.costSource
                    ? li.costSource == "Ad Server (Cost & Discount Delivery)" ||
                      li.costSource == "Others (Cost & Discount Delivery)"
                      ? 1
                      : 0
                    : 0,
        labels: this.setCopiedLineItemLabels(li),
        referenceKeys: this.setReferenceKey(li, prop),
        roId: li.roId,
        supplierId: li.publisher
          ? displayNames.includes(li.publisher)
            ? self.lineItemPublishersAPIData.find(
              x => x.displayName == li.publisher
            ).supplierId
            : null
          : null,
        bundleCost: li.bundleCost,
        bundleDiscount: li.bundleDiscount,
        bundleId: li.bundleId,
        mergedPublisher: li.mergedPublisher,
        field1: li.field1,
        field2: li.field2,
        field3: li.field3,
        field4: li.field4,
        field5: li.field5,
        field6: li.field6,
        field7: li.field7,
        field8: li.field8,
        field9: li.field9,
        field10: li.field10
      }
      self.AssignLineItemsLabelType(lineItemRow, li)
      this.setFixedColumnsSource(li);
      self.setLineItemDataParameters(li, lineItemRow, prop);
      self.setBudgetUnitCost(li, prop, lineItemRow);
      self.setPricingRateData(li, prop);
      lineItemRow["lineItemDataParameters"] = this.selectedLineItemDataParameters;
      self.selectedLineItemDataParameters.forEach(element => {
        if (!self.lineItemDataParametersOrder.map(x => x.id).includes(element.dataParameterId)) {
          self.lineItemDataParametersOrder.push({
            title: self.lineItemDataParameters.find(x => x.id == element.dataParameterId).name,
            id: element.dataParameterId
          })
        }
      });

      if (self.copiedLineItems.map(x => x.orderId).includes(lineItemRow.orderId)) {
        self.copiedLineItems = self.copiedLineItems.filter(x => x.orderId != lineItemRow.orderId)
      }

      self.copiedLineItems.push(lineItemRow);

      self.selectedLineItemDataParameters = [];
      if (prop == "startdate") {
        this.skipSettingMediaPlanDateFilter = false;
      }
      if (prop == "enddate") {
        this.skipSettingMediaPlanDateFilter = false;
      }
    },
    setFixedColumnsSource: function (li) {
      if (li.costSource) {
        var costSource = this.costSourceEnums.find(
          y => y.id == li.costSource
        ).name;

        var sourceForBuyType = this.dataParameterPricingTypeMapping
          .filter(x => x.name == costSource)[0]
          .lineItemPricing.map(x => x.name);

        this.fixedColumns[0] = {
          title: "Buy Type",
          data: "buyType",
          type: "dropdown",
          source: sourceForBuyType,
          allowInvalid: false,
          minwidth: "100px",
          width: "100px",
          allowEmpty: false,
          validator: (value, cb) => cb(this.isValidBuyType(value))
        };
      }
      if (li.buyType) {
        this.mappingForCostSource = this.dataParameterPricingTypeMapping.filter(
          function (element) {
            return element.lineItemPricing.some(function (subElement) {
              return subElement.name === li.buyType;
            });
          }
        );

        var mappingForBuyMetric = this.dataParameterPricingTypeMapping.filter(
          x => x.name == this.mappingForCostSource.map(x => x.name)[0]
        )[0].lineItemPricing.filter(x => x.name == li.buyType)[0]
          .DataParameter.map(x => x.name);

        this.fixedColumns[1] =
          {
            title: "Buy Metric",
            data: "buyMetric",
            type: "dropdown",
            source: mappingForBuyMetric,
            minwidth: "100px",
            width: "100px",
            wordWrap: false,
            allowEmpty: false,
            validator: (value, cb) => cb(this.checkIfExistsInKpiMetric(value))
          }
      }
    },
    setReachUniverseReachPercentage: function (li, prop, lineItemRow) {
      var reachDataParameterId = this.lineItemDataParameters
        .find(x => x.name == 'Reach').id;
      var finalValue = 0;
      var reachData = lineItemRow.lineItemDataParameters
        .find(x => x.dataParameterId == reachDataParameterId)
      switch (prop) {
        case "Reach":
        // When reach changes update reachPercentage keep constant universe
          if (!parseInt(li.universe) > 0 && !parseInt(li.reachPercentage) > 0) {
          // Do nothing
            return true;
          }
          if (parseInt(li.universe) > 0) {
            finalValue = li.Reach / li.universe * 100;
            lineItemRow.reachPercentage = finalValue.toFixed(2)
          } else {
            finalValue = li.Reach / li.reachPercentage * 100;
            if (li.reachPercentage == 0) {
              finalValue = 0
            }
            lineItemRow.universe = finalValue.toFixed(2);
          }
          return true;
        case "universe":

          // When universe changes update reach constant reachPercentage
          if (!parseInt(li.reachPercentage) > 0 && !parseInt(li.Reach) > 0) {
          // Do nothing
            return true;
          }
          if (parseInt(li.reachPercentage) > 0) {
            finalValue = li.reachPercentage * li.universe / 100;
            if (li.universe == 0 || isNaN(li.universe)) {
              finalValue = 0
            }
            if (reachData) {
              lineItemRow.lineItemDataParameters
                .find(x => x.dataParameterId == reachDataParameterId)
                .dataParameterValue = parseInt(finalValue);
            } else {
              lineItemRow.lineItemDataParameters
                .push({
                  dataParameterId: reachDataParameterId,
                  dataParameterValue: parseInt(finalValue)
                })
              li.Reach = parseInt(finalValue)
            }
          } else {
            finalValue = li.Reach / li.universe * 100;
            if (li.universe == 0 || isNaN(li.universe)) {
              finalValue = 0
            }
            lineItemRow.reachPercentage = finalValue.toFixed(2);
          }
          return true;
        case "reachPercentage":
        // When reachPercentage changes update reach constant universe
          if (!parseInt(li.Reach) > 0 && !parseInt(li.universe) > 0) {
          // Do nothing
            return true;
          }
          if (parseInt(li.universe) > 0) {
            finalValue = li.reachPercentage * li.universe / 100;
            if (reachData) {
              lineItemRow.lineItemDataParameters
                .find(x => x.dataParameterId == reachDataParameterId)
                .dataParameterValue = parseInt(finalValue);
            } else {
              lineItemRow.lineItemDataParameters
                .push({
                  dataParameterId: reachDataParameterId,
                  dataParameterValue: parseInt(finalValue)
                })
              li.Reach = parseInt(finalValue)
            }
          } else {
            finalValue = li.Reach / li.reachPercentage * 100;
            if (li.reachPercentage == 0) {
              finalValue = 0
            }
            lineItemRow.universe = finalValue.toFixed(2);
          }
          return true;
        default:
          break;
      }
    },
    setPricingTypeDataForGivenCostSource: function (rowData, row, prop, source) {
      var costSource = this.costSourceEnums.find(
        y => y.id == rowData.costSource
      ).name;

      var sourceForBuyType = this.dataParameterPricingTypeMapping
        .filter(x => x.name == costSource)[0]
        .lineItemPricing.map(x => x.name);

      var buyType = rowData.buyType;
      if (rowData.buyType && prop != "buyMetric" && sourceForBuyType.includes(rowData.buyType)) {
        rowData.buyType = buyType
      } else if (prop == "buyMetric" && rowData.buyMetric) {
        var buyTypeForGivenBuyMetric = this.dataParameterPricingTypeMapping.filter(x => x.name == costSource
        )[0].lineItemPricing.filter(x => x.DataParameter.find(y => y.name == rowData.buyMetric)).map(x => x.name)[0]
        rowData.buyType = buyTypeForGivenBuyMetric != undefined ? buyTypeForGivenBuyMetric : sourceForBuyType[0]
      } else {
        rowData.buyType = sourceForBuyType[0]
      }

      this.lineItemPricingMapping = this.dataParameterPricingTypeMapping.filter(
        x => x.name == costSource
      )[0].lineItemPricing;

      var sourceForBuyMetric = this.lineItemPricingMapping
        .filter(x => x.name == rowData.buyType)[0]
        .DataParameter.map(x => x.name);

      rowData.buyMetric = rowData.buyMetric && prop != "buyType"
        ? rowData.buyMetric && sourceForBuyMetric.includes(rowData.buyMetric)
          ? rowData.buyMetric
          : sourceForBuyMetric[0]
        : sourceForBuyMetric[0]

      let kpiMetric =
         rowData.kpiMetric && prop != "buyType" && prop != "buyMetric"
           ? rowData.kpiMetric
           : rowData.buyMetric

      rowData.kpiMetric = this.fixedColumns[3].source.find(x => x == kpiMetric) || "Impressions";

      var costSourceId = this.dataParameterPricingTypeMapping
        .find(x => x.name == this.costSourceEnums.find(y => y.id == rowData.costSource).name).id

      rowData.pricingTypeId = this.dataParameterPricingTypeMapping
        .filter(x => x.name == this.costSourceEnums.find(y => y.id == rowData.costSource).name)[0]
        .lineItemPricing.filter(x => x.name == rowData.buyType)[0]
        .DataParameter.filter(x => x.name == rowData.buyMetric)
        .map(x => x.id)[0]

      rowData.pricingTypeReferenceValueId = this.dataParameterPricingTypeMapping
        .filter(x => x.name == this.costSourceEnums.find(y => y.id == rowData.costSource).name)[0]
        .lineItemPricing.filter(x => x.name == rowData.buyType)
        .map(x => x.id)[0]

      if (rowData.cost != undefined && rowData.cost != null && rowData.cost != "" && rowData.unitCost != undefined && rowData.unitCost != null && rowData.unitCost != "" && (prop == "costSource" || prop == "buyType" || prop == "buyMetric")) {
        var isComputed = rowData.buyMetric && rowData.buyMetric != "Fixed" && rowData.buyMetric != "Days"
          ? this.benchMarkList.find(x => x.name == rowData.buyMetric).isComputed
          : false
        var pricingValue;
        if (!isComputed) {
          pricingValue = rowData.cost && rowData.unitCost ? Math.round((rowData.cost / rowData.unitCost) *
                        (rowData.buyMetric == "Impressions" ? 1000 : 1))
            : rowData.cost == 0 || rowData.unitCost == 0 ? 0 : ""
        } else {
          pricingValue = rowData.cost && rowData.unitCost
            ? rowData.buyMetric != "CTR"
              ? parseFloat(((rowData.cost / rowData.unitCost) * (rowData.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2))
              : parseFloat(((rowData.cost / rowData.unitCost) * (rowData.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4))
            : rowData.cost == 0 || rowData.unitCost == 0 ? 0 : ""
        }
        if (rowData.kpiMetric == rowData.buyMetric) {
          rowData.kpiValue = pricingValue
        }
        rowData[rowData.buyMetric] = pricingValue
      }

      return costSourceId;
    },
    setDataForGivenBuyType: function (rowData, prop, source) {
      var mappingData = [];
      if (rowData["costSource"]) {
        var costSource = this.costSourceEnums.find(
          y => y.id == rowData.costSource
        ).name;
        this.lineItemPricingMapping = this.dataParameterPricingTypeMapping.filter(
          x => x.name == costSource
        )[0].lineItemPricing;

        mappingData = this.lineItemPricingMapping
          .filter(x => x.name == rowData.buyType)[0]
          .DataParameter.map(x => x.name);
      } else {
        this.mappingForCostSource = this.dataParameterPricingTypeMapping.filter(
          function (element) {
            return element.lineItemPricing.some(function (subElement) {
              return subElement.name === rowData.buyType;
            });
          }
        );

        rowData.costSource = this.costSourceEnums.find(y => y.name == this.mappingForCostSource.map(x => x.name)[0]).id;

        this.mappingForBuyMetric = this.dataParameterPricingTypeMapping.filter(
          x => x.name == this.mappingForCostSource.map(x => x.name)[0]
        )[0].lineItemPricing;

        mappingData = this.mappingForBuyMetric
          .filter(x => x.name == rowData.buyType)[0]
          .DataParameter.map(x => x.name);
      }

      rowData.buyMetric = rowData.buyMetric && prop != "buyType"
        ? rowData.buyMetric && mappingData.includes(rowData.buyMetric)
          ? rowData.buyMetric
          : mappingData[0]
        : mappingData[0]

      let kpiMetric = rowData.kpiMetric && prop != "buyType" && prop != "buyMetric"
        ? rowData.kpiMetric
        : rowData.buyMetric

      rowData.kpiMetric = this.fixedColumns[3].source.find(x => x == kpiMetric) || "Impressions";

      if (rowData.cost != undefined && rowData.cost != null && rowData.cost != "" && rowData.unitCost != undefined && rowData.unitCost != null && rowData.unitCost != "" && (prop == "costSource" || prop == "buyType" || prop == "buyMetric")) {
        var isComputed = rowData.buyMetric && rowData.buyMetric != "Fixed" && rowData.buyMetric != "Days"
          ? this.benchMarkList.find(x => x.name == rowData.buyMetric).isComputed
          : false
        var pricingValue;
        if (!isComputed) {
          pricingValue = rowData.cost && rowData.unitCost ? Math.round((rowData.cost / rowData.unitCost) *
                      (rowData.buyMetric == "Impressions" ? 1000 : 1))
            : rowData.cost == 0 || rowData.unitCost == 0 ? 0 : ""
        } else {
          pricingValue = rowData.cost && rowData.unitCost
            ? rowData.buyMetric != "CTR"
              ? parseFloat(((rowData.cost / rowData.unitCost) * (rowData.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2))
              : parseFloat(((rowData.cost / rowData.unitCost) * (rowData.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4))
            : rowData.cost == 0 || rowData.unitCost == 0 ? 0 : ""
        }
        if (rowData.kpiMetric == rowData.buyMetric) {
          rowData.kpiValue = pricingValue
        }
        rowData[rowData.buyMetric] = pricingValue
      }
      return rowData;
    },
    setBudgetUnitCost: function (li, prop, lineItemRow) {
      var self = this;
      var isComputed;
      var kpiValue;
      if ((prop == "unitCost" || prop == "cost") && li.unitCost != null && li.unitCost != undefined && parseFloat(li.unitCost) >= 0 && li.cost != undefined && li.cost != null && parseFloat(li.cost) >= 0 && li.buyMetric) {
        let quantity = 0;
        if (parseFloat(li.cost) >= 0 && (li.cost == 0 || li.cost > 0) && parseFloat(li.unitCost) >= 0 && (li.unitCost > 0 || li.unitCost == 0)) {
          isComputed = li.buyMetric && li.buyMetric != "Fixed" && li.buyMetric != "Days"
            ? this.benchMarkList.find(x => x.name == li.buyMetric).isComputed
            : false
          if (!isComputed) {
            quantity = li.unitCost > 0 ? Math.round((li.cost / li.unitCost) *
                      (li.buyMetric == "Impressions" ? 1000 : 1)) : 0
          } else {
            quantity = li.buyMetric != "CTR"
              ? li.unitCost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2)) : 0
              : li.unitCost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4)) : 0
          }
        }
        li[li.buyMetric] = prop == li.buyMetric
          ? !isComputed
            ? li[li.buyMetric]
              ? Math.round(li[li.buyMetric])
              : li.buyMetric == "CTR"
                ? parseFloat(li[li.buyMetric].toFixed(4))
                : parseFloat(li[li.buyMetric].toFixed(2))
            : li[li.buyMetric] != null && li[li.buyMetric] != undefined && li[li.buyMetric] != "" ? li[li.buyMetric] : 0
          : quantity;

        if (self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.lineItemDataParameters.find(
          x => x.name == li.buyMetric).id)) {
          self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.lineItemDataParameters.find(
            x => x.name == li.buyMetric
          ).id).dataParameterValue = parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0) ? !isComputed ? Math.round(li[li.buyMetric]) : li[li.buyMetric] : li[li.buyMetric] != null && li[li.buyMetric] != undefined && li[li.buyMetric] != "" ? li[li.buyMetric] : 0
        }

        lineItemRow["perUnitPrice"] = li.unitCost;
        if (parseFloat(li.kpiValue) >= 0 && li.buyMetric == li.kpiMetric) {
          li.kpiValue = quantity;
          if (li.kpiMetric && self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.benchMarkList.find(x => x.name == li.kpiMetric).id)) {
            self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.benchMarkList.find(x => x.name == li.kpiMetric).id).dataParameterValue = parseFloat(li.kpiValue) >= 0 ? !isComputed ? Math.round(li.kpiValue) : li.kpiValue != null && li.kpiValue != undefined && li.kpiValue != "" ? li.kpiValue : 0 : 0
          } else {
            lineItemRow["kpiValue"] = li.kpiValue;
          }
        }
      }

      if (parseFloat(li.cost) >= 0 && (li.cost == 0 || li.cost > 0) && prop == "kpiValue") {
        var unitPrice;
        if (li.buyMetric == li.kpiMetric) {
          isComputed = li.buyMetric && li.buyMetric != "Fixed" && li.buyMetric != "Days"
            ? this.benchMarkList.find(x => x.name == li.buyMetric).isComputed
            : false
          kpiValue = li.buyMetric
            ? li.buyMetric != "CTR"
              ? li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2)) : 0
              : li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4)) : 0
            : 0
          if (li.kpiMetric && self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.benchMarkList.find(x => x.name == li.kpiMetric).id)) {
            self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.benchMarkList.find(x => x.name == li.kpiMetric).id).dataParameterValue = parseFloat(li.kpiValue) >= 0 ? !isComputed ? Math.round(li.kpiValue) : li.buyMetric == "CTR" ? parseFloat(li.kpiValue.toFixed(4)) : parseFloat(li.kpiValue.toFixed(2)) : li.kpiValue != null && li.kpiValue != undefined && li.kpiValue != "" ? li.kpiValue : li.kpiValue = kpiValue
          } else {
            lineItemRow["kpiValue"] = li.kpiValue != undefined && li.kpiValue != null && li.kpiValue != "" ? li.kpiValue : li.kpiValue = kpiValue;
          }
          if (parseFloat(li.cost) >= 0 && (li.cost == 0 || li.cost > 0) && parseFloat(li.kpiValue) > 0 && li.kpiValue > 0) {
            unitPrice = li.kpiValue
              ? parseFloat(((li.cost / (li.kpiValue)) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4))
              : li.kpiValue == 0 ? 0 : li.kpiValue != undefined && li.kpiValue != null && li.kpiValue != "" ? li.kpiValue : 0

            li[li.buyMetric] = li.kpiValue;
            if (unitPrice != lineItemRow["perUnitPrice"]) {
              lineItemRow["perUnitPrice"] = unitPrice;
            }
          }
        }
      }

      if (parseFloat(li[li.kpiMetric]) >= 0 && prop != "kpiValue") {
        isComputed = li.kpiMetric && li.kpiMetric != "Fixed" && li.kpiMetric != "Days"
          ? this.benchMarkList.find(x => x.name == li.kpiMetric).isComputed
          : false
        kpiValue = li.buyMetric && li.buyMetric == li.kpiMetric
          ? li.buyMetric != "CTR"
            ? li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2)) : 0
            : li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4)) : 0
          : 0
        if (li.kpiMetric && self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.benchMarkList.find(x => x.name == li.kpiMetric).id)) {
          self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.benchMarkList.find(x => x.name == li.kpiMetric).id).dataParameterValue = parseFloat(li[li.kpiMetric]) >= 0 && (li[li.kpiMetric] > 0 || li[li.kpiMetric] == 0) ? !isComputed ? Math.round(li[li.kpiMetric]) : li.kpiMetric == "CTR" ? parseFloat(li[li.kpiMetric].toFixed(4)) : parseFloat(li[li.kpiMetric].toFixed(2)) : li.kpiValue != undefined && li.kpiValue != null && li.kpiValue != "" ? li.kpiValue : li.kpiValue = kpiValue
        } else {
          lineItemRow["kpiValue"] = li.kpiValue != undefined && li.kpiValue != null && li.kpiValue != "" ? li.kpiValue : li.kpiValue = kpiValue
        }
      }

      if (parseFloat(li.kpiValue) >= 0 && (li.kpiValue == 0 || li.kpiValue > 0) && li.buyMetric == li.kpiMetric) {
        isComputed = li.kpiMetric && li.kpiMetric != "Fixed" && li.kpiMetric != "Days"
          ? this.benchMarkList.find(x => x.name == li.kpiMetric).isComputed
          : false
        kpiValue = li.buyMetric != "CTR"
          ? li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2)) : 0
          : li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4)) : 0
        if (li.kpiMetric && self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.benchMarkList.find(x => x.name == li.kpiMetric).id)) {
          self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.benchMarkList.find(x => x.name == li.kpiMetric).id).dataParameterValue = parseFloat(li.kpiValue) >= 0 && (li.kpiValue > 0 || li.kpiValue == 0) ? !isComputed ? Math.round(li.kpiValue) : li.kpiMetric == "CTR" ? parseFloat(li.kpiValue.toFixed(4)) : parseFloat(li.kpiValue.toFixed(2)) : li.kpiValue != undefined && li.kpiValue != null && li.kpiValue != "" ? li.kpiValue : li.kpiValue = kpiValue
        } else {
          lineItemRow["kpiValue"] = li.kpiValue != undefined && li.kpiValue != null && li.kpiValue != "" ? li.kpiValue : li.kpiValue = kpiValue
        }
      }

      if (prop == li.buyMetric) {
        isComputed = li.buyMetric && li.buyMetric != "Fixed" && li.buyMetric != "Days"
          ? this.benchMarkList.find(x => x.name == li.buyMetric).isComputed
          : false
        var pricingValue = li.buyMetric != "CTR"
          ? li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2)) : 0
          : li.unitCost > 0 && li.cost > 0 ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4)) : 0
        if (parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0)) {
          if (self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.lineItemDataParameters.find(
            x => x.name == li.buyMetric).id)) {
            self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.lineItemDataParameters.find(
              x => x.name == li.buyMetric
            ).id).dataParameterValue = parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] > 0 || li[li.buyMetric] == 0) ? !isComputed ? Math.round(li[li.buyMetric]) : li.buyMetric == "CTR" ? parseFloat(li[li.buyMetric].toFixed(4)) : parseFloat(li[li.buyMetric].toFixed(2)) : li[li.buyMetric] != null && li[li.buyMetric] != undefined && li[li.buyMetric] != "" ? li[li.buyMetric] : li[li.buyMetric] = pricingValue
          }
          unitPrice = parseFloat(((li.cost / (li[li.buyMetric])) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4))
          lineItemRow["perUnitPrice"] = unitPrice;
        } else {
          if (self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.lineItemDataParameters.find(
            x => x.name == li.buyMetric).id)) {
            self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.lineItemDataParameters.find(
              x => x.name == li.buyMetric
            ).id).dataParameterValue = parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] > 0 || li[li.buyMetric] == 0) ? !isComputed ? Math.round(li[li.buyMetric]) : li.buyMetric == "CTR" ? parseFloat(li[li.buyMetric].toFixed(4)) : parseFloat(li[li.buyMetric].toFixed(2)) : li[li.buyMetric] != null && li[li.buyMetric] != undefined && li[li.buyMetric] != "" ? li[li.buyMetric] : li[li.buyMetric] = pricingValue
          }
        }
        unitPrice = parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0)
          ? parseFloat(li.cost) >= 0 && (li.cost == 0 || li.cost > 0) && parseFloat(li[li.buyMetric]) > 0 && li[li.buyMetric] > 0 ? (li.cost / Math.round(li[li.buyMetric])) * (li.buyMetric == "Impressions" ? 1000 : 1) : li.unitCost
          : li[li.buyMetric] == 0 ? 0 : li.unitCost

        if (unitPrice != lineItemRow["perUnitPrice"]) {
          lineItemRow["perUnitPrice"] = unitPrice;
        }
      }
    },
    setPricingRateData: function (li, prop) {
      if (this.benchMarkList.map(x => x.name).includes(li.buyType)) {
        var isComputed = li.buyType != "Fixed" && li.buyType != "Days"
          ? this.benchMarkList.find(x => x.name == li.buyType).isComputed
          : false
        if (parseFloat(li.cost) >= 0 && (li.cost == 0 || li.cost > 0) && parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] > 0 || li[li.buyMetric] == 0)) {
          li[li.buyType] = prop == li.buyType
            ? li[li.buyType]
            : !isComputed
              ? li[li.buyMetric] > 0 ? Math.round((li.cost / li[li.buyMetric]) * (li.buyMetric == 'Impressions' ? 1000 : 1)) : 0
              : li[li.buyMetric] > 0 ? parseFloat(((li.cost / li[li.buyMetric]) * (li.buyMetric == 'Impressions' ? 1000 : 1)).toFixed(2)) : 0

          if (this.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(this.lineItemDataParameters.find(
            x => x.name == li.buyType).id)) {
            this.selectedLineItemDataParameters.find(x => x.dataParameterId == this.lineItemDataParameters.find(
              x => x.name == li.buyType
            ).id).dataParameterValue = li[li.buyType]
          } else {
            this.selectedLineItemDataParameters.push({
              dataParameterId: this.benchMarkList.find(x => x.name == li.buyType).id,
              dataParameterValue: li[li.buyType]
            })
          }
        }
      }

      if (this.benchMarkColumns.map(x => x.title).includes("CTR")) {
        if (parseFloat(li['Impressions']) >= 0 && (li['Impressions'] > 0 || li['Impressions'] == 0) && parseFloat(li['Clicks']) >= 0 && (li['Clicks'] == 0 || li['Clicks'] > 0)) {
          li["CTR"] = prop == "CTR" ? li["CTR"] : li['Impressions'] > 0 ? parseFloat(((li['Clicks'] / li['Impressions']) * 100).toFixed(4)) : 0

          if (this.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(this.lineItemDataParameters.find(
            x => x.name == "CTR").id)) {
            this.selectedLineItemDataParameters.find(x => x.dataParameterId == this.lineItemDataParameters.find(
              x => x.name == "CTR"
            ).id).dataParameterValue = li["CTR"]
          } else {
            this.selectedLineItemDataParameters.push({
              dataParameterId: this.benchMarkList.find(x => x.name == "CTR").id,
              dataParameterValue: li["CTR"]
            })
          }
        }
      }
    },
    setLineItemEntity: function (li) {
      var tableData = this.getTableSourceData();
      var isCampaignPresent = tableData.map(x => x.entity).includes("Campaign")
      li.entity = isCampaignPresent ? "Campaign" : "AdGroup"
    },
    setReferenceKey: function (li, prop) {
      var self = this;
      var toBeAddedReferenceKeys = [];
      var lineItemRowKeys = Object.keys(li);
      lineItemRowKeys = lineItemRowKeys.filter(x => x == prop[0])
      lineItemRowKeys.forEach(element => {
        var edsPresent = self.edsColumns.map(x => x.name).includes(prop[0])
        if (edsPresent) {
          var edsId = self.edsColumns.filter(x => x.name == prop[0])[0].edsId
          if (li[prop]) {
            toBeAddedReferenceKeys.push({ edsId: edsId, lineItemId: li.lineItemId, referenceKey: li[prop] })
          }
        }
      });
      toBeAddedReferenceKeys.forEach(x => {
        var edsUpdate = li.referenceKeys.find(y => y.edsId == x.edsId)
        if (edsUpdate) {
          li.referenceKeys.find(y => y.edsId == x.edsId).referenceKey = x.referenceKey
        } else {
          li.referenceKeys.push(x)
        }
      })
      var allReferenceKeys = li.referenceKeys
      return allReferenceKeys || [];
    },
    setCopiedLineItemLabels: function (li) {
      var toBeAddedLabels = [];
      var lineItemRowKeys = Object.keys(li);
      lineItemRowKeys.forEach(element => {
        var isLabelTypePresent = this.labelTypes.map(x => x.name).includes(element)
        if (isLabelTypePresent) {
          if (li[element]) {
            toBeAddedLabels.push(this.labels.find(x => x.name == li[element]))
          }
        }
      });
      return toBeAddedLabels || [];
    },
    AssignLineItemsLabelType: function (rowData, li) {
      var lineItemRowKeys = Object.keys(li);
      lineItemRowKeys.forEach(element => {
        var isLabelTypePresent = this.labelTypes.map(x => x.name).includes(element)
        if (isLabelTypePresent) {
          if (li[element]) {
            rowData[element] = li[element];
          }
        }
      });
    },
    setLineItemDataParameters: function (li, lineItemRow, prop) {
      var self = this;
      var isComputed;
      if (li.buyMetric && li.kpiMetric && li.buyMetric == li.kpiMetric) {
        isComputed = li.buyMetric && li.buyMetric != "Fixed" && li.buyMetric != "Days"
          ? this.benchMarkList.find(x => x.name == li.buyMetric).isComputed
          : false
        self.selectedLineItemDataParameters.push({
          dataParameterId: self.lineItemDataParameters.find(
            x => x.name == li.buyMetric
          ).id,
          dataParameterValue:
           parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0)
             ? !isComputed
               ? Math.round(li[li.buyMetric])
               : li.buyMetric == "CTR"
                 ? parseFloat(li[li.buyMetric].toFixed(4))
                 : parseFloat(li[li.buyMetric].toFixed(2))
             : parseFloat(li.kpiValue) >= 0 && (li.kpiValue == 0 || li.kpiValue > 0)
               ? !isComputed
                 ? Math.round(li.kpiValue)
                 : li.kpiMetric == "CTR"
                   ? parseFloat(li.kpiValue.toFixed(4))
                   : parseFloat(li.kpiValue.toFixed(2))
               : li[li.buyMetric] != null ? li[li.buyMetric] : ""
        })
      }

      if (li.buyMetric != undefined && li.buyMetric != "" && li.buyMetric != null) {
        isComputed = li.buyMetric && li.buyMetric != "Fixed" && li.buyMetric != "Days"
          ? this.benchMarkList.find(x => x.name == li.buyMetric).isComputed
          : false
        if (!self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.lineItemDataParameters.find(
          x => x.name == li.buyMetric
        ).id)) {
          self.selectedLineItemDataParameters.push({
            dataParameterId: self.lineItemDataParameters.find(
              x => x.name == li.buyMetric
            ).id,
            dataParameterValue: parseFloat(li[li.buyMetric]) >= 0 && (li[li.buyMetric] == 0 || li[li.buyMetric] > 0)
              ? !isComputed
                ? Math.round(li[li.buyMetric])
                : li[li.buyMetric]
              : li[li.buyMetric] != null ? li[li.buyMetric] : ""
          })
        }
      }

      if (li.kpiMetric != undefined && li.kpiMetric != "" && li.kpiMetric != null) {
        isComputed = li.kpiMetric && li.kpiMetric != "Fixed" && li.kpiMetric != "Days"
          ? this.benchMarkList.find(x => x.name == li.kpiMetric).isComputed
          : false
        if (!self.selectedLineItemDataParameters.map(x => x.dataParameterId)
          .includes(self.benchMarkList.find(x => x.name == li.kpiMetric).id)) {
          self.selectedLineItemDataParameters.push({
            dataParameterId: self.lineItemDataParameters.find(x => x.name == li.kpiMetric).id,
            dataParameterValue: prop != li.kpiMetric
              ? parseFloat(li.kpiValue) >= 0 && (li.kpiValue == 0 || li.kpiValue > 0)
                ? !isComputed
                  ? Math.round(li.kpiValue)
                  : li.kpiMetric == "CTR"
                    ? parseFloat(li.kpiValue.toFixed(4)) : parseFloat(li.kpiValue.toFixed(2))
                : li.kpiValue
              : parseFloat(li[li.kpiMetric]) >= 0 && (li[li.kpiMetric] == 0 || li[li.kpiMetric] > 0)
                ? !isComputed
                  ? Math.round(li[li.kpiMetric])
                  : li.kpiMetric == "CTR"
                    ? parseFloat(li[li.kpiMetric].toFixed(4)) : parseFloat(li[li.kpiMetric].toFixed(2))
                : ""
          })
        }
      }

      var lineItemRowKeys = Object.keys(li);
      lineItemRowKeys.forEach(element => {
        var isPresent = self.benchMarkList.map(x => x.name).includes(element)
        if (isPresent && element != li.buyMetric) {
          if (!self.selectedLineItemDataParameters.map(x => x.dataParameterId)
            .includes(self.benchMarkList.find(x => x.name == element).id)) {
            isComputed = element && element != "Fixed" && element != "Days"
              ? this.benchMarkList.find(x => x.name == element).isComputed
              : false
            self.selectedLineItemDataParameters.push({
              dataParameterId: self.benchMarkList.find(x => x.name == element).id,
              dataParameterValue: parseFloat(li[element]) >= 0 && (li[element] == 0 || li[element] > 0) ? !isComputed ? Math.round(li[element]) : element == "CTR" ? parseFloat(li[element].toFixed(4)) : parseFloat(li[element].toFixed(2)) : ""
            })
          }
        }
      })

      if (li.cost != undefined && li.cost != null && li.cost != "" && li.unitCost != undefined && li.unitCost != null && li.unitCost != "") {
        if (li.buyMetric != undefined && li.buyMetric != null && li.buyMetric != "") {
          var alreadyPresent = self.selectedLineItemDataParameters.map(x => x.dataParameterId).includes(self.lineItemDataParameters.find(x => x.name == li.buyMetric).id)
          if (alreadyPresent) {
            isComputed = li.buyMetric != "Fixed" && li.buyMetric != "Days"
              ? this.benchMarkList.find(x => x.name == li.buyMetric).isComputed
              : false
            self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.lineItemDataParameters.find(x => x.name == li.buyMetric).id).dataParameterValue =
                   parseFloat(li.cost) >= 0 && (li.cost == 0 || li.cost > 0) && parseFloat(li.unitCost) > 0 && li.unitCost > 0
                     ? !isComputed
                       ? Math.round((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1))
                       : li.buyMetric != "CTR"
                         ? parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(2))
                         : parseFloat(((li.cost / li.unitCost) * (li.buyMetric == "Impressions" ? 1000 : 1)).toFixed(4))
                     : li.cost == 0 || li.unitCost == 0 ? 0 : self.selectedLineItemDataParameters.find(x => x.dataParameterId == self.lineItemDataParameters.find(x => x.name == li.buyMetric).id).dataParameterValue
          }
        }
      }
    },
    setCostSource: function (li, prop, source) {
      var rowData = this.setDataForGivenBuyType(li, prop, source)
      var costSourceId = this.dataParameterPricingTypeMapping
        .find(x => x.name == this.costSourceEnums.find(y => y.id == rowData.costSource).name).id
      return costSourceId
    },
    setBuyType: function (li, prop, source) {
      var rowData = this.setDataForGivenBuyType(li, prop, source)
      return rowData.buyType
    },
    setDateRange: function () {
      var tableData = this.getTableSourceData();
      var startDates = tableData.map(x => new Date(moment(x.startdate).format("YYYY/MM/DD")));
      var endDates = tableData.map(x => new Date(moment(x.enddate).format("YYYY/MM/DD")));
      var minDate = moment(new Date(Math.min.apply(null, startDates))).format("YYYY/MM/DD")
      var maxDate = moment(new Date(Math.max.apply(null, endDates))).format("YYYY/MM/DD")
      if (minDate != 'Invalid date' && maxDate != 'Invalid date') {
        if (!this.skipSettingMediaPlanDateFilter) {
          if (moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") > moment(minDate).format("YYYY/MM/DD") && moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") >= moment(maxDate).format("YYYY/MM/DD")) {
            this.setMediaPlanObject(minDate, this.$root.$children[0].mediaPlanObj.dateFilters[1]);
            this.skipSettingMediaPlanDateFilter = true;
          }
          if (moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") <= moment(minDate).format("YYYY/MM/DD") && moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") < moment(maxDate).format("YYYY/MM/DD")) {
            this.setMediaPlanObject(this.$root.$children[0].mediaPlanObj.dateFilters[0], maxDate);
            this.skipSettingMediaPlanDateFilter = true;
          }
          if (moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("YYYY/MM/DD") > moment(minDate).format("YYYY/MM/DD") && moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("YYYY/MM/DD") < moment(maxDate).format("YYYY/MM/DD")) {
            this.setMediaPlanObject(minDate, maxDate);
            this.skipSettingMediaPlanDateFilter = true;
          }
        }
      }
    },
    addRows: function () {
      let totalRows = this.hot.countRows();
      this.hot.alter("insert_row", totalRows, 1);
      let insertedRowData = this.getRowSourceData(totalRows);
      insertedRowData.orderId = this.getOrderId(insertedRowData);

      let tableData = this.getTableSourceData();
      var adgroupEntityCount = tableData.filter(li => li.entityTypeId == 2);
      var campaignEntityCount = tableData.filter(li => li.entityTypeId == 1);

      if (adgroupEntityCount > campaignEntityCount) {
        insertedRowData.entityTypeId = 2;
        this.hot.setDataAtRowProp(
          totalRows,
          "entity",
          "AdGroup",
          "Custom.Computation"
        );
      } else {
        insertedRowData.entityTypeId = 1;
        this.hot.setDataAtRowProp(
          totalRows,
          "entity",
          "Campaign",
          "Custom.Computation"
        );
      }
      insertedRowData.costPacingTypeId = 1;
      insertedRowData.linkedEntities = [];
      insertedRowData.toBeAddedLabels = [];
      insertedRowData.referenceKeys = [];
      insertedRowData.lineItemFractions = "";
      insertedRowData.isFractionEnabled = 0;
      insertedRowData.mediaPlanVersionId = 0;
      insertedRowData.startdate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[0]).format("MMM DD,YYYY");
      insertedRowData.enddate = moment(this.$root.$children[0].mediaPlanObj.dateFilters[1]).format("MMM DD,YYYY");
    },
    mergeCells () {
      var self = this;
      const selected = self.selectedRange || [];
      const rowToMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1], rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      const bundleCostMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1] + 3, rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      const bundleDiscountMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1] + 4, rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      self.mergedRow.push(rowToMerge, bundleCostMerge, bundleDiscountMerge);
      this.setBundleId();
      self.$root.$children[0].isValidSelectionforMerge = false;
    },
    UnmergeCells () {
      var self = this;
      const selected = self.selectedRange || [];

      const rowToMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1], rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      const bundleCostMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1] + 3, rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };
      const bundleDiscountMerge = { row: selected[0][0] > selected[0][2] ? selected[0][2] : selected[0][0], col: selected[0][1] + 4, rowspan: Math.abs((selected[0][2] - selected[0][0])) + 1, colspan: 1 };

      var indexToRemove = [];

      for (let i = 0; i < self.mergedRow.length; i++) {
        var element = self.mergedRow[i];
        if (self.isValidIndexToUnmerge(element, rowToMerge)) {
          indexToRemove.push(i);
          continue;
        }
        if (self.isValidIndexToUnmerge(element, bundleCostMerge)) {
          indexToRemove.push(i);
          continue;
        }
        if (self.isValidIndexToUnmerge(element, bundleDiscountMerge)) {
          indexToRemove.push(i);
          continue;
        }
      }

      var rowRange = self.mergedRow[indexToRemove[0]];
      self.handleRowsUnmerege(rowRange.row, rowRange.rowspan);
      indexToRemove.sort(function (a, b) { return b - a });
      indexToRemove.forEach(element => {
        self.mergedRow.splice(element, 1);
      });
      self.setValidUnmergefalse();
    },
    isValidIndexToUnmerge (element, row) {
      if (element.row == row.row &&
           element.col == row.col &&
           element.rowspan == row.rowspan &&
           element.colspan == row.colspan
      ) {
        return true;
      }
    },
    handleRowsUnmerege (row, rowCount) {
      const rowData = this.getRowSourceData(row);
      while (rowCount != 0) {
        const data = this.getRowSourceData(row);
        data.publisher = rowData.publisher;
        data.bundleCost = null;
        data.bundleDiscount = null;
        data.bundleId = null;
        rowCount--;
        row++;
      }
    },
    setBundleId () {
      var self = this;
      var minRow = self.selectedRange[0][0] < self.selectedRange[0][2] ? self.selectedRange[0][0] : self.selectedRange[0][2];
      var maxRow = self.selectedRange[0][0] < self.selectedRange[0][2] ? self.selectedRange[0][2] : self.selectedRange[0][0];
      var mergeStartRowData = this.getRowSourceData(minRow);
      for (var i = minRow; i <= maxRow; i++) {
        var li = this.getRowSourceData(i);
        li.bundleId = mergeStartRowData.orderId;
        li.mergedPublisher = mergeStartRowData.publisher;
      }
    },
    getOrderId: function (rowData) {
      let allData = this.getTableSourceData();
      allData = allData.concat(this.deletedLIForEdit);
      if (rowData.orderId == undefined || rowData.orderId == null) {
        let orderIds = allData
          .filter(x => x.orderId != undefined || x.orderId != null)
          .map(x => x.orderId);
        if (orderIds.length == 0) {
          this.importOrderId = 1;
          return 1;
        } else {
          this.importOrderId =
            Math.max.apply(
              Math,
              orderIds.map(x => x)
            ) + 1;
          return (
            Math.max.apply(
              Math,
              orderIds.map(x => x)
            ) + 1
          );
        }
      } else {
        this.importOrderId = rowData.orderId;
        return rowData.orderId;
      }
    },
    isValidBuyType: function (value) {
      var isValid = false;
      this.dataParameterPricingTypeMapping.forEach(element => {
        if (isValid) {
          isValid = true;
          return true;
        }
        element.lineItemPricing.forEach(pricing => {
          if (pricing.name == value) {
            isValid = true;
            return true;
          }
        });
      });
      return isValid;
    },
    gteZero: function (value) {
      let parsedValue = parseFloat(value + "");
      return !isNaN(parsedValue) && parsedValue >= 0;
    },
    checkIfExistsInKpiMetric: function (value) {
      var kpiMetricDropDownValues = this.lineItemDataParameters.map(
        x => x.name
      );
      if (value == "Fixed" || value == "Days") {
        return true;
      } else if (kpiMetricDropDownValues.includes(value)) {
        return true;
      } else {
        return false;
      }
    },
    checkForEmpty: function (value) {
      if (value != "" && value != null) {
        return true;
      } else {
        return false;
      }
    },
    addColumnIfCheckboxChecked: function (selectColumnsObject) {
      this.lineItemDataParametersOrder = [];
      selectColumnsObject.selectedBenchmarks.forEach(element => {
        this.lineItemDataParametersOrder.push({
          title: element,
          id: this.lineItemDataParameters.find(x => x.name == element).id
        })
      });
      this.benchmarkToRemove = selectColumnsObject.benchmarkToRemove;
      selectColumnsObject.benchmarkToRemove.forEach(element => {
        this.benchMarkColumns = this.benchMarkColumns.filter(
          x => x.title != element
        );
      });

      this.attributeToRemove = selectColumnsObject.attributeToRemove;
      selectColumnsObject.attributeToRemove.forEach(element => {
        this.attributeColumns = this.attributeColumns.filter(
          x => x.title != element
        );
      });

      this.benchMarkColumns = [];
      this.selectedBenchmarks = selectColumnsObject.selectedBenchmarks;
      selectColumnsObject.selectedBenchmarks.forEach(element => {
        if (
          !this.fixedDependentColumns.map(x => x.title).includes(element) &&
          !this.fixedCostColumns.map(x => x.title).includes(element) &&
          !this.fixedColumns.map(x => x.title).includes(element) &&
          !this.attributeColumns.map(x => x.title).includes(element) &&
          !this.benchMarkColumns.map(x => x.title).includes(element)
        ) {
          if (element != undefined) {
            this.benchMarkColumns.push({
              title: element,
              data: element,
              type: "numeric",
              minwidth: "100px",
              width: "100px",
              allowEmpty: true,
              numericFormat: {
                pattern: {
                  thousandSeparated: true
                }
              }
            });
          }
        }
      });

      var lineItems = this.getTableSourceData();
      lineItems.forEach(li => {
        this.setPricingRateData(li, "");
      });

      this.selectedAttributes = selectColumnsObject.selectedAttributes;
      this.pushIntoAttributeColumns(selectColumnsObject.selectedAttributes);
      this.benchmarkToRemove = [];
      this.attributeToRemove = [];

      var hotTableData = this.getTableSourceData();
      hotTableData.forEach(li => {
        selectColumnsObject.attributeToRemove.forEach(e => delete li[e]);
      })

      this.hot.updateSettings({
        columns: [
          ...this.fixedDependentColumns,
          ...this.bundleColumns,
          ...this.fixedCostColumns,
          ...this.attributeColumns,
          ...this.fixedColumns,
          ...this.benchMarkColumns
        ]
      });

      this.showSelectColumnsPopup = false;
    },
    rowMoved: function (row, target) {
      var self = this;
      let rowsCount = self.hot.countRows();
      let orderId = 0;
      let rowsSortedOrder = [];
      for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
        let rowData = self.getRowSourceData(
          self.hot.toPhysicalRow(eachRow)
        );
        rowData.orderId = ++orderId;
        rowsSortedOrder.push(rowData);
      }
      self.lineItemData = rowsSortedOrder;
      self.hot.updateSettings({
        data: []
      });
      self.hot.updateSettings({
        data: self.lineItemData,
        rowHeaders: self.setRowHeader
      });
    },
    selectColumnPopUpEnable: function () {
      this.showSelectColumnsPopup = true;
      this.selectedBenchmarks = this.benchMarkColumns.map(
        item => item.title
      );

      var disableSelectedBenchmark = [];
      let rowsCount = this.hot.countRows();
      for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
        let rowData = this.getRowSourceData(eachRow);
        if (!disableSelectedBenchmark.includes(rowData.buyMetric)) {
          this.benchmarksToBeDisabled.push(rowData.buyMetric);
        }
        if (!disableSelectedBenchmark.includes(rowData.kpiMetric)) {
          this.benchmarksToBeDisabled.push(rowData.kpiMetric);
        }
      }
    },
    setImportedLineItemLabels: function (labels) {
      var labelObjects = [];
      labels.forEach(function (label) {
        var obj = {
          id: label.Id,
          name: label.Name,
          colorCode: label.ColorCode,
          labelTypeId: label.LabelTypeId
        };
        if (label.Description != undefined && label.Description != null) {
          obj.description = label.Description;
        }
        labelObjects.push(obj);
      });
      return labelObjects;
    },
    setImportedEdsKeys: function (edsReferenceKeys) {
      var refrenceKeysObjects = [];
      if (edsReferenceKeys != undefined && edsReferenceKeys.length > 0) {
        edsReferenceKeys.forEach(function (edsReferenceKey) {
          if (
            edsReferenceKey.ReferenceKeys != null &&
            edsReferenceKey.ReferenceKeys != undefined &&
            edsReferenceKey.ReferenceKeys.length > 0
          ) {
            edsReferenceKey.ReferenceKeys.forEach(function (key) {
              var obj = {
                ReferenceKey: key,
                EdsId: edsReferenceKey.Id
              };
              refrenceKeysObjects.push(obj);
            });
          }
        });
      }
      return refrenceKeysObjects;
    },
    setToBeAddedLabels (lineItemLabels) {
      var toBeAddedLabels = [];
      lineItemLabels.forEach(function (label) {
        if (
          label.labelTypeId == undefined ||
          label.labelTypeId == null
        ) {
          toBeAddedLabels.push(label);
        }
      });
      return toBeAddedLabels;
    },
    setLabelData: function (lineItemRow, lineItem) {
      var self = this;
      var linkedLabels = lineItem.labels;
      linkedLabels.forEach(function (label) {
        var labelId = label.id;
        var linkedLabel = self.labels.find(i => i.id == labelId);
        if (linkedLabel != undefined && linkedLabel.labelTypeId != undefined) {
          var labelTypeId = linkedLabel.labelTypeId;
          var labelType = self.labelTypes.find(i => i.id == labelTypeId);
          if (
            labelType != undefined &&
            lineItem[labelType.name] == undefined
          ) {
            var labelName = label.name
            if (label.description != undefined && label.description != "") {
              labelName = label.name + "(" + label.description + ")";
            }
            lineItem[labelType.name] = labelName;
          }
          if (
            labelType != undefined &&
            lineItem[labelType.name] != undefined
          ) {
            lineItemRow[labelType.name] = lineItem[labelType.name];
          }
        }
      });
    },
    setReferenceKeyData: function (lineItemRow, linkedReferenceKeys) {
      var self = this;
      linkedReferenceKeys.forEach(function (eds) {
        var edsId = eds.edsId;
        var linkedRefKey = linkedReferenceKeys.find(i => i.edsId == edsId);
        if (linkedRefKey != undefined && linkedRefKey.edsId != undefined) {
          var edsData = self.edsColumns.find(i => i.id == linkedRefKey.edsId);
          if (
            edsData != undefined &&
            lineItemRow[edsData.name] == undefined
          ) {
            lineItemRow[edsData.name] = eds.referenceKey;
          }
        }
      });
    },
    setRowHeader: function (row) {
      return "";
    },
    countOccurrences: function (array, arrayLength, elementToBeCounted) {
      var result = 0;
      for (var element = 0; element < arrayLength; element++) {
        if (elementToBeCounted == array[element]) result++;
      }
      return result;
    },
    linkLineItemsPopupEnable: function () {
      this.apiService
        .getAllLineItems()
        .then(response => {
          this.allMediaPlans = response.data.data;
        })
        .catch(x => {});
      this.lineItemLinkPopup = true;
    },
    linkLineItems: function (linkLineItemObject) {
      var self = this;
      if (linkLineItemObject.lineItemIds.length == 0) {
        self.lineItemLinkPopup = false;
      } else {
        const getLineItemInfoBasedOnLiIdsPromise = self.apiService.getLineItemInformationById(
          linkLineItemObject.lineItemIds,
          linkLineItemObject.retainLinking
        );
        getLineItemInfoBasedOnLiIdsPromise
          .then(res => {
            let importedLineItems = [];
            let rowsCount = self.hot.countRows();
            var deletedRows = [];
            var isRowsDeleted = false;
            for (let eachRow = 0; eachRow < rowsCount; eachRow++) {
              let rowData = self.getRowSourceData(eachRow);
              if (
                rowData.channelType == undefined ||
                rowData.channelType == "" ||
                rowData.channelType == null
              ) {
                isRowsDeleted = true;
                deletedRows.push(rowData);
              }
            }

            if (isRowsDeleted == true) {
              deletedRows.forEach(element => {
                var handsonData = this.getTableSourceData();
                var rowNumber = handsonData
                  .map(x => x.orderId)
                  .indexOf(element.orderId);
                this.hot.alter("remove_row", rowNumber);
              });
            }
            deletedRows = [];
            isRowsDeleted = false;
            this.importOrderId = self.getTableSourceData().length > 0 ? Math.max.apply(Math, self.getTableSourceData().map(x => x.orderId)) : 0;
            res.data.lineItemViewModel.forEach(li => {
              this.campaignOrAdGroupLinked = [];
              let lineItemRow = {
                id: 0,
                type: li.TypeId,
                publisher: li.Publisher,
                supplierId: li.SupplierId,
                theme: li.Theme,
                costSource: li.CostSource,
                budget: li.Budget,
                pricingTypeReferenceValueId: li.PricingTypeReferenceValueId,
                pricingTypeId: li.PricingTypeId,
                kpiMetricId: li.KpiMetricId,
                kpiValue: "",
                perUnitPrice: li.PerUnitPrice,
                mediaPlanVersionId:
                  self.$root.$children[0].mediaPlanId == 0
                    ? 0
                    : self.$root.$children[0].mediaPlanVersionId,
                orderId:
                  self.importOrderId == 0 || self.importOrderId == 1
                    ? (self.importOrderId = self.getOrderId(li) + 1)
                    : (self.importOrderId = self.importOrderId + 1),
                entityTypeId:
                  linkLineItemObject.retainLinking == true
                    ? li.EntityTypeId
                    : 1,
                costPacingTypeId: 1,
                costPacingJson: "",
                creativeFormat: li.CreativeFormat,
                note1: li.Variable1,
                note2: li.Variable2,
                universe: li.universe,
                reachPercentage: li.reachPercentage,
                roNumber: li.RONumber,
                lineItemDataParameters: [],
                labels: self.setImportedLineItemLabels(li.Labels),
                referenceKeys: self.setImportedEdsKeys(li.Edses),
                lineItemFractions: li.lineItemFractions,
                isFractionEnabled: li.IsFractionEnabled ? li.isFractionEnabled : 0,
                isCostCalcuated: li.CalculateCost,
                isDiscountOverdelivery: li.DiscountOverDelivery,
                linkedEntities:
                  linkLineItemObject.retainLinking == true
                    ? li.CampaignIds.length > 0
                      ? (this.campaignOrAdGroupLinked = this.getLinkedEntities(
                        li.CampaignIds
                      ))
                      : []
                    : [],
                objective: li.ObjectiveId,
                nickname: li.Nickname,
                targeting: li.Targeting,
                bundleCost: li.BundleCost,
                bundleDiscount: li.BundleDiscount,
                bundleId: li.BundleId,
                field1: li.field1,
                field2: li.field2,
                field3: li.field3,
                field4: li.field4,
                field5: li.field5,
                field6: li.field6,
                field7: li.field7,
                field8: li.field8,
                field9: li.field9,
                field10: li.field10
              };

              li.LineItemDataParameters.forEach(lidp => {
                let lineItemDataParameters = {
                  dataParameterId: lidp.DataParameterId,
                  dataParameterValue: lidp.DataParameterValue,
                  orderId: lidp.OrderId
                };
                lineItemRow.lineItemDataParameters.push(lineItemDataParameters);
              });

              importedLineItems.push(lineItemRow);
            });
            importedLineItems = [...this.setimportedBundleId(importedLineItems)];
            self.MergeRowsoninit(importedLineItems, true);
            self.setDataToLineItems(importedLineItems);
            self.lineItemLinkPopup = false;
          })
          .catch(x => {
            console.log(x);
          });
      }
    },
    setimportedBundleId (importedLineItems) {
      var bundleIdMinOrdeId = new Map();
      importedLineItems.forEach(element => {
        if (element.bundleId != undefined && !isNaN(element.bundleId) && element.bundleId != null) {
          if (!bundleIdMinOrdeId.has(element.bundleId)) {
            bundleIdMinOrdeId.set(element.bundleId, element.orderId);
          } else {
            var existingOrderId = bundleIdMinOrdeId.get(element.bundleId);
            if (existingOrderId > element.orderId) {
              bundleIdMinOrdeId.set(element.bundleId, element.orderId);
            }
          }
        }
      });
      importedLineItems.forEach(element => {
        if (bundleIdMinOrdeId.has(element.bundleId)) {
          element.bundleId = bundleIdMinOrdeId.get(element.bundleId);
        }
      });
      return importedLineItems;
    },
    getLinkedEntities: function (campaignIds) {
      var campaignOrAdGroupLinked = [];
      campaignIds.forEach(element => {
        campaignOrAdGroupLinked.push({ entityId: element, fxConverter: 1 });
      });
      return campaignOrAdGroupLinked;
    },
    linkLineItemCancel: function () {
      this.lineItemLinkPopup = false;
    },
    setDataToLineItems: function (mediaPlanData) {
      var self = this;
      self.alreadyLinkedCampaignIds = [];
      self.alreadyLinkedAdgroupIds = [];
      let allDataParameters = [];
      var mediaPlanStartDate = self.$root.$children[0].mediaPlanObj.dateFilters[0];
      var mediaPlanEndDate = self.$root.$children[0].mediaPlanObj.dateFilters[1];
      var lineItemOrderIds = mediaPlanData.map(x => x.orderId);
      var displayNames = self.lineItemPublishersAPIData.filter(x => x.supplierId != undefined).map(x => x.name)
      lineItemOrderIds.sort(function (a, b) {
        return a - b;
      });
      lineItemOrderIds.forEach(liOrderId => {
        mediaPlanData.forEach(li => {
          if (liOrderId == li.orderId) {
            let lineItemRow = {
              channelType: li.type != "" && li.type != undefined && li.type != null
                ? self.lineItemTypes.find(x => x.id == li.type).name
                : li.type,
              publisher: li.publisher != "" && li.publisher != undefined && li.publisher != null
                ? (displayNames.includes(li.publisher) && li.supplierId != undefined && li.supplierId != null && li.supplierId != 0)
                  ? (self.lineItemPublishersAPIData.find(
                    x => x.name == li.publisher && x.supplierId == li.supplierId
                  ).displayName)
                  : li.publisher
                : li.publisher,
              onlyPublisher: li.publisher,
              theme: li.theme,
              entity: self.entityEnum.find(x => x.id == li.entityTypeId).name,
              costSource: li.costSource
                ? self.getCostSource(
                  li.costSource,
                  li.isCostCalcuated,
                  li.isDiscountOverdelivery
                )
                : li.costSource,
              cost: li.budget,
              buyType: li.buyType
                ? li.buyType
                : li.costSource
                  ? this.dataParameterPricingTypeMapping
                    .filter(x => x.id == li.costSource)[0]
                    .lineItemPricing.find(
                      x => x.id == li.pricingTypeReferenceValueId
                    ).name
                  : "",
              buyMetric: li.buyMetric
                ? li.buyMetric
                : li.costSource
                  ? self.dataParameterPricingTypeMapping
                    .filter(
                      x =>
                        x.name ==
                      self.dataParameterPricingTypeMapping.find(
                        x => x.id == li.costSource
                      ).name
                    )[0]
                    .lineItemPricing.filter(
                      x => x.id == li.pricingTypeReferenceValueId
                    )[0]
                    .DataParameter.find(x => x.id == li.pricingTypeId).name
                  : "",
              unitCost: li.perUnitPrice,
              kpiMetric: li.kpiMetricId != undefined && li.kpiMetricId != ""
                ? self.lineItemDataParameters.find(
                  x => x.id == li.kpiMetricId).name
                : "",
              kpiValue: li.kpiValue ? li.kpiValue : "",
              mediaPlanVersionId: li.mediaPlanVersionId,
              entityTypeId: li.entityTypeId,
              orderId: li.orderId,
              costPacingTypeId: li.costPacingTypeId,
              costPacingJson: li.costPacingJson,
              creativeformat: li.creativeFormat,
              note1: li.note1,
              note2: li.note2,
              universe: li.universe,
              reachPercentage: li.reachPercentage,
              startdate: li.startDate == undefined || li.startDate == "" || li.startDate == null ? moment(mediaPlanStartDate).format("MMM DD, YYYY") : moment(li.startDate).format("MMM DD, YYYY"),
              enddate: li.endDate == undefined || li.endDate == "" || li.endDate == null ? moment(mediaPlanEndDate).format("MMM DD, YYYY") : moment(li.endDate).format("MMM DD, YYYY"),
              ronumber: li.roNumber,
              isFractionEnabled: li.isFractionEnabled ? li.isFractionEnabled : 0,
              lineItemFractions: li.lineItemFractions,
              targeting: li.targeting,
              nickname: li.nickname,
              objective: (this.$root.$children[0].mediaPlanObjectivesSource.filter(e => e.id == li.objective)).map(x => x.name)[0],
              liId: this.generateLineItemId(li),
              lineItemId: li.id,
              linkedEntities: li.linkedEntities,
              isCostCalcuated: li.isCostCalcuated,
              isDiscountOverdelivery: li.isDiscountOverdelivery,
              toBeAddedLabels: self.setToBeAddedLabels(li.labels),
              referenceKeys: li.referenceKeys,
              roId: li.roId,
              supplierId: li.supplierId,
              bundleCost: li.bundleCost,
              bundleDiscount: li.bundleDiscount,
              bundleId: li.bundleId,
              mergedPublisher: li.mergedPublisher,
              field1: li.field1,
              field2: li.field2,
              field3: li.field3,
              field4: li.field4,
              field5: li.field5,
              field6: li.field6,
              field7: li.field7,
              field8: li.field8,
              field9: li.field9,
              field10: li.field10
            };
            if (lineItemRow.entityTypeId == 1) {
              self.alreadyLinkedCampaignIds.push(lineItemRow.linkedEntities);
            }
            if (lineItemRow.entityTypeId == 2) {
              self.alreadyLinkedAdgroupIds.push(lineItemRow.linkedEntites);
            }
            self.setLabelData(lineItemRow, li);
            self.setReferenceKeyData(lineItemRow, li.referenceKeys);
            if (
              lineItemRow.linkedEntities.length != 0 &&
              self.isReadOnlyEntityDropdown == false
            ) {
              self.isReadOnlyEntityDropdown = true;
              self.fixedDependentColumns[5] = {
                title: "Entity",
                data: "entity",
                readOnly: true,
                minwidth: "100px",
                width: "100px",
                allowEmpty: false
              };
              self.hot.updateSettings({
                columns: [
                  ...self.fixedDependentColumns,
                  ...self.bundleColumns,
                  ...self.fixedCostColumns,
                  ...self.attributeColumns,
                  ...self.fixedColumns,
                  ...self.benchMarkColumns
                ]
              });
            }

            let kpiMetric = li.kpiMetricId != undefined && li.kpiMetricId != ""
              ? self.lineItemDataParameters.find(x => x.id == li.kpiMetricId).name : ""

            li.lineItemDataParameters.forEach(lidp => {
              let dataParameterName = self.lineItemDataParameters.find(
                x => x.id == lidp.dataParameterId
              ).name;
              if (dataParameterName != undefined) {
                if (
                  !self.benchMarkColumns
                    .map(x => x.title)
                    .includes(dataParameterName)
                ) {
                  self.benchMarkColumns.push({
                    title: dataParameterName,
                    data: dataParameterName,
                    type: "numeric",
                    width: "100px",
                    minwidth: "100px",
                    numericFormat: {
                      pattern: {
                        thousandSeparated: true
                      }
                    }
                  });
                }
              }
              lineItemRow[dataParameterName] =
                lidp.dataParameterValue == null ? 0 : lidp.dataParameterValue;
            });

            allDataParameters = allDataParameters.concat(
              li.lineItemDataParameters
            );
            lineItemRow["kpiValue"] = lineItemRow.kpiMetric ? lineItemRow[kpiMetric] : lineItemRow["kpiValue"];

            if (self.lineItemData.map(x => x.orderId).includes(lineItemRow.orderId)) {
              var index = self.lineItemData.map(x => x.orderId).indexOf(lineItemRow.orderId)
              self.lineItemData[index] = lineItemRow;
            } else {
              self.lineItemData.push(lineItemRow);
            }
          }
        });
      });
      var tempBenchmarkColumns = self.benchMarkColumns;
      self.benchMarkColumns = [];

      var dataParameterOrderIds = allDataParameters.filter(x => x.orderId != undefined).map(x => x.orderId);
      if (dataParameterOrderIds.length > 0) {
        dataParameterOrderIds.sort(function (a, b) {
          return a - b;
        });

        let orderedDataParameters = [];
        dataParameterOrderIds.forEach(id => {
          allDataParameters.forEach(element => {
            if (id == element.orderId) {
              orderedDataParameters.push(
                self.lineItemDataParameters.find(
                  x => x.id == element.dataParameterId
                ).name
              );
            }
          });
        });

        orderedDataParameters.forEach(element => {
          if (
            !self.fixedDependentColumns.map(x => x.title).includes(element) &&
            !self.fixedCostColumns.map(x => x.title).includes(element) &&
          !self.fixedColumns.map(x => x.title).includes(element) &&
          !self.attributeColumns.map(x => x.title).includes(element) &&
          !self.benchMarkColumns.map(x => x.title).includes(element)
          ) {
            if (element != undefined) {
              self.benchMarkColumns.push({
                title: element,
                data: element,
                type: "numeric",
                minwidth: "100px",
                width: "100px",
                allowEmpty: true,
                numericFormat: {
                  pattern: {
                    thousandSeparated: true
                  }
                }
              });
            }
          }
        });
        tempBenchmarkColumns.forEach(element => {
          if (
            !self.fixedDependentColumns
              .map(x => x.title)
              .includes(element.title) &&
          !self.fixedCostColumns.map(x => x.title).includes(element.title) &&
          !self.fixedColumns.map(x => x.title).includes(element.title) &&
          !self.attributeColumns.map(x => x.title).includes(element.title) &&
          !self.benchMarkColumns.map(x => x.title).includes(element.title)
          ) {
            self.benchMarkColumns.push({
              title: element.title,
              data: element.title,
              type: "numeric",
              minwidth: "100px",
              width: "100px",
              allowEmpty: true,
              numericFormat: {
                pattern: {
                  thousandSeparated: true
                }
              }
            });
          }
        });
      } else {
        this.lineItemDataParametersOrder.forEach(element => {
          if (
            !self.fixedDependentColumns.map(x => x.title).includes(element.title) &&
            !self.fixedCostColumns.map(x => x.title).includes(element.title) &&
          !self.fixedColumns.map(x => x.title).includes(element.title) &&
          !self.attributeColumns.map(x => x.title).includes(element.title) &&
          !self.benchMarkColumns.map(x => x.title).includes(element.title)
          ) {
            if (element.title != undefined) {
              self.benchMarkColumns.push({
                title: element.title,
                data: element.title,
                type: "numeric",
                minwidth: "100px",
                width: "100px",
                allowEmpty: true,
                numericFormat: {
                  pattern: {
                    thousandSeparated: true
                  }
                }
              });
            }
          }
        });
      }
      self.hot.updateSettings({
        data: self.lineItemData,
        customBorders: [],
        columns: [
          ...self.fixedDependentColumns,
          ...self.bundleColumns,
          ...self.fixedCostColumns,
          ...self.attributeColumns,
          ...self.fixedColumns,
          ...self.benchMarkColumns
        ]
      });
      var benchmarksTitle = self.benchMarkColumns.map(x => x.title);

      benchmarksTitle.forEach(title => {
        this.benchmarksOrdering.push(
          self.lineItemDataParameters.find(x => x.name == title).id
        );
      });

      benchmarksTitle.forEach(title => {
        if (!this.lineItemDataParametersOrder.map(x => x.title).includes(title)) {
          this.lineItemDataParametersOrder.push({
            title: title,
            id: self.lineItemDataParameters.find(x => x.name == title).id
          })
        }
      });
    },
    getTableSourceData () {
      const tableData = this.hot.getSourceData();
      var bundleIdsPublisher = new Map();
      var bundleIdsCost = new Map();
      var bundleIdsDiscount = new Map();
      tableData.forEach(li => {
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.publisher)) {
          if (!bundleIdsPublisher.has(li.bundleId)) {
            bundleIdsPublisher.set(li.bundleId, li.publisher);
          }
        }
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.bundleCost)) {
          if (!bundleIdsCost.has(li.bundleId)) {
            bundleIdsCost.set(li.bundleId, li.bundleCost);
          }
        }
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.bundleDiscount)) {
          if (!bundleIdsDiscount.has(li.bundleId)) {
            bundleIdsDiscount.set(li.bundleId, li.bundleDiscount);
          }
        }
      });
      tableData.forEach(li => {
        if (li.bundleId && bundleIdsPublisher.has(li.bundleId)) {
          li.publisher = bundleIdsPublisher.get(li.bundleId);
        }
        if (li.bundleId && bundleIdsCost.has(li.bundleId)) {
          li.bundleCost = bundleIdsCost.get(li.bundleId);
        }
        if (li.bundleId && bundleIdsDiscount.has(li.bundleId)) {
          li.bundleDiscount = bundleIdsDiscount.get(li.bundleId);
        }
      })
      return tableData;
    },
    getRowSourceData (row) {
      const tableData = this.hot.getSourceData();
      var bundleIdsPublisher = new Map();
      var bundleIdsCost = new Map();
      var bundleIdsDiscount = new Map();
      tableData.forEach(li => {
        if (!(li.bundleId == undefined || li.bundleId == null || li.bundleId == 0) && (li.publisher)) {
          if (!bundleIdsPublisher.has(li.bundleId)) {
            bundleIdsPublisher.set(li.bundleId, li.publisher);
          }
          if (!bundleIdsCost.has(li.bundleId)) {
            bundleIdsCost.set(li.bundleId, li.bundleCost);
          }
          if (!bundleIdsDiscount.has(li.bundleId)) {
            bundleIdsDiscount.set(li.bundleId, li.bundleDiscount);
          }
        }
      });

      const rowData = this.hot.getSourceDataAtRow(row);

      if (rowData.bundleId && bundleIdsPublisher.has(rowData.bundleId)) {
        rowData.publisher = bundleIdsPublisher.get(rowData.bundleId);
      }

      if (rowData.bundleId && bundleIdsCost.has(rowData.bundleId)) {
        rowData.bundleCost = bundleIdsCost.get(rowData.bundleId);
      }

      if (rowData.bundleId && bundleIdsDiscount.has(rowData.bundleId)) {
        rowData.bundleDiscount = bundleIdsDiscount.get(rowData.bundleId);
      }
      return rowData;
    },
    getCostSource: function (
      lineItemCostsource,
      isCostCalcuated,
      isDiscountOverdelivery
    ) {
      var self = this;
      var costSourceToBeSet = [];
      if (lineItemCostsource == 1) {
        costSourceToBeSet = self.dataParameterPricingTypeMapping.find(
          x => x.id == lineItemCostsource
        ).name;
      } else {
        if (lineItemCostsource == 2) {
          if (isDiscountOverdelivery == true) {
            costSourceToBeSet = "Ad Server (Cost & Discount Delivery)";
          } else if (isCostCalcuated == true) {
            costSourceToBeSet = "Ad Server (Cost)";
          } else {
            costSourceToBeSet = "Ad Server";
          }
        } else {
          if (isDiscountOverdelivery == true) {
            costSourceToBeSet = "Others (Cost & Discount Delivery)";
          } else if (isCostCalcuated == true) {
            costSourceToBeSet = "Others (Cost)";
          } else {
            costSourceToBeSet = "Others";
          }
        }
      }
      return costSourceToBeSet;
    },
    hideEntityLinking: function () {
      this.$refs.linkEntity.showModal = false;
    },
    saveLinkedEntities: function (object) {
      var self = this;
      var rows = self.getTableSourceData();
      var linkedEntities = object.lineItemEntityRequest.map(x => x.EntityId);
      var linkedEntityTypeId = object.entityTypeId;
      var fxConvertor = object.lineItemEntityRequest.map(x => x.FxConverter);
      var orderId = object.lineItemId;
      // order id is treated as unique id as we dont have the lineItemId during creation
      var row = rows.find(x => x.orderId == orderId);
      if (linkedEntities.length != 0 && self.isReadOnlyEntityDropdown == false) {
        self.isReadOnlyEntityDropdown = true;
        self.fixedDependentColumns[5] = {
          title: "Entity",
          data: "entity",
          readOnly: true,
          minwidth: "100px",
          width: "100px",
          allowEmpty: false
        };
        self.hot.updateSettings({
          columns: [
            ...self.fixedDependentColumns,
            ...self.bundleColumns,
            ...self.fixedCostColumns,
            ...self.attributeColumns,
            ...self.fixedColumns,
            ...self.benchMarkColumns
          ]
        });
      }
      row.entityTypeId = linkedEntityTypeId;
      var entitiesLinked = [];
      linkedEntities.forEach(function (entityId) {
        var obj = {
          entityId: entityId,
          fxConvertor: parseFloat(fxConvertor)
        };
        entitiesLinked.push(obj);
      });
      row.linkedEntities = entitiesLinked;
      var rowNumber = rows.map(x => x.orderId).indexOf(orderId);

      let entityName = self.entityEnum.find(x => x.id == row.entityTypeId).name;
      self.hot.setDataAtRowProp(
        rowNumber,
        "entity",
        entityName,
        "Custom.Computation"
      );
      this.$refs.linkEntity.showModal = false;
      if (self.$root.$children[0].isEstimateEnabled) {
        object.lineItemId = row.lineItemId
        this.apiService.saveLinkedEntities(object)
      }
    },
    hideFractionsModal: function () {
      this.showFractionsModal = false;
    },
    hideCostPacingModal: function () {
      this.showCostPacingModal = false;
    },
    saveFractionDetails: function (lineItemFractionsObject) {
      var self = this;
      var rows = self.getTableSourceData();
      var row = rows.find(
        x => x.orderId == lineItemFractionsObject.lineItemOrderId
      );
      row.lineItemFractions = lineItemFractionsObject.lineItemFractions;
      row.isFractionEnabled = lineItemFractionsObject.isFractionEnabled;
      self.showFractionsModal = false;
    },
    saveCostPacingDetails: function (costPacingObject) {
      var self = this;
      var rows = self.getTableSourceData();
      var row = rows.find(
        x => x.orderId == costPacingObject.orderId
      );
      row.cost = costPacingObject.cost;
      row.costPacingJson = costPacingObject.costPacingJson;
      row.costPacingTypeId = costPacingObject.costPacingTypeId;
      self.showCostPacingModal = false;

      self.hot.updateSettings({
        data: self.lineItemData
      });
    },
    cancelAction: function () {
      this.lineItemData = [];
      this.selectedBenchmarks = [];
      this.selectedAttributes = [];
      this.attributeColumns = [];
      this.benchMarkColumns = [];
      this.hot.updateSettings({
        customBorders: [],
        mergeCells: [],
        columns: [
          ...this.fixedDependentColumns,
          ...this.bundleColumns,
          ...this.fixedCostColumns,
          ...this.attributeColumns,
          ...this.fixedColumns,
          ...this.benchMarkColumns
        ]
      });
      this.benchmarksToBeDisabled = [];
      this.selectedLineItemDataParameters = [];
      this.lineItemDataParametersOrder = [];
      this.mergedRow = [];
    },
    clear: function () {
      this.skipDataChanged = true;
      this.hot.clear();
    },
    cancelActionForSelectColumns: function () {
      this.showSelectColumnsPopup = false;
    },
    confirmAction: function () {
      var self = this;
      if (self.isDeleteEnabled) {
        var rowData = this.getRowSourceData(self.rowtoBeDeleted)
        var mergedRowLength = self.mergedRow.length;
        for (let index = 0; index < mergedRowLength; index++) {
          var element = self.mergedRow[index];

          if ((self.rowtoBeDeleted >= element.row && self.rowtoBeDeleted <= (element.row + element.rowspan - 1)) && element.rowspan == 2) {
            if (self.rowtoBeDeleted == element.row) {
              this.handleParentRowDelete(rowData, element.row + 1, element.rowspan);
            }
            self.mergedRow.splice(index, 1)
            index -= 1;
            mergedRowLength -= 1;
            continue;
          }
          if ((self.rowtoBeDeleted >= element.row && self.rowtoBeDeleted <= (element.row + element.rowspan - 1)) && element.rowspan > 2) {
            if (self.rowtoBeDeleted == element.row) {
              this.handleParentRowDelete(rowData, element.row + 1, element.rowspan);
            }
            self.mergedRow[index].rowspan = element.rowspan - 1;
            continue;
          }

          if (self.rowtoBeDeleted <= element.row) {
            self.mergedRow[index].row = element.row - 1;
          }
        }
        if (rowData.liId != 0 && rowData.liId != undefined && rowData.liId != "") {
          self.idEditRowDeleted = true;
          self.deletedLIForEdit.push(rowData);
          this.hot.alter("remove_row", self.rowtoBeDeleted);
        } else {
          this.hot.alter("remove_row", self.rowtoBeDeleted);
        }
        if (this.$root.$children[0].isSummaryTabOpen) {
          this.$emit('setSummaryTabData');
        }
        self.isDeleteEnabled = false;
        self.enableConfirmPopup = false;
        this.$emit("calculateLineItemTotalBudget");
      }
      if (self.onSaveOfMediaPlan) {
        this.$emit('onSaveSuccessOfMediaPlan');
      }
      if (self.onFailToSaveMediaPlan) {
        this.$emit('onSaveFailOfMediaPlan');
      }
      this.hot.updateSettings({
        mergeCells: [...self.mergedRow]
      });
    },
    handleParentRowDelete (rowData, row, rowSpan) {
      const data = this.getRowSourceData(row);
      if (rowSpan == 2) {
        data.publisher = rowData.publisher;
        data.bundleCost = null
        data.bundleDiscount = null
        data.bundleId = null
      } else {
        const tableData = this.getTableSourceData();
        tableData.forEach(li => {
          if ((li.bundleId == rowData.bundleId) && (li.orderId != rowData.orderId)) {
            li.publisher = rowData.publisher;
            li.bundleCost = rowData.bundleCost;
            li.bundleDiscount = rowData.bundleDiscount;
            li.bundleId = rowData.bundleId + 1;
          }
        });
      }
    },
    confirmBoxCancel: function () {
      var self = this;
      self.enableConfirmPopup = false
      if (self.isDeleteEnabled) {
        self.isDeleteEnabled = false;
      }
    },
    setMediaPlanObject: function (startDate, endDate) {
      this.$root.$children[0].mediaPlanObj.dateFilters = [
        moment(startDate).format("MMM DD,YYYY"),
        moment(endDate).format("MMM DD,YYYY")
      ]
    },
    enableHOTtable: function () {
      var self = this;
      self.benchMarkColumns = [];
      self.lineItemData = [];
      self.lineItemData.push({
        channelType: "",
        publisher: null,
        theme: "",
        costPacingTypeId: 1,
        linkedEntities: [],
        orderId: 1,
        mediaPlanVersionId: 0,
        startdate: moment(self.$root.$children[0].mediaPlanObj.dateFilters[0]).format(
          "MMM DD, YYYY"
        ),
        enddate: moment(self.$root.$children[0].mediaPlanObj.dateFilters[1]).format(
          "MMM DD, YYYY"
        )
      });
      self.fixedDependentColumns[5] = {
        title: "Entity",
        data: "entity",
        type: "dropdown",
        source: [],
        allowInvalid: false,
        minwidth: "100px",
        width: "100px",
        allowEmpty: false
      };
      self.fixedDependentColumns[5].source = self.entityEnum.map(
        x => x.name
      );

      self.hot.updateSettings({
        data: self.lineItemData,
        customBorders: [],
        mergeCells: [],
        columns: [...self.fixedDependentColumns, ...self.bundleColumns, ...self.fixedCostColumns, ...self.attributeColumns, ...self.fixedColumns]
      });
      self.showLineItems = true;
      self.$parent.$parent.$parent.showDigitalLineItems = true;
    },
    generateLineItemId: function (lineItem) {
      var lineItemId = lineItem.id;
      var generatedLineItemId = lineItem.id != 0
        ? parseInt(deltax.businessProfileId).toString(36) +
              "-" + lineItemId + "L"
        : "";
      return generatedLineItemId;
    },
    pushIntoAttributeColumns: function (selectedAttributes) {
      selectedAttributes.forEach(element => {
        if (
          !this.fixedDependentColumns.map(x => x.title).includes(element) &&
          !this.fixedCostColumns.map(x => x.title).includes(element) &&
          !this.fixedColumns.map(x => x.title).includes(element) &&
          !this.attributeColumns.map(x => x.title).includes(element) &&
          !this.benchMarkColumns.map(x => x.title).includes(element)
        ) {
          if (element == "Start Date" || element == "End Date") {
            this.attributeColumns.push({
              title: element,
              data: this.checkboxAttributeList.find(
                x => x.name == element
              ).id,
              minwidth: "100px",
              type: "date",
              dateFormat: "MMM DD, YYYY",
              width: "100px",
              allowEmpty: true,
              datePickerConfig: {
                minDate: moment(
                  this.$root.$children[0].mediaPlanObj.dateFilters[0]
                ).toDate(),
                maxDate: moment(
                  this.$root.$children[0].mediaPlanObj.dateFilters[1]
                ).toDate()
              }
            });
            this.skipSettingMediaPlanDateFilter = false;
          } else if (element == "Objective") {
            var options = (this.$root.$children[0].mediaPlanObjectivesSource.filter(e => e.id != 5)).map(x => x.name);
            this.attributeColumns.push({
              title: "Objective",
              data: "objective",
              type: "dropdown",
              source: options,
              allowInvalid: false,
              minwidth: "100px",
              width: "100px",
              wordWrap: false,
              allowEmpty: true
            })
          } else if (element == "ReachPercentage" || element == "Universe") {
            this.attributeColumns.push({
              title: element,
              data: this.checkboxAttributeList.find(
                x => x.name == element
              ).id,
              type: "numeric",
              minwidth: "100px",
              width: "100px",
              allowEmpty: true
            });
          } else if (this.labelTypes.some(obj => obj.name == element)) {
            var labelTypeId = this.labelTypes.find(i => i.name == element).id;
            var data = this.labels.filter(x => {
              return x.labelTypeId == labelTypeId;
            });
            var sourceData = [];
            if (data.length > 0) {
              sourceData = data.map(x => x.name);
            }
            this.attributeColumns.push({
              title: element,
              type: "dropdown",
              data: element,
              source: sourceData,
              minwidth: "100px",
              width: "100px",
              allowInvalid: false,
              wordWrap: false,
              allowEmpty: true
            });
          } else if (this.edsColumns.some(obj => obj.name == element)) {
            var edsId = this.edsColumns.find(i => i.name == element).id;
            var dataEDS = this.edsColumns.filter(x => {
              return x.edsId == edsId;
            });
            var sourceEDSData = [];
            if (dataEDS.length > 0) {
              sourceEDSData = dataEDS.map(x => x.name);
            }
            this.attributeColumns.push({
              title: element,
              data: sourceEDSData,
              minwidth: "100px",
              width: "100px",
              wordWrap: false
            });
          } else {
            this.attributeColumns.push({
              title: element,
              data: this.checkboxAttributeList.find(
                x => x.name == element
              ).id,
              minwidth: "100px",
              width: "100px",
              wordWrap: false,
              allowEmpty: true
            });
          }
        }
      });
    },
    addFieldColumns () {
      if (this.budgetCenterConfiguration != undefined && this.budgetCenterConfiguration != null && this.budgetCenterConfiguration != '' && this.budgetCenterConfiguration != {} && Object.keys(this.budgetCenterConfiguration).length > 0) {
        const digitalConfig = this.budgetCenterConfiguration.find(
          (config) => config.type.toLowerCase() == "digital"
        );
        if (digitalConfig == undefined || digitalConfig == null) {
          return;
        }
        const digitalAlias = digitalConfig.alias;
        if (digitalAlias) {
          const isUnidentical = Object.entries(digitalAlias).some(
            ([key, value]) => key !== value
          );

          if (isUnidentical) {
            Object.entries(digitalAlias).forEach(([key, value]) => {
              const exists = this.checkboxAttributeList.some(item => item.id === key);
              if (!exists) {
                this.checkboxAttributeList.push({ id: key, name: this.attributeColumnMapper[value] || value });
              }
            });
          }
        }
      }
    }
  }
};
</script>
<style scoped>
::v-deep #linkEntity, ::v-deep #addFractions, ::v-deep #costPacingOptions{
  font-size: 11px;
  padding-left: 5px;
}
.vue-multiselect-tree.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
::v-deep .read-only-class {
  background-color: #f0f0f0;
}
::v-deep .invalid-cell-data {
  border: 1px solid red;
}
.overflow-hidden{
  overflow-y: hidden !important;
  overflow-x: visible !important;
}
.vue-multiselect-tree.body {
  height: 85%;
}
::v-deep .ivu-modal-header{
     padding: 10px 16px !important;
     border-bottom: 2px solid #ccc !important;
}
::v-deep .ivu-modal-footer {
    padding: 9px 18px 12px 18px !important;
}
.confirm-box-text-style{
  font-size: 13px;
  color: black;
}
 .vertical-center-modal{
        display: flex;
        align-items: center;
        justify-content: center;
    }
::v-deep .ivu-modal{
      top: 185px;
        }
.hide-element {
  visibility: hidden;
}
.enable-button-padding{
  padding-top: 10px;
}
.non-editable{
  pointer-events: none;
}
</style>
