<template>
    <div class="input-item">
        <div class="input-item-title">
            <div class="input-item-name">{{input.title}}</div>
            <div class="input-alert" v-if="input.dueDate">
                <span class="alert-icon-exclamation"><i class="fas fa-exclamation-triangle"></i></span>
                <span class="alert-text">Due {{dueDate(input.dueDate)}}</span>
            </div>
        </div>
        <div class="input-item-body">
            <div class="spec-wrapper">
                <div class="spec-title">Specifications</div>
                <div class="input-title">{{input.description}}</div>
            </div>
            <div class="upload-methods">
                <upload
                :inputId="input.id">
                </upload>
            </div>
            <div class="attachment-list-panel">
                <attachment
                :inputId="input.id"
                :files="files">
                </attachment>
            </div>
        </div>
    </div>
</template>

<script>

import Upload from './Upload.vue';

import Attachment from './Attachment.vue';

export default {
  components: {
    "upload": Upload,
    "attachment": Attachment
  },
  props: {
    input: Object
  },
  data () {
    return {
      files: this.input.attachments
    }
  },
  watch: {
    '$store.state.uploadFile': function () {
      var self = this;
      if (self.$store.state.uploadFile.inputId == self.input.id) {
        self.files.push(self.$store.state.uploadFile.file);
      }
    }
  },
  methods: {
    dueDate: function (inputDate) {
      return moment(inputDate).calendar({
        sameDay: '[Today]',
        nextDay: '[Tomorrow]',
        lastDay: '[Yesterday]',
        sameElse: 'Do MMMM, YYYY LT'
      });
    }
  }
}
</script>
<style scoped>
.input-item {
    border: 1px solid #cecdcd;
    width: 100%;
}

.input-item-title {
    background-color: #f5f5f5;
    border-bottom: 1px solid #cecdcd;
    font-size: 14px;
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.input-item-body {
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
}

.input-alert {
    text-align: right;
}

.alert-icon-exclamation, .alert-text {
    margin-right: 8px;
}

.spec-wrapper {
    margin: 4px;
}

.spec-title {
    font-weight: bold;
    margin-bottom: 4px;
}

.input-title {
    margin-top: 4px;
}

.upload-methods {
    padding: 8px;
}

.attachment-list-panel {
    padding: 8px;
}
</style>
