<template>
    <div class="additional-submission">
        <div class="additional-submission-title">
            <div>
              <input-item
              placeholder="Enter Title"
              class="additional-submission-title-input"
              type="text"
              v-model="inputObject.title"></input-item>
              <span>
                <label v-show="!validTitle" class="required-message-style">* Required</label>
              </span>
            </div>
        </div>
        <div class="additional-submission-body">
            <div class="spec-wrapper">
                <div class="spec-title">
                    <input-item
                    placeholder="Enter Description"
                    class="spec-title-search"
                    type="text"
                    v-model="inputObject.description">
                    </input-item>
                </div>
                <div class="input-title">
                    <upload
                    ref="upload"
                    :inputId="inputObject.id">
                    </upload>
                </div>
            </div>
            <div class="input-specs-display">
                {{inputObject.description}}
            </div>
            <div class="attachment-list-panel">
                <attachment :inputId="0" :files="files">
                </attachment>
            </div>
            <div class="btn-wrapper">
                <Button
                class="btn btn-white btn-cancel"
                v-on:click="cancelSubmission">
                    Cancel
                </Button>
                <Button
                :disabled="disableBtn"
                type="success"
                class="btn create-btn btn-success button-style btn-submit"
                v-on:click="addInput">
                    Submit
                <i
                v-if="disableBtn"
                class="fa fa-lg fa-spinner fa-spin pull-right loader" />
                </Button>
            </div>
        </div>
    </div>
</template>
<script>
import {
  locale,
  Input
} from "iview";
import lang from "iview/dist/locale/en-US";
import Upload from './Upload.vue';

import Attachment from './Attachment.vue';

var apiService = require("../Services/InputListingApiServices.js");

locale(lang);

export default {
  components: {
    "upload": Upload,
    "attachment": Attachment,
    "input-item": Input
  },
  props: {
    inputGroupId: Number
  },
  data () {
    return {
      files: [],
      disableBtn: false,
      inputObject: {
        id: 0,
        title: '',
        description: '',
        inputGroupId: 0
      },
      validTitle: true
    }
  },
  watch: {
    '$store.state.uploadFile': function () {
      var self = this;
      if (self.$store.state.uploadFile.inputId == 0) {
        self.files.push(self.$store.state.uploadFile.file);
      }
    }
  },
  methods: {
    cancelSubmission: function () {
      this.resetData();
      this.$parent.showCreativeSpecsPanel = false;
    },
    resetData: function () {
      this.files = [];
      this.disableBtn = false;
      this.inputObject = {
        id: 0,
        title: '',
        description: '',
        inputGroupId: 0
      }
    },
    addInput: function () {
      var self = this;
      this.inputObject.inputGroupId = this.inputGroupId;

      if (!this.allowSave()) {
        return;
      }

      var additionalInputObject = {
        description: this.inputObject.description,
        inputGroupId: this.inputObject.inputGroupId,
        isDeleted: false,
        status: 2,
        title: this.inputObject.title,
        id: this.inputObject.id,
        attachments: []
      }

      this.disableBtn = true;
      const addInputPromise = apiService.AddInput(this.inputObject);
      Promise.all([addInputPromise])
        .then(value => {
          additionalInputObject.id = value[0].data.inputId;
          var promises = [];
          this.files.forEach(fle => {
            if (fle.creativeUrl) {
              promises.push(apiService.UploadFilesByUrl(value[0].data.inputId, fle.creativeUrl)
                .catch(err => err));
            } else {
              var formData = new FormData();
              formData.append("attachments", fle);
              promises.push(apiService.UploadFiles(value[0].data.inputId, formData));
            }
          });

          var failedPromises = [];
          Promise.all(promises)
            .then(val => {
              val.forEach((attachment, index) => {
                if (attachment.status != 204) {
                  additionalInputObject.attachments.push(attachment.data.attachmentUploaded[0]);
                } else {
                  failedPromises.push(this.files[index].creativeUrl ? this.files[index].creativeUrl : this.files[index].name);
                }
              });
              if (failedPromises.length > 0) {
                alert("Failed to upload the following:" + failedPromises);
              }
              self.$parent.inputGroupDisplayed.inputs.push(additionalInputObject);
              this.cancelSubmission();
            });
        });
    },
    allowSave () {
      if (this.inputObject.title.trim().length == 0) {
        this.validTitle = false;
        return false;
      }
      this.validTitle = true;
      return true;
    }
  }
}
</script>
<style scoped>
.additional-submission {
    border: 1px solid #cecdcd;
    width: 100%;
}

.additional-submission-title {
    background-color: #f5f5f5;
    border-bottom: 1px solid #cecdcd;
    font-size: 14px;
    padding: 8px;
}

.additional-submission-title-input {
  width: 25%;
  margin-right: 8px;
}

.additional-submission-body {
    padding: 12px;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
}

.input-alert {
    text-align: right;
}

.alert-icon-exclamation, .alert-text {
    margin-right: 8px;
}

.spec-wrapper {
    margin: 4px;
}

.spec-title {
    margin-bottom: 12px;
}

.input-title {
    margin-top: 4px;
}

.upload-methods {
    padding: 8px;
}

.attachment-list-panel {
    padding: 8px;
}

.input-specs-display {
    padding: 8px;
    display: flex;
    justify-content: center;
}

.spec-title-search {
    width: 100%;
}

.btn-submit {
    width: max-content;
    margin-bottom: 0px;
}

.btn-wrapper {
    margin: 4px;
}

.required-message-style{
  color:#CC3F44;
  font-size: 12px;
}

.btn-cancel {
  margin-bottom: 0px;
  margin-right: 4px;
}

.loader {
  margin-top: 2px !important;
}
</style>
