<template>
  <div>
    <div v-if="!isSummaryDataSet" style="text-align: center; margin-top: 35px;">
      <i class="fa fa-spinner fa-spin fa-2x" />
    </div>

    <div v-else id="campaign-launcher" class="elements">
      {{ allVerified }}
      <div v-if="accountData.length" class="budget-type-pivot">
        <div class="title-sticky">
          <label class="element-label">Summary</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ summaryVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="summaryVerified" />
            </label>
          </span>
        </div>
        <div class="budget-type-table">
          <table class="table perf">
            <thead>
              <tr>
                <th v-for="item in molocoEnums.summaryColumns[selectedCampaignLauncherConfig.campaign.objective]"
                  :key="item" class="table-header">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(record, i) in accountData" :key="i">
              <tr>
                <td class="data-center table-data-min-width-medium">
                  <span v-if="record.name">
                    {{ record.name }}
                  </span>
                  <br v-if="record.id" />
                  <span class="id-label">
                    ({{ record.id }})
                  </span>
                </td>
                <td class="data-center table-data-min-width-medium">{{ record.brand }}</td>
                <td class="data-center table-data-min-width-medium">{{ record.objective }}</td>
                <td v-if="isAppInstallsObjective" class="data-center table-data-min-width-large">
                  <div class="tracking-settings">
                    <span v-if="record.trackingSettings.mmpTrackingLink">Tracking Link: </span>
                    <span v-if="record.trackingSettings.mmpTrackingLink">{{ record.trackingSettings.mmpTrackingLink
                      }}</span>

                    <span v-if="record.trackingSettings.brandApp">Brand App: </span>
                    <span v-if="record.trackingSettings.brandApp">{{ record.trackingSettings.brandApp }}</span>
                  </div>
                </td>
                <td class="data-center table-data-min-width-large">
                  <div class="budget-settings">
                    <span v-if="record.budgetSettings.cpm">CPM: </span>
                    <span v-if="record.budgetSettings.cpm">
                      {{ bpCurrencySymbol }} {{ record.budgetSettings.cpm }}
                    </span>

                    <span v-if="isAppInstallsObjective && record.budgetSettings.cpiCeiling">CPI Ceiling: </span>
                    <span v-if="isAppInstallsObjective && record.budgetSettings.cpiCeiling">
                      {{ bpCurrencySymbol }} {{ record.budgetSettings.cpiCeiling }}
                    </span>

                    <span v-if="isAverageDailyBudget && record.budgetSettings.averageDailyBudget">Average Daily Budget:
                    </span>
                    <span v-if="isAverageDailyBudget && record.budgetSettings.averageDailyBudget">
                      {{ bpCurrencySymbol }} {{ record.budgetSettings.averageDailyBudget }}
                    </span>

                    <span v-if="isFixedDailyBudget && record.budgetSettings.fixedDailyBudget">Fixed Daily Budget:
                    </span>
                    <span v-if="isFixedDailyBudget && record.budgetSettings.fixedDailyBudget">
                      {{ bpCurrencySymbol }} {{ record.budgetSettings.fixedDailyBudget }}
                    </span>

                    <template v-for="(value, day) in getWeeklyDailyBudget(record.budgetSettings.weeklyDailyBudget)">
                      <span>{{ day }}</span>
                      <span>{{ value }}</span>
                    </template>

                    <span v-if="isBrandAwarenessObjective && record.budgetSettings.campaignMaxImpressions">Campaign
                      Maximum Impression:
                    </span>
                    <span v-if="isBrandAwarenessObjective && record.budgetSettings.campaignMaxImpressions">{{
      record.budgetSettings.campaignMaxImpressions }}</span>
                  </div>
                </td>
                <td class="data-center table-data-min-width-extra-small">{{ ads.length }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="campaigns.length" class="budget-type-pivot">
        <div class="title-sticky">
          <label class="element-label">Campaign Setup</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ campaignsVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="campaignsVerified" />
            </label>
          </span>
        </div>
        <Alert v-if="showMismatchWarning" type="warning" closable show-icon @on-close="removeAlertMessage">
          <p class="alert-subheading">Campaign data outdated, looks like the campaign was updated directly on Moloco.
          </p>
          <div v-for="(item, index) in alertedCampaigns" :key="index" slot="desc">
            <div v-if="item.showStatusAlert || item.showBidAlert || item.showLandingUrlAlert">
              <span class="alert-subheading">Campaign Name: </span>{{ item.name }}
            </div>
            <div v-if="item.showStatusAlert">
              <span class="alert-subheading">Status:&nbsp;</span>Moloco: {{ item.mcpStatusValue }} | DX:
              {{ item.currentStatusValue }}
            </div>
            <div v-if="item.showBidAlert">
              <span class="alert-subheading">Bid:&nbsp;</span>Moloco: {{ item.mcpBidValue }} | DX:
              {{ item.currentBidValue }}
            </div>
            <div v-if="item.showLandingUrlAlert">
              <span class="alert-subheading">Landing URL:&nbsp;</span>Moloco: {{ item.mcpLandingUrlValue }} | DX:
              {{ item.currentLandingUrlValue }}
            </div>
          </div>
        </Alert>
        <div class="budget-type-table">
          <table class="table perf">
            <thead>
              <tr>
                <th v-for="item in molocoEnums.campaignColumns[selectedCampaignLauncherConfig.campaign.objective]"
                  :key="item" class="table-header">
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(record, i) in campaigns" :key="i">
              <MolocoCampaignSetup :campaign="record" ref="molocoCampaignSetup" @show-warning="showMismatchAlert"
                @edit-campaign="openCampaignEdit" :submissionId="submissionId" :bpCurrencySymbol="bpCurrencySymbol" />
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="ads.length" class="targets-pivot">
        <div class="title-sticky">
          <label class="element-label">Ad and Creatives</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ pagesAndCreativeVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="pagesAndCreativeVerified" />
            </label>
          </span>
        </div>
        <div class="targets-table">
          <table class="table perf">
            <thead>
              <tr>
                <th v-for="item in molocoEnums.pagesAndCreativeColumn" :key="item">{{ item }}</th>
              </tr>
            </thead>
            <tbody v-for="(adRow, i1) in ads" :key="i1">
              <tr>
                <td class="table-data-min-width-small">
                  <div>
                    <label>
                      {{ adRow.name }}
                    </label>
                  </div>
                </td>
                <td class="table-data-min-width-small">
                  <div>
                    <label>
                      {{ adRow.status }}
                    </label>
                  </div>
                </td>
                <td class="table-data-min-width-small">
                  <div>
                    <label>
                      {{ adRow.type }}
                    </label>
                  </div>
                </td>
                <td class="table-data-min-width-medium">
                  <span v-if="adRow.creative.asset">
                    <img :src="adRow.creative.asset" height="50">
                  </span>
                  <br v-if="adRow.creative.name" />
                  <span v-if="adRow.creative.name">
                    {{ adRow.creative.name }}
                  </span>
                  <span v-if="adRow.creative.description">
                    {{ adRow.creative.description }}
                  </span>
                  <br v-if="adRow.creative.callToAction" />
                  <span>
                    {{ adRow.creative.callToAction }}
                  </span>
                </td>
                <td style="width: 15%;" class="table-data-min-width-large">
                  <div>
                    <label>
                      {{ adRow.landingPage }}
                    </label>
                  </div>
                </td>
                <td style="width: 25%;" class="table-data-min-width-small">
                  <div v-for="(item, index) in adRow.trackingSpec" :key="index">
                    <p class="tracking-event">
                      <span>Event {{ item.index }}:</span>
                      <span>{{ item.event }}</span>
                    </p>
                    <p class="tracking-url">
                      <span>Link:</span>
                      <span>{{ item.trackingUrl }}</span>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="targetDataComputed.length" class="targets-pivot">
        <div class="title-sticky">
          <label class="element-label">Targeting</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ targetsVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="targetsVerified" />
            </label>
          </span>
        </div>
        <div class="targets-table">
          <table class="table perf">
            <thead>
              <tr>
                <th v-for="item in molocoEnums.targetingColumns" :key="item">{{ item }}</th>
              </tr>
            </thead>
            <tbody v-for="(targetRow, i1) in targetDataComputed" :key="i1">
              <tr>
                <td class="table-data-min-width-medium">
                  <div>
                    <label>
                      {{ targetRow.campaignName }}
                    </label>
                  </div>
                </td>
                <td>
                  <Row>
                    <Row-Col span="23">
                      <div>
                        <label>
                          {{ targetRow.target }}
                        </label>
                      </div>
                      <span class="target-label">
                        Key Value Pair:
                      </span>
                      <br />
                      <span v-for="(keyValueObject, i1) in targetRow.keyValuePairs" :key="i1">
                        <span class="keyValueText">
                          {{ keyValueObject.name }}
                        </span>
                        <span>
                          {{ keyValueObject.relation }}
                        </span>
                        <span>
                          {{ keyValueObject.values }}
                        </span>
                        <span class="targetType" v-if="showTargetType(targetRow, i1)">
                          {{ keyValueObject.targetType }}
                        </span>
                        <br />
                      </span>
                      <span class="keyValueText"
                        v-if="targetRow.externalAudienceId && JSON.parse(targetRow.externalAudienceId).length">
                        External Audience IDs:
                      </span>
                      <span v-if="targetRow.externalAudienceId && JSON.parse(targetRow.externalAudienceId).length"
                        class="target-label">{{ JSON.parse(targetRow.externalAudienceId).join(", ") }}</span>
                    </Row-Col>
                    <Row-Col span="1" v-if="targetRow.searchEngineCampaignId" class="text-right">
                      <div>
                        <i class="fa fa-pencil" @click="loadCurrentCampaignTarget(targetRow.campaignId)"
                          v-if="!isFetchingCampaign && submissionId == 0" />
                        <i v-if="isFetchingCampaign && submissionId == 0" class="fa fa-spinner fa-spin" />
                      </div>
                    </Row-Col>
                  </Row>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <MolocoTargetModal :loadedFromAsset=true :hideModalButton=true :summarySelectedTarget="summarySelectedTarget"
      :summaryTargets="publishedTargets" @update-campaign-target="e => updateCampaignTargetData(e)" />
    <MolocoCampaignEditModal :campaigns="campaigns.filter(e => e.searchEngineCampaignId)"
      :activeCampaignId="campaignEditId" @close="campaignEditId = null" />
  </div>
</template>

<script>
import { locale, row, col, Alert } from "iview";
import lang from "iview/dist/locale/en-US";
import { mapState, mapMutations, mapActions } from "vuex";
import * as molocoEnums from "../../../Constants/MolocoEnums.js";
import { APIService } from '../../../ApiService.js';
import { EventBus } from "../../../EventBus.js";
import ApiService from '../ApiService.js';
import MolocoTargetModal from "../../../Components/Moloco/TargetCreationModal.vue";
import MolocoCampaignSetup from "./MolocoCampaignSetup.vue";
import MolocoCampaignEditModal from "./MolocoCampaignEditModal.vue";

locale(lang); // iview is default chinese

export default {
  components: {
    Row: row,
    "Row-Col": col,
    MolocoTargetModal,
    MolocoCampaignSetup,
    MolocoCampaignEditModal,
    Alert
  },
  mounted () {
    this.fetchSummaryData();
  },
  props: {
    submissionId: { default: 0 },
    viewType: { default: "Changes" },
    isMolocoPublishSuccess: {
      default: false
    }
  },
  data: function () {
    return {
      campaignsVerified: false,
      targetsVerified: false,
      summaryVerified: false,
      pagesAndCreativeVerified: false,
      molocoEnums,
      ads: [],
      adgroups: [],
      campaigns: [],
      accountData: [],
      targetData: [],
      isExpanded: false,
      budgetPackage: {},
      isFetchingCampaign: false,
      summarySelectedTarget: {},
      publishedTargets: [],
      showMismatchWarning: false,
      warningMessage: "",
      alertMessages: [],
      alertedCampaigns: [],
      campaignEditId: null
    };
  },
  computed: {
    ...mapState([
      "molocoCampaignLauncherPublishDataId",
      "selectedPublisher",
      "publisher",
      "savedTargetsMapperByBpseId",
      "businessProfilesSearchEngineId",
      "campaignLauncherConfigBpseIdMapper",
      "selectedCampaignLauncherConfigId",
      "savedTargets",
      "molocoAccounts",
      "bpCurrencySymbol",
      "isCampaignLauncherVerificationEnabled",
      "selectedCampaignLauncherConfig",
      "accountAssetsMapper",
      "imageIdUrlMap",
      "renderSubmissionSummary",
      "imagesData",
      "videosData",
      "molocoTargetingKeyValuePairs",
      "molocoBrandApps",
      "molocoTrackingLinks",
      "isSummaryDataSet"
    ]),
    allVerified: function () {
      this.$emit("elementsVerified", [
        { value: this.campaignsVerified, label: "Campaign Setup" },
        { value: this.targetsVerified, label: "Targeting" },
        { value: this.summaryVerified, label: "Summary" },
        { value: this.pagesAndCreativeVerified, label: "Page & Creative" }
      ]);
      return "";
    },
    isAppInstallsObjective () {
      return this.selectedCampaignLauncherConfig.campaign.objective == molocoEnums.objectives['App Installs'];
    },
    isBrandAwarenessObjective () {
      return this.selectedCampaignLauncherConfig.campaign.objective == molocoEnums.objectives["Brand Awareness"];
    },
    isAverageDailyBudget () {
      return this.isAppInstallsObjective && this.selectedCampaignLauncherConfig.budgetType == molocoEnums.budgetTypes["Average Daily Budget"];
    },
    isFixedDailyBudget () {
      return this.isAppInstallsObjective && this.selectedCampaignLauncherConfig.budgetType == molocoEnums.budgetTypes["Fixed Budget"] && this.selectedCampaignLauncherConfig.fixedBudgetType == molocoEnums.fixedBudgetTypes["Daily"];
    },
    isFixedDayOfWeekBudget () {
      return this.isAppInstallsObjective && this.selectedCampaignLauncherConfig.budgetType == molocoEnums.budgetTypes["Fixed Budget"] && this.selectedCampaignLauncherConfig.fixedBudgetType == molocoEnums.fixedBudgetTypes["DayOfWeek"];
    },
    targetDataComputed () {
      return this.targetData;
    }
  },
  created: async function () {
    var bp = await APIService.getBusinessProfileAsync();
    let currencyData = await APIService.getApiData(
      deltax.apiEndPoint +
      "businessProfiles/" +
      deltax.businessProfileId +
      "/Currencies/" + bp.currencyId
    );
    let currency = currencyData.data.data;
    var verificationFlag = bp.isCampaignLauncherVerificationEnabled;
    var bpCurrencySymbol = currency.symbol;
    this.set_bpCurrencySymbol(bpCurrencySymbol);
    this.set_isCampaignLauncherVerificationEnabled(verificationFlag);

    if (this.isCampaignLauncherVerificationEnabled == false) {
      this.campaignsVerified = true;
      this.targetsVerified = true;
      this.pagesAndCreativeVerified = true;
      this.summaryVerified = true;
    }

    EventBus.$on("update-campaign", (updatedCampaign) => {
      const campaign = this.campaigns.find(e => e.id == updatedCampaign.id);

      campaign.bid = updatedCampaign.bid;
      campaign.status = this.getKeyByValue(this.molocoEnums.Campaignstatus, updatedCampaign.status);
      campaign.landingUrl = updatedCampaign.landingUrl;
      campaign.name = updatedCampaign.name;
      campaign.description = updatedCampaign.description;
      campaign.dealType = updatedCampaign.dealType;
      campaign.priority = updatedCampaign.priority;
      campaign.adUnitTargeting = updatedCampaign.adUnits;
      campaign.startDate = updatedCampaign.schedule.start;
      campaign.endDate = updatedCampaign.schedule.end;
      campaign.jioId = updatedCampaign.jioId;
      campaign.roId = updatedCampaign.roId;
      campaign.maxImpressionCap = updatedCampaign.maxImpressionCap;
      campaign.dailyMaxImpressionCap = updatedCampaign.dailyMaxImpressionCap;
      campaign.impressionInterval = updatedCampaign.impressionInterval;
      campaign.editedSummary = updatedCampaign.editedSummary;
    });
  },
  beforeDestroy () {
    EventBus.$off("update-campaign");
  },
  watch: {
    'renderSubmissionSummary': function (value) {
      if (value) {
        this.fetchSummaryData();
      }
    },
    'viewType': function (to, from) {
      if (to !== from) {
        this.fetchSummaryData();
      }
    },
    isMolocoPublishSuccess: function (value) {
      if (value) {
        this.publishedTargets.forEach(item => {
          if (item.editedSummary) {
            item.editedSummary = "";
          }
        });
      }
    }
  },
  methods: {
    ...mapMutations([
      "set_isCampaignLauncherVerificationEnabled", "SET_imageIdUrlMap", "set_imagesData", "set_videosData", "set_bpCurrencySymbol", "set_molocoTargetingKeyValuePairs", "set_isSummaryDataSet"
    ]),
    ...mapActions(["fetchMolocoBrandAppsAndMMPTrackingLinks"]),
    getCommaSeperatedtargetName (targetsList) {
      return Array.prototype.map.call(targetsList, function (target) { return target.name; }).join(",");
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    getReadableDate (dateTimeStamp, isStartDate = false) {
      if (!dateTimeStamp) {
        if (isStartDate) {
          return "Now";
        }
        return "N/A";
      }
      var date = new Date(dateTimeStamp);
      return date.toDateString();
    },
    getReadableDateTime (value) {
      return new Date(value).toLocaleString();
    },
    getWeeklyDailyBudget (weeklyDailyBudget) {
      if (!this.isFixedDayOfWeekBudget || !weeklyDailyBudget) return [];

      return Object
        .keys(molocoEnums.daysOfWeek)
        .reduce((l, day) => ({ ...l, [day]: `${this.bpCurrencySymbol} ${weeklyDailyBudget[day]}` }), {});
    },
    async setSummaryData (campaignResponse, adgroupResponse, adResponse) {
      await this.setAccountData();
      this.setCampaignsData(campaignResponse);
      this.setAdsData(adResponse);
      this.setTargetData();
      this.set_isSummaryDataSet(true);
    },
    async setAccountData () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.summaryVerified = false;
      }

      const selectedAccount = this.molocoAccounts.find(account => account.id == this.selectedCampaignLauncherConfig.bpseId);
      const trackingSettings = {};

      if (this.isAppInstallsObjective) {
        await this.fetchMolocoBrandAppsAndMMPTrackingLinks({ bpseId: selectedAccount.id, adAccountId: selectedAccount.accountId, productId: selectedAccount.customerId });
        const brandApp = this.molocoBrandApps[selectedAccount.customerId].find(app => app.bundle_id == this.selectedCampaignLauncherConfig.campaign.app);
        const trackingLink = this.molocoTrackingLinks[selectedAccount.customerId].find(link => link.id == this.selectedCampaignLauncherConfig.campaign.mmpTrackingLink);

        trackingSettings.mmpTrackingLink = trackingLink.title;
        trackingSettings.brandApp = `${brandApp.localized_app_name.en} (${this.getKeyByValue(this.molocoEnums, brandApp.os)})`;
      }

      this.accountData =
        [{
          name: selectedAccount.accountNickName,
          id: selectedAccount.accountId,
          brand: selectedAccount.customerId,
          objective: this.getKeyByValue(this.molocoEnums.objectives, this.selectedCampaignLauncherConfig.campaign.objective),
          trackingSettings,
          budgetSettings: {
            "cpm": this.selectedCampaignLauncherConfig.targetCpm,
            "cpiCeiling": this.selectedCampaignLauncherConfig.cpiCeiling,
            "averageDailyBudget": this.selectedCampaignLauncherConfig.averageDailyBudget,
            "fixedDailyBudget": this.selectedCampaignLauncherConfig.fixedDailyBudget,
            "weeklyDailyBudget": this.selectedCampaignLauncherConfig.weeklyDailyBudget,
            "campaignMaxImpressions": this.selectedCampaignLauncherConfig.campaign.maximumImpressions
          }
        }];
      if (!this.accountData.length) {
        this.summaryVerified = true;
      }
    },
    getValidEvents (events) {
      if (!events) return [];

      return Object.keys(events)
        .filter(key => key.toLowerCase().startsWith("event") && !key.toLowerCase().endsWith("url"))
        .map(key => {
          if (!events[key] || !events[`${key}TrackingURL`]) {
            return null;
          }

          return {
            index: Number(key.substring(5)),
            event: events[key],
            trackingUrl: events[`${key}TrackingURL`]
          };
        })
        .filter(e => e !== null);
    },
    setCampaignsData (campaignResponse) {
      let campaigns = campaignResponse.data.data;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.campaignsVerified = false;
      }

      campaigns.forEach((campaign, index) => {
        let apiData = JSON.parse(campaign.apiData);

        let record =
        {
          id: campaign.id,
          name: campaign.name,
          description: campaign.description,
          objective: campaign.facebookObjective,
          lineItemId: campaign.lineItemId,
          targetId: campaign.targetId,
          lineItemFractionId: campaign.lineItemFractionId,
          searchEngineCampaignId: campaign.searchEngineCampaignID ? campaign.searchEngineCampaignID : null,
          status: this.getKeyByValue(this.molocoEnums.Campaignstatus, campaign.status),
          priority: campaign.campaignPriority,
          country: campaign.salesCountry,
          dealType: campaign.facebookBuyingType,
          startDate: campaign.startDate,
          endDate: campaign.endDate,
          adUnitTargeting: apiData.ad_units,
          budgetPackage: this.budgetPackage.title || '-',
          budgetType: this.getKeyByValue(this.molocoEnums.budgetTypes, this.selectedCampaignLauncherConfig.budgetType),
          lifetimeBudget: this.calculateLifetimeBudget(),
          budget: campaign.budget,
          weeklyDailyBudget: campaign.budgetPeriod,
          cpiCeiling: campaign.cpcLimit,
          bid: campaign.bid ? campaign.bid / 1000000 : this.accountData[0].budgetSettings.cpm,
          processingStatus: campaign.processingStatus,
          processingMessage: campaign.processingMessage,
          editedSummary: campaign.editedSummary ? campaign.editedSummary : "",
          landingUrl: apiData.landing_url,
          jioId: apiData.jio_support.jio_campaign_id,
          roId: apiData.custom_key_values.jio_ro_id,
          impressionInterval: JSON.stringify(apiData.jio_support.capper.imp_interval),
          ...(campaign.facebookObjective == this.molocoEnums.objectives["Brand Awareness"] ? {
            maxImpressionCap: apiData.jio_support.campaign_max_imp_cap.max_imp_cap_count,
            dailyMaxImpressionCap: apiData.jio_support.campaign_daily_imp_cap
          } : {})
        };
        this.campaigns.push(record);
      });
      if (!this.campaigns.length) {
        this.campaignsVerified = true;
      }
    },
    async setTargetData () {
      if (this.isCampaignLauncherVerificationEnabled) {
        this.targetsVerified = false;
      }
      // var targetsMapped = this.savedTargetsMapperByBpseId[this.selectedCampaignLauncherConfig.bpseId]
      var targets = [];
      this.targetData = [];
      this.campaigns.forEach((campaign, index) => {
        targets.push(campaign.targetId);
      });

      var molocoTargetingKeyValuePairsResponse = await APIService.GetMolocoTargetingKeyValuePairs();
      this.set_molocoTargetingKeyValuePairs(molocoTargetingKeyValuePairsResponse);
      this.campaigns.forEach((campaign, index) => {
        let campaignTarget = this.adgroups.find(adgroup => adgroup.campaignId == campaign.id);
        if (campaignTarget) {
          let targetKeyValuePairs = this.extractTargetValues(campaignTarget.targetString);
          let record =
          {
            campaignId: campaign.id,
            campaignName: campaign.name,
            target: `${campaignTarget.targetName} (DXT-${campaignTarget.targetId})`,
            keyValuePairs: targetKeyValuePairs,
            searchEngineCampaignId: campaign.searchEngineCampaignId ? campaign.searchEngineCampaignId : null,
            id: campaignTarget.targetId,
            targetString: campaignTarget.targetString,
            name: campaignTarget.targetName,
            lineItemId: campaign.lineItemId,
            externalAudienceId: campaignTarget.externalAudienceId,
            processingStatus: campaign.processingStatus,
            processingMessage: campaign.processingMessage,
            editedSummary: campaign.editedSummary ? campaign.editedSummary : ""
          };
          if (record.searchEngineCampaignId) {
            this.publishedTargets.push(record);
          }
          this.targetData.push(record);
        }
      });
      if (!this.targetData.length) {
        this.targetsVerified = true;
      }
    },
    setAdsData (adResponse) {
      var self = this;
      let ads = adResponse.data.data;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.pagesAndCreativeVerified = false;
      }

      ads.forEach((ad, index) => {
        // let adCampaign = self.campaigns.find(campaign => campaign.id == ad.campaignId)
        var assetId = ad.creative.videoId;
        var assetPrefix = 'DXV';
        if (!ad.creative.videoId) {
          assetId = ad.creative.imageId;
          assetPrefix = 'DXC';
        }
        let record =
        {
          name: ad.title,
          status: self.molocoEnums.AdStatusNew[ad.status],
          type: self.getKeyByValue(self.molocoEnums.adTypes, ad.adType),
          creative: {
            name: ad.creative.title,
            description: ad.creative.body,
            callToAction: ad.creative.callToAction,
            asset: self.imageIdUrlMap[`${assetPrefix}-${Number.parseInt(deltax.businessProfileId).toString(36)}-${assetId}`] ? self.imageIdUrlMap[`${assetPrefix}-${Number.parseInt(deltax.businessProfileId).toString(36)}-${assetId}`].src : ''
          },
          landingPage: ad.destinationURL,
          trackingSpec: ad.trackingSpec ? this.getValidEvents(JSON.parse(ad.trackingSpec)) : {}
        };
        self.ads.push(record);
      });
      if (!this.ads.length) {
        this.pagesAndCreativeVerified = true;
      }
    },
    async fetchSummaryData () {
      this.resetSummaryData();
      var statusToFetch = [this.molocoEnums.entityStatus.New, this.molocoEnums.entityStatus.Failed, this.molocoEnums.entityStatus.Edited].join(',');
      var campaignStatusToFetch = [this.molocoEnums.entityStatus.New, this.molocoEnums.entityStatus.SuccessWithoutCreativeGroup, this.molocoEnums.entityStatus.Failed, this.molocoEnums.entityStatus.Edited].join(',');
      if (this.submissionId || this.viewType != 'Changes') {
        statusToFetch = null;
        campaignStatusToFetch = null;
      }
      if (this.selectedCampaignLauncherConfig.budgetPackage) {
        var budgetPackageResponse = await APIService.getBudgetPackageById(this.selectedCampaignLauncherConfig.budgetPackage);
        if (budgetPackageResponse.status == 200) {
          this.budgetPackage = budgetPackageResponse.data.data;
        }
      }

      var campaignResponse = await ApiService.GetCampaignsSummaryData(deltax.businessProfileId, this.molocoCampaignLauncherPublishDataId, this.submissionId, campaignStatusToFetch);
      var adgroupResponse = await ApiService.GetAdgroupsSummaryData(deltax.businessProfileId, this.molocoCampaignLauncherPublishDataId, this.submissionId, statusToFetch);
      var adResponse = await ApiService.GetAdsSummaryData(deltax.businessProfileId, this.molocoCampaignLauncherPublishDataId, this.submissionId, statusToFetch);

      // set the adgroup response data in adgroups
      this.adgroups = adgroupResponse.data.data;
      // console.log(this.adgroups)

      let ads = adResponse.data.data;
      var imageIds = ads.filter(ad => ad.creative.imageId).map(ad => ad.creative.imageId);
      var videoIds = ads.filter(ad => ad.creative.videoId).map(ad => ad.creative.videoId);
      if (imageIds && imageIds.length > 0) {
        var fetchedImages = await APIService.getImagesByIdandNames(deltax.businessProfileId, this.businessProfilesSearchEngineId, imageIds, []);
      }
      if (videoIds && videoIds.length > 0) {
        var fetchedVideos = await APIService.getVideosByIdandNames(deltax.businessProfileId, this.businessProfilesSearchEngineId, videoIds, []);
      }
      this.formatData(fetchedImages ? fetchedImages.data.data : [], fetchedVideos ? fetchedVideos.data.data : []);
      await this.setSummaryData(campaignResponse, adgroupResponse, adResponse);
    },
    resetSummaryData () {
      this.ads = [];
      this.adgroups = [];
      this.campaigns = [];
      this.accountData = [];
      this.targetData = [];
      this.set_isSummaryDataSet(false);
    },
    calculateLifetimeBudget () {
      const config = this.selectedCampaignLauncherConfig;

      if (this.isBrandAwarenessObjective && config.targetCpm) {
        return `${this.bpCurrencySymbol} ${config.campaign.maximumImpressions * (config.targetCpm / 1000)}`;
      }

      const start = config.schedule.start ? new Date(config.schedule.start) : null;
      const end = config.schedule.end ? new Date(config.schedule.end) : null;

      if (!this.isAppInstallsObjective || !start) return "N/A";

      if (!end) return "Unlimited";

      const days = ((end - start) / 86400000) + 1;

      if (this.isAverageDailyBudget) return `${this.bpCurrencySymbol} ${days * config.averageDailyBudget}`;
      else if (this.isFixedDailyBudget) return `${this.bpCurrencySymbol} ${days * config.fixedDailyBudget}`;
      else if (this.isFixedDayOfWeekBudget) {
        var weeklyTotalBudget = Object
          .values(config.weeklyDailyBudget)
          .reduce((l, e) => l + e, 0);
        var budget = Math.floor(days / 7) * weeklyTotalBudget;
        for (let i = 0; i < days % 7; i++) {
          const dayNumber = new Date(end).addDays(-i).getDay() + 1;
          const day = this.getKeyByValue(this.molocoEnums.daysOfWeek, dayNumber);
          budget += config.weeklyDailyBudget[day];
        }
        return `${this.bpCurrencySymbol} ${budget}`;
      }

      return "N/A";
    },
    formatData (fetchedImages, fetchedVideos) {
      let data = [];
      this.SET_imageIdUrlMap({});
      let assetUrlMap = {};
      for (let imageData of fetchedImages) {
        let formattedImageData = this.formatImageData(imageData);
        data.push(formattedImageData);
        assetUrlMap[formattedImageData.id] = formattedImageData;
      }
      this.set_imagesData(data);
      let formattedVideos = this.formatVideos(fetchedVideos, assetUrlMap);
      this.set_videosData(formattedVideos);
      this.SET_imageIdUrlMap(assetUrlMap);
      this.$store.commit("set_builtImageIdUrlMap", true);
    },
    formatImageData (imageData) {
      let [width, height] = imageData.dimension.split("x").map(d => d.trim());
      return {
        id: `DXC-${Number.parseInt(deltax.businessProfileId).toString(36)}-${imageData.id}`,
        name:
          imageData.name != null
            ? imageData.name.substring(imageData.name.indexOf("_") + 1)
            : "Sample Image",
        src: imageData.url,
        hash: imageData.hash,
        width,
        height
      };
    },
    formatVideoData (videoData) {
      const [width, height] = videoData.format ? videoData.format.split('x').map(Number) : [0, 0];
      var data = {
        id: `DXV-${Number.parseInt(deltax.businessProfileId).toString(36)}-${videoData.videoId || videoData.id}`,
        name: videoData.title != null ? videoData.title : "Sample Video",
        src: videoData.picture,
        width: width,
        height: height
      };
      data.nameId = data.name;
      return data;
    },
    formatVideos (videosData, assetUrlMap) {
      let data = [];

      for (let videoData of videosData) {
        if (videoData.processingStatus == 1) {
          let formattedVideoData = this.formatVideoData(videoData);
          assetUrlMap[formattedVideoData.id] = formattedVideoData;
          assetUrlMap[formattedVideoData.name] = formattedVideoData;
          data.push(formattedVideoData);
          this.updateAssetUrlMap(formattedVideoData);
        } else if (videoData.processingStatus == 9) {
          data.push({
            name: videoData.title != null ? videoData.title : "Sample Video",
            error: true
          });
        }
      }
      return data;
    },
    updateAssetUrlMap (formattedVideoData) {
      this.$set(this.imageIdUrlMap, formattedVideoData.id, {
        src: formattedVideoData.picture || formattedVideoData.src
      });
      this.$set(this.imageIdUrlMap, formattedVideoData.nameId, {
        src: formattedVideoData.picture || formattedVideoData.src
      });
    },
    toggleEllipsis (index) {
      const container = this.$refs.toggleContainer;
      if (this.isExpanded && container && container.length) {
        container[index].style.maxHeight = "44px"; // Limit to 3 lines
      } else {
        container[index].style.maxHeight = "none"; // Show complete text
      }
      this.isExpanded = !this.isExpanded;
    },
    extractTargetValues (targetString) {
      let targetObject = JSON.parse(targetString);
      let result = [];
      var targetPairsObject = {};
      var targetType = '';
      if (targetObject.and && Object.keys(targetObject.and).length) {
        targetPairsObject = targetObject.and;
        targetType = "AND";
      } else if (targetObject.or && Object.keys(targetObject.or).length) {
        targetPairsObject = targetObject.or;
        targetType = "OR";
      }
      for (let pairKey in targetPairsObject.pairs) {
        let matchingKeyValuePair = this.molocoTargetingKeyValuePairs.find(
          (item) => item.key === pairKey
        );
        if (matchingKeyValuePair) {
          let values = [];
          var relation = Object.keys(targetPairsObject.pairs[pairKey])[0];
          if (relation == "is_in") {
            relation = "IS";
            values = targetPairsObject.pairs[pairKey].is_in.values;
          } else if (relation == "is_not_in") {
            relation = "IS NOT";
            values = targetPairsObject.pairs[pairKey].is_not_in.values;
          }
          let { name, value } = matchingKeyValuePair;
          let allValues = value.split(",");
          let filteredValues = allValues.filter((val) => values.includes(val.trim())).join(', ');
          if (filteredValues.length > 0) {
            result.push({ name, relation, values: filteredValues, targetType });
          }
        }
      }
      return result;
    },
    showTargetType (targetRow, index) {
      let keyValuePairCount = targetRow.keyValuePairs.length;
      return (keyValuePairCount > 1 && index == 0) || index != keyValuePairCount - 1;
    },
    async loadCurrentCampaignTarget (campaignId) {
      this.summarySelectedTarget = this.targetData.find(x => x.campaignId == campaignId);
      this.$store.state.showMolocoTargetModalInSummary = true;
    },
    updateCampaignTargetData (target) {
      var self = this;
      this.targetData.forEach((item, index) => {
        if (item.campaignId == target.campaignId) {
          let updatedItem = {
            ...item,
            keyValuePairs: self.extractTargetValues(target.targetString),
            targetString: target.targetString,
            externalAudienceId: target.externalAudienceId
          };
          // if (!this.validateSameTargetString(target.liveTargetString, target.targetString) && !self.$store.state.molocoEditedTargets.includes(target.campaignId)) {
          //   self.$store.state.molocoEditedTargets.push(target.campaignId);
          // }
          // if (!this.validateSameExternalAudiences(target.liveCustomTargets, target.externalAudienceId) && !self.$store.state.molocoEditedExternalAudiences.includes(target.campaignId)) {
          //   self.$store.state.molocoEditedExternalAudiences.push(target.campaignId);
          // }
          if (!self.$store.state.molocoEditedTargets.includes(target.campaignId)) {
            self.$store.state.molocoEditedTargets.push(target.campaignId);
          }
          if (!self.$store.state.molocoEditedExternalAudiences.includes(target.campaignId)) {
            self.$store.state.molocoEditedExternalAudiences.push(target.campaignId);
          }
          self.$set(self.targetData, index, updatedItem);
        }
      });
    },
    showMismatchAlert (messageInfo) {
      let alertedCampaign = this.alertedCampaigns.find(campaign => campaign.id === messageInfo.campaignId);

      if (!alertedCampaign) {
        let campaign = this.campaigns.find(campaign => campaign.id === messageInfo.campaignId);
        if (campaign) {
          alertedCampaign = {
            id: messageInfo.campaignId,
            name: campaign.name,
            showStatusAlert: false,
            currentStatusValue: null,
            mcpStatusValue: null,
            showBidAlert: false,
            currentBidValue: null,
            mcpBidValue: null,
            showLandingUrlAlert: false,
            currentLandingUrlValue: null,
            mcpLandingUrlValue: null
          };
          this.alertedCampaigns.push(alertedCampaign);
        }
      }
      if (messageInfo.type === 'status') {
        alertedCampaign.showStatusAlert = true;
        alertedCampaign.currentStatusValue = messageInfo.currentStatusValue;
        alertedCampaign.mcpStatusValue = messageInfo.mcpStatusValue;
      } else if (messageInfo.type === 'bid') {
        alertedCampaign.showBidAlert = true;
        alertedCampaign.currentBidValue = messageInfo.currentBidValue;
        alertedCampaign.mcpBidValue = messageInfo.mcpBidValue;
      } else if (messageInfo.type === 'landingUrl') {
        alertedCampaign.showLandingUrlAlert = true;
        alertedCampaign.currentLandingUrlValue = messageInfo.currentLandingUrlValue;
        alertedCampaign.mcpLandingUrlValue = messageInfo.mcpLandingUrlValue;
      }

      this.showMismatchWarning = true;
    },
    removeAlertMessage () {
      this.showMismatchWarning = false;
      this.alertMessages = [];
      this.alertedCampaigns = [];
    },
    validateSameTargetString (targetString1, targetString2) {
      if (!targetString1 || !targetString2) {
        return false;
      }
      let obj1 = JSON.parse(targetString1);
      let obj2 = JSON.parse(targetString2);
      let condition1 = Object.keys(obj1)[0];
      let condition2 = Object.keys(obj2)[0];

      if (condition1 !== condition2) return false;
      if (Object.keys(obj1[condition1].pairs).length !== Object.keys(obj2[condition2].pairs).length) return false;

      for (let obj1Key in obj1[condition1].pairs) {
        if (obj2[condition2].pairs[obj1Key]) {
          if (Object.keys(obj1[condition1].pairs[obj1Key])[0] === Object.keys(obj2[condition2].pairs[obj1Key])[0]) {
            let isInVal = Object.keys(obj1[condition1].pairs[obj1Key])[0];
            if (!this.areEqualValues(obj1[condition1].pairs[obj1Key][isInVal].values, obj2[condition2].pairs[obj1Key][isInVal].values)) {
              return false;
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
      return true;
    },
    validateSameExternalAudiences (externalAudience1, externalAudience2) {
      if (externalAudience1 && externalAudience2) {
        return this.areEqualValues(JSON.parse(externalAudience1), JSON.parse(externalAudience2));
      } else if (!externalAudience1 && !externalAudience2) {
        return true;
      } else {
        return false;
      }
    },
    areEqualValues (arr1, arr2) {
      {
        let N = arr1.length;
        let M = arr2.length;

        if (N != M) { return false; }

        let map =
          new Map();
        let count = 0;
        for (let i = 0; i < N; i++) {
          if (map.get(arr1[i]) == null) { map.set(arr1[i], 1); } else {
            count = map.get(arr1[i]);
            count++;
            map.set(arr1[i], count);
          }
        }

        for (let i = 0; i < N; i++) {
          if (!map.has(arr2[i])) { return false; }

          if (map.get(arr2[i]) == 0) { return false; }

          count = map.get(arr2[i]);
          --count;
          map.set(arr2[i], count);
        }

        return true;
      }
    },
    openCampaignEdit (id) {
      this.campaignEditId = id;
    }
  }
};
</script>

<style scoped>
.alert-subheading {
  font-weight: bold;
  color: #E6A700 !important;
}

::v-deep .ivu-message-warning,
.ivu-alert-warning {
  padding: 16px 16px 16px 69px !important;
}

.edit-bid-input {
  width: 50%;
}

.title-sticky {
  padding: 0px 5px;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}

.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}

.verified-action {
  float: right;
  padding: 10px;
}

.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}

input[type="checkbox"] {
  vertical-align: text-bottom;
}

.total-footer {
  font-weight: 600;
  background-color: #eee;
}

.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}

.target-label {
  padding-top: 15px;
}

.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}

.page-label {
  padding: 10px 5px;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}

.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}

.more-previews {
  font-size: 1.3em;
}

.data-right {
  text-align: right;
}

.data-center {
  text-align: center;
  vertical-align: middle;
}

.upper-case {
  text-transform: uppercase;
}

.table-header {
  border: 1px solid #ddd !important;
  padding: 10px !important;
}

.id-label {
  font-size: 12px;
}

.table-data-min-width-medium {
  min-width: 130px
}

span,
td {
  color: #566464;
}

.cell-data-value-split-45 {
  display: inline-block;
  width: 48%;
  text-align: right;
  vertical-align: top;
  color: #a7a7a7;
}
</style>
<style>
table.target-info-table td {
  border: 0 !important;
}

.cell-data-value-split-55 {
  display: inline-block;
  width: 50%;
  text-align: left;
  margin-left: 2%;
}

.text-center {
  text-align: center;
}

.table-data-min-width-large {
  min-width: 130px
}

.tracking-settings,
.budget-settings {
  display: grid;
  gap: 0.4rem;
}

.tracking-settings {
  grid-template-columns: 2fr 3fr;
}

.budget-settings {
  grid-template-columns: 1fr 1fr;
}

.tracking-settings>span:nth-child(2n),
.budget-settings>span:nth-child(2n) {
  justify-self: start;
  text-align: left;
  color: #566464;
}

.tracking-settings>span:nth-child(2n+1),
.budget-settings>span:nth-child(2n+1) {
  justify-self: end;
  text-align: right;
  color: #a7a7a7;
}

.dayOfWeek {
  display: flex;
  flex-direction: column;
}

.tracking-event,
.tracking-url {
  margin: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.4rem;
}

.tracking-url {
  margin-left: 2.4rem;
  margin-bottom: 0.8rem;
}

.table-data-min-width-small {
  min-width: 80px
}

.table-data-min-width-extra-small {
  min-width: 40px
}

.ellipsis-container {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  line-height: 21px;
  max-height: 44px;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  cursor: pointer;
}

.ellipsis-container::after {
  letter-spacing: .10em;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 10px 2px 45px;
}

.keyValueText {
  color: gray;
  font-weight: bold;
}

.targetType {
  font-style: italic;
}
</style>
