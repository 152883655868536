<template>
  <tr>
    <td class="data-center table-data-min-width-medium">{{ campaignData.name }}</td>
    <td class="data-center table-data-min-width-small" style="width: 10%;">
      <Row v-if="showLiveStatus">
        <Row-Col span="16" class="live-campaign-info">
          <u @click="setLiveCampaignStatus">{{ fetchedCampaignStatus }}</u>
        </Row-Col>
        <Row-Col span="8" class="text-right" style="color: #51b848">
          Live
        </Row-Col>
      </Row>
      <Row>
        <Row-Col span="16">
          <Dropdown v-if="enableStatusEdit && submissionId == 0" @on-click="updateCampaignStatus">
            {{ campaignCurrentStatus }}
            <Icon type="ios-arrow-down"></Icon>
            <DropdownMenu slot="list">
              <DropdownItem name="Active">Active</DropdownItem>
              <DropdownItem name="Paused">Paused</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <div v-else><span>{{ campaignData.status }}</span></div>
        </Row-Col>
        <Row-Col span="8" class="text-right">
          <i class="fa fa-pencil" @click="fetchCurrentCampaignStatus(campaignData.id)"
            v-if="!isFetchingStatus && campaignData.searchEngineCampaignId && !enableStatusEdit && submissionId == 0" />
          <i v-if="isFetchingStatus && submissionId == 0" class="fa fa-spinner fa-spin" />
          <Icon v-if="enableStatusEdit && submissionId == 0" class="campaign-row-icon" type="md-close"
            @click="cancelStatus" />
          <Icon v-if="enableStatusEdit && submissionId == 0" class="campaign-row-icon" type="md-checkmark"
            @click="applyStatus" />
        </Row-Col>
      </Row>
    </td>
    <td class="data-center table-data-min-width-medium">
      {{ getReadableDate(campaignData.startDate) }} - {{ getReadableDate(campaignData.endDate) }}
    </td>
    <td class="data-center table-data-min-width-medium">
      <span ref="toggleContainer" class="ellipsis-container" @click="toggleEllipsis">
        {{ campaignData.adUnitTargeting.map(adUnit => getKeyByValue(molocoEnums.adUnitTargeting, adUnit).split("_").join(" ")).join(", ") }}
      </span>
    </td>
    <td class="data-center table-data-min-width-small">{{ campaignData.budgetPackage }}</td>
    <td v-if="isAppInstallsObjective" class="data-center table-data-min-width-small">
      {{ campaignData.budgetType }}
    </td>
    <td class="data-center table-data-min-width-small">{{ campaignData.lifetimeBudget }}</td>
    <td class="data-center table-data-min-width-small" style="width: 10%;">
      <Row v-if="showLiveBid">
        <Row-Col span="16" class="live-campaign-info">
          <span @click="setLiveCampaignBid">{{ bpCurrencySymbol }} {{ fetchedCampaignBid }}</span>
        </Row-Col>
        <Row-Col span="8" class="text-right" style="color: #51b848">
          Live
        </Row-Col>
      </Row>
      <Row>
        <Row-Col span="16">
          <span>{{ bpCurrencySymbol }} </span>
          <i-input v-model="campaignCurrentBid" type="number" size="small" class="edit-bid-input"
            @on-change="validateInput" v-if="enableBidEdit && submissionId == 0" />
          <span v-else>{{ campaignData.bid }}</span>
        </Row-Col>
        <Row-Col span="8" class="text-right">
          <i class="fa fa-pencil" @click="fetchCurrentCampaignBid(campaignData.id)"
            v-if="!isFetchingBid && campaignData.searchEngineCampaignId && !enableBidEdit && submissionId == 0" />
          <i v-if="isFetchingBid && submissionId == 0" class="fa fa-spinner fa-spin" />
          <Icon v-if="enableBidEdit && submissionId == 0" class="campaign-row-icon" type="md-close"
            @click="cancelBid" />
          <Icon v-if="enableBidEdit && submissionId == 0" class="campaign-row-icon" type="md-checkmark"
            @click="applyBid" />
        </Row-Col>
      </Row>
    </td>
    <td class="data-center table-data-min-width-medium" style="width: 18%;" v-if="isBrandAwarenessObjective">
      <Row v-if="showLiveLandingUrl" style="margin-bottom:10px">
        <Row-Col span="16" class="live-campaign-info" @click.native="setLiveCampaignLandingUrl">
          <span> {{ fetchedCampaignLandingUrl ? fetchedCampaignLandingUrl : "-" }} </span>
        </Row-Col>
        <Row-Col span="8" class="text-right" style="color: #51b848">
          Live
        </Row-Col>
      </Row>
      <Row>
        <Row-Col span="16">
          <i-input v-model="campaignCurrentLandingUrl" type="text" size="small" class="landing-url-input"
            v-if="enableLandingUrlEdit && submissionId == 0" />
          <span v-else>{{ campaignData.landingUrl ? campaignData.landingUrl : "-" }}</span>
        </Row-Col>
        <Row-Col span="8" class="text-right">
          <i class="fa fa-pencil" @click="fetchCurrentCampaignLandingURL(campaignData.id)"
            v-if="!isFetchingLandingUrl && campaignData.searchEngineCampaignId && !enableLandingUrlEdit && submissionId == 0" />
          <i v-if="isFetchingLandingUrl && submissionId == 0" class="fa fa-spinner fa-spin" />
          <Icon v-if="enableLandingUrlEdit && submissionId == 0" class="campaign-row-icon" type="md-close"
            @click="cancelLandingUrl" />
          <Icon v-if="enableLandingUrlEdit && submissionId == 0" class="campaign-row-icon" type="md-checkmark"
            @click="applyLandingUrl" />
        </Row-Col>
      </Row>
    </td>
    <td class="data-center table-data-min-width-small" style="text-align: center;">
      <i class="fa fa-gear" :class="{ 'action-disabled': !campaignData.searchEngineCampaignId || submissionId != 0 }"
        @click="openCampaignEdit(campaignData)"></i>
    </td>
  </tr>
</template>

<script>
import { input, Dropdown, DropdownMenu, DropdownItem, Icon, Row, Col } from "iview";
import { APIService } from '../../../ApiService.js';
import * as molocoEnums from "../../../Constants/MolocoEnums.js";
import { mapState } from "vuex";

export default {
  props: {
    campaign: {
      type: Object,
      required: true
    },
    submissionId: {
      type: Number,
      required: true
    },
    bpCurrencySymbol: {
      type: String,
      required: true
    }
  },
  components: {
    "i-input": input,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Icon,
    Row,
    "Row-Col": Col
  },
  data: function () {
    return {
      molocoEnums,
      campaignData: {},
      isExpanded: false,
      isFetchingStatus: false,
      isFetchingBid: false,
      enableStatusEdit: false,
      enableBidEdit: false,
      enableTargetEdit: false,
      molocoCampaignData: null,
      fetchedCampaignStatus: null,
      fetchedCampaignBid: 0,
      isStatusEdited: false,
      isBidEdited: false,
      campaignCurrentBid: 0,
      campaignCurrentStatus: 0,
      showLiveStatus: false,
      showLiveBid: false,
      isFetchingLandingUrl: false,
      enableLandingUrlEdit: false,
      showLiveLandingUrl: false,
      campaignCurrentLandingUrl: null,
      fetchedCampaignLandingUrl: null,
      isLandingUrlEdited: false
    };
  },
  watch: {
    campaign: {
      immediate: true,
      handler: function (val) {
        this.campaignData = val;
      }
    }
  },
  computed: {
    ...mapState([
      "selectedCampaignLauncherConfig",
      "molocoCampaignLauncherPublishDataId"
    ]),
    isAppInstallsObjective () {
      return this.selectedCampaignLauncherConfig.campaign.objective == molocoEnums.objectives['App Installs'];
    },
    isBrandAwarenessObjective () {
      return this.selectedCampaignLauncherConfig.campaign.objective == molocoEnums.objectives["Brand Awareness"];
    }
  },
  methods: {
    toggleEllipsis () {
      const container = this.$refs.toggleContainer;
      if (this.isExpanded && container) {
        container.style.maxHeight = "44px"; // Limit to 3 lines
      } else {
        container.style.maxHeight = "none"; // Show complete text
      }
      this.isExpanded = !this.isExpanded;
    },
    getReadableDate (dateTimeStamp, isStartDate = false) {
      if (!dateTimeStamp) {
        if (isStartDate) {
          return "Now";
        }
        return "N/A";
      }
      var date = new Date(dateTimeStamp);
      return date.toDateString();
    },
    openCampaignEdit (campaign) {
      if (!campaign.searchEngineCampaignId || this.submissionId != 0) return;

      this.$emit("edit-campaign", campaign.id);
    },
    async fetchCurrentCampaignStatus (campaignId) {
      this.isFetchingStatus = true;
      this.campaignCurrentStatus = this.campaignData.status;
      if (!this.isStatusEdited && !this.campaignData.editedSummary.includes("Status")) {
        try {
          this.molocoCampaignData = await APIService.getMolocoCampaignData(this.selectedCampaignLauncherConfig.bpseId, campaignId);
          if (this.molocoCampaignData.campaign && this.molocoCampaignData.campaign.enabling_state === molocoEnums.CampaignEnablingState["Paused"]) {
            this.fetchedCampaignStatus = "Paused";
          } else {
            this.fetchedCampaignStatus = "Active";
          }
          this.isFetchingStatus = false;
          this.enableStatusEdit = true;
          this.compareStatusMismatch();
        } catch (error) {
          this.$Message.destroy();
          this.$Message.error('Failed to fetch latest campaign details from Moloco');
          console.error('Failed to fetch latest campaign details from Moloco', error);
          this.isFetchingStatus = false;
        }
      } else {
        this.isFetchingStatus = false;
        this.enableStatusEdit = true;
      }
    },
    async fetchCurrentCampaignBid (campaignId) {
      this.isFetchingBid = true;
      this.campaignCurrentBid = this.campaignData.bid;
      if (!this.isBidEdited && !this.campaignData.editedSummary.includes("Bid")) {
        try {
          this.molocoCampaignData = await APIService.getMolocoCampaignData(this.selectedCampaignLauncherConfig.bpseId, campaignId);
          if (this.molocoCampaignData && this.molocoCampaignData.campaign) {
            if (this.selectedCampaignLauncherConfig.campaign.objective === molocoEnums.objectives["App Installs"]) {
              this.fetchedCampaignBid = this.molocoCampaignData.campaign.avod_support.bid_flow_policy.predetermined_fixed_bid_cpm.amount_micros / 1000000;
            } else if (this.selectedCampaignLauncherConfig.campaign.objective === molocoEnums.objectives["Brand Awareness"]) {
              this.fetchedCampaignBid = this.molocoCampaignData.campaign.goal.optimize_fixed_cpm_pacing.target_cpm.amount_micros / 1000000;
            }
          }
          this.isFetchingBid = false;
          this.enableBidEdit = true;
          this.compareBidMismatch();
        } catch (error) {
          this.$Message.destroy();
          this.$Message.error('Failed to fetch latest campaign details from Moloco');
          console.error('Failed to fetch latest campaign details from Moloco', error);
          this.isFetchingBid = false;
        }
      } else {
        this.isFetchingBid = false;
        this.enableBidEdit = true;
      }
    },
    async fetchCurrentCampaignLandingURL (campaignId) {
      this.isFetchingLandingUrl = true;
      this.campaignCurrentLandingUrl = this.campaignData.landingUrl;
      if (!this.isLandingUrlEdited && !this.campaignData.editedSummary.includes("LandingUrl")) {
        try {
          this.molocoCampaignData = await APIService.getMolocoCampaignData(this.selectedCampaignLauncherConfig.bpseId, campaignId);
          if (this.molocoCampaignData && this.molocoCampaignData.campaign) {
            this.fetchedCampaignLandingUrl = this.molocoCampaignData.campaign.landing_url;
          }
          this.isFetchingLandingUrl = false;
          this.enableLandingUrlEdit = true;
          this.compareLandingUrl();
        } catch (error) {
          this.$Message.destroy();
          this.$Message.error('Failed to fetch latest campaign details from Moloco');
          console.error('Failed to fetch latest campaign details from Moloco', error);
          this.isFetchingLandingUrl = false;
        }
      } else {
        this.isFetchingLandingUrl = false;
        this.enableLandingUrlEdit = true;
      }
    },
    applyStatus () {
      this.enableStatusEdit = false;
      // edit endpoint call and emit new campaign value*
      // if (this.campaignCurrentStatus !== this.fetchedCampaignStatus) {
      if (this.campaignCurrentStatus !== this.campaignData.status) {
        let editRequestData = {
          lineItemId: this.campaignData.lineItemId,
          targetId: this.campaignData.targetId,
          lineItemFractionId: 0,
          PatchRequestModels: [
            {
              property: molocoEnums.campaignEditableFields.Status,
              value: this.campaignCurrentStatus === "Active" ? 1 : 2
            }
          ]
        };
        this.$Message.destroy();
        APIService.updateMolocoCampaignData(this.molocoCampaignLauncherPublishDataId, editRequestData).then(response => {
          this.$Message.success('Campaign status updated.');
          if (!this.$store.state.molocoEditedStatuses.includes(this.campaignData.id)) {
            this.$store.state.molocoEditedStatuses.push(this.campaignData.id);
          }
          this.isStatusEdited = true;
          this.showLiveStatus = false;
          this.campaignData.status = this.campaignCurrentStatus;
        }).catch(error => {
          console.log(error);
          this.$Message.error('Failed to update the campaign status.');
        });
      } else {
        this.isStatusEdited = true;
        this.showLiveStatus = false;
      }
      // if (this.campaignCurrentStatus === this.fetchedCampaignStatus) {
      //   this.showLiveStatus = false;
      // }
    },
    cancelStatus () {
      this.enableStatusEdit = false;
      this.showLiveStatus = false;
    },
    applyBid () {
      this.enableBidEdit = false;
      if (this.campaignCurrentBid <= 0) {
        if (this.selectedCampaignLauncherConfig.campaign.objective === molocoEnums.objectives["App Installs"]) {
          this.campaignCurrentBid = this.molocoCampaignData.campaign.avod_support.bid_flow_policy.predetermined_fixed_bid_cpm.amount_micros / 1000000;
        } else if (this.selectedCampaignLauncherConfig.campaign.objective === molocoEnums.objectives["Brand Awareness"]) {
          this.campaignCurrentBid = this.molocoCampaignData.campaign.goal.optimize_fixed_cpm_pacing.target_cpm.amount_micros / 1000000;
        }
      }
      // edit endpoint call and emit new campaign value*
      // if (this.campaignCurrentBid != this.fetchedCampaignBid) {
      if (this.campaignCurrentBid != this.campaignData.bid) {
        let editRequestData = {
          lineItemId: this.campaignData.lineItemId,
          targetId: this.campaignData.targetId,
          lineItemFractionId: 0,
          PatchRequestModels: [
            {
              property: molocoEnums.campaignEditableFields.Bid,
              value: this.campaignCurrentBid
            }
          ]
        };
        this.$Message.destroy();
        APIService.updateMolocoCampaignData(this.molocoCampaignLauncherPublishDataId, editRequestData).then(response => {
          this.$Message.success('Campaign bid updated.');
          if (!this.$store.state.molocoEditedBids.includes(this.campaignData.id)) {
            this.$store.state.molocoEditedBids.push(this.campaignData.id);
          }
          this.isBidEdited = true;
          this.showLiveBid = false;
          this.campaignData.bid = this.campaignCurrentBid;
        }).catch(error => {
          console.log(error);
          this.$Message.error('Failed to update the campaign bid.');
        });
      } else {
        this.isBidEdited = true;
        this.showLiveBid = false;
      }
      // if (this.campaignCurrentBid == this.fetchedCampaignBid) {
      //   this.showLiveBid = false;
      // }
    },
    cancelBid () {
      this.enableBidEdit = false;
      this.showLiveBid = false;
    },
    validateInput (e) {
      if (e.target.value <= 0) {
        this.$Message.destroy();
        this.$Message.error('The value must be greater than 0');
      }
    },
    compareStatusMismatch () {
      if (this.campaignData.status !== this.fetchedCampaignStatus) {
        this.showLiveStatus = true;
        this.$emit('show-warning', { campaignId: this.campaignData.id, type: "status", currentStatusValue: this.campaignData.status, mcpStatusValue: this.fetchedCampaignStatus });
      }
    },
    compareBidMismatch () {
      if (this.campaignData.bid != this.fetchedCampaignBid) {
        this.showLiveBid = true;
        this.$emit('show-warning', { campaignId: this.campaignData.id, type: "bid", currentBidValue: this.campaignData.bid, mcpBidValue: this.fetchedCampaignBid });
      }
    },
    updateCampaignStatus (status) {
      this.campaignCurrentStatus = status;
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    setLiveCampaignStatus () {
      this.campaignCurrentStatus = this.fetchedCampaignStatus;
      this.showLiveStatus = false;
    },
    setLiveCampaignBid () {
      this.campaignCurrentBid = this.fetchedCampaignBid;
      this.showLiveBid = false;
    },
    cancelLandingUrl () {
      this.enableLandingUrlEdit = false;
      this.showLiveLandingUrl = false;
    },
    applyLandingUrl () {
      if (this.validateUrl()) {
        this.enableLandingUrlEdit = false;
        if ((this.campaignCurrentLandingUrl || this.campaignData.landingUrl) && (this.campaignCurrentLandingUrl !== this.campaignData.landingUrl)) {
          let editRequestData = {
            lineItemId: this.campaignData.lineItemId,
            targetId: this.campaignData.targetId,
            lineItemFractionId: 0,
            PatchRequestModels: [
              {
                property: molocoEnums.campaignEditableFields.LandingUrl,
                value: this.campaignCurrentLandingUrl
              }
            ]
          };
          this.$Message.destroy();
          APIService.updateMolocoCampaignData(this.molocoCampaignLauncherPublishDataId, editRequestData).then(response => {
            this.$Message.success('Campaign Landing URL updated.');
            if (!this.$store.state.molocoEditedLandingUrls.includes(this.campaignData.id)) {
              this.$store.state.molocoEditedLandingUrls.push(this.campaignData.id);
            }
            this.campaignData.landingUrl = this.campaignCurrentLandingUrl;
            this.isLandingUrlEdited = true;
            this.showLiveLandingUrl = false;
          }).catch(error => {
            console.log(error);
            this.$Message.error('Failed to update the campaign status.');
          });
        } else {
          this.isLandingUrlEdited = true;
          this.showLiveLandingUrl = false;
        }
      }
    },
    setLiveCampaignLandingUrl () {
      this.campaignCurrentLandingUrl = this.fetchedCampaignLandingUrl;
      this.showLiveLandingUrl = false;
    },
    compareLandingUrl () {
      if ((this.campaignData.landingUrl || this.fetchedCampaignLandingUrl) && (this.campaignData.landingUrl !== this.fetchedCampaignLandingUrl)) {
        this.showLiveLandingUrl = true;
        this.$emit('show-warning', { campaignId: this.campaignData.id, type: "landingUrl", currentLandingUrlValue: this.campaignData.landingUrl, mcpLandingUrlValue: this.fetchedCampaignLandingUrl });
      }
    },
    validateUrl () {
      let url = this.campaignCurrentLandingUrl;
      let pattern = new RegExp('^(http(s)?:\\/\\/.)[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$');
      if (url && !pattern.test(url)) {
        this.$Message.destroy();
        this.$Message.error('Please enter a valid landing URL starting with http(s)');
        return false;
      }
      return true;
    }
  }

};
</script>

<style scoped>
.live-campaign-info {
  cursor: pointer;
}

.campaign-row-icon:hover {
  cursor: pointer;
}

.icon-section {
  display: flex;
  justify-content: space-evenly;
  vertical-align: middle;
  margin-top: 5px;
}

.edit-status-select {
  width: 50%;
}

::v-deep .landing-url-input .ivu-input-small {
  border: 0px;
  border-bottom: 0.3px solid #566464;
  box-shadow: none;
  width: 150px;
}

::v-deep .ivu-input-small {
  padding: 2px 0px;
  height: 40px;
  border-radius: 0px;
}

.landing-url-input {
  width: 100%;
  border: 0px;
}

::v-deep .edit-bid-input .ivu-input-small {
  border: 0px;
  border-bottom: 0.3px solid #566464;
  box-shadow: none;
  width: 70px;
}

::v-deep .ivu-input-small {
  padding: 0px 2px;
  height: 20px;
  border-radius: 0px;
}

.edit-bid-input {
  width: 50%;
  border: 0px;
}

.title-sticky {
  padding: 0px 5px;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}

.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}

.verified-action {
  float: right;
  padding: 10px;
}

.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}

input[type="checkbox"] {
  vertical-align: text-bottom;
}

.total-footer {
  font-weight: 600;
  background-color: #eee;
}

.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}

.target-label {
  padding-top: 15px;
}

.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}

.page-label {
  padding: 10px 5px;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}

.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}

.more-previews {
  font-size: 1.3em;
}

.data-right {
  text-align: right;
}

.data-center {
  text-align: left;
  vertical-align: middle;
}

.upper-case {
  text-transform: uppercase;
}

::v-deep .ivu-modal-footer {
  display: none;
}

.table-header {
  border: 1px solid #ddd !important;
  padding: 10px !important;
}

.id-label {
  font-size: 12px;
}

.table-data-min-width-medium {
  min-width: 130px
}

span,
td {
  color: #566464;
}

.cell-data-value-split-45 {
  display: inline-block;
  width: 48%;
  text-align: right;
  vertical-align: top;
  color: #a7a7a7;
}

.action-disabled {
  cursor: not-allowed;
  color: #a9a9a9;
}
</style>
<style>
table.target-info-table td {
  border: 0 !important;
}

.cell-data-value-split-55 {
  display: inline-block;
  width: 50%;
  text-align: left;
  margin-left: 2%;
}

.text-center {
  text-align: center;
}

.table-data-min-width-large {
  min-width: 130px
}

.tracking-settings,
.budget-settings {
  display: grid;
  gap: 0.4rem;
}

.tracking-settings {
  grid-template-columns: 2fr 3fr;
}

.budget-settings {
  grid-template-columns: 1fr 1fr;
}

.tracking-settings>span:nth-child(2n),
.budget-settings>span:nth-child(2n) {
  justify-self: start;
  text-align: left;
  color: #566464;
}

.tracking-settings>span:nth-child(2n+1),
.budget-settings>span:nth-child(2n+1) {
  justify-self: end;
  text-align: right;
  color: #a7a7a7;
}

.dayOfWeek {
  display: flex;
  flex-direction: column;
}

.tracking-event,
.tracking-url {
  margin: 0;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.4rem;
}

.tracking-url {
  margin-left: 2.4rem;
  margin-bottom: 0.8rem;
}

.table-data-min-width-small {
  min-width: 80px
}

.table-data-min-width-extra-small {
  min-width: 40px
}

.ellipsis-container {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  line-height: 21px;
  max-height: 44px;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  cursor: pointer;
}

.ellipsis-container::after {
  letter-spacing: .10em;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 10px 2px 45px;
}

.keyValueText {
  color: gray;
  font-weight: bold;
}

.targetType {
  font-style: italic;
}
</style>
