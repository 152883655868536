<template>
<div>
  <div class="page-title" v-if="!this.isWizardFlow">
    <div class="breadcrumb-env col-md-12" style="padding-right: 10px">
      <ol class="breadcrumb bc-1" style="margin-bottom: 8px;">
        <li>
          <a href="/App/Avertisers/List">
            <i class="fa fa-home">
            </i>
            Home
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fas fa-rocket"></i>
            Campaign Launcher
          </a>
        </li>
        <li v-if="selectedMediaplanName && configChange && isSummaryPathBool">
          <a>
            <i class="fas fa-puzzle-piece"></i>
            {{selectedMediaplanName}}
          </a>
        </li>
        <li v-if="selectedMediaplanName && configChange && isSummaryPathBool">
          <a>
            <i class="fas fa-cog"></i>
            <select class="customSelect" v-model="configChange">
              <option class="customOption" v-for="item in mediaPlanConfigs" :key="item.Id" :value="item.Id">{{item.Name}}</option>
            </select>
          </a>
        </li>
        <input type="button" v-show="!displayExistingSubmissions && !isCreationFlow && authFlowExists" class="btn btn-white toggle-submission-mode" value="Submissions" v-on:click="showSubmissions()"/>
        <input type="button" v-show="displayExistingSubmissions && !isCreationFlow && authFlowExists" class="btn btn-white toggle-submission-mode" value="New" v-on:click="hideSubmissions()"/>
      </ol>
    </div>
  </div>
  <div v-show="!isRouteProcessed">
    <div v-if="!promoAlert.show" class="cl-spinner">
      <i class="fa fa-lg fa-spinner fa-spin" />
    </div>
    <div v-else class="alert alert-warning">
      <span v-html="promoAlert.message"></span>
    </div>
  </div>
  <div v-show="isRouteProcessed">
    <Alert banner closable :type="alert.type" v-show="alert.show">
      <span v-html="alert.message" slot="desc"></span>
    </Alert>
    <div class="row" v-if="!this.alert.isBlocking" v-show="!autoNavigateToNextStep && !configChangeLoading">
      <Layout v-show="!showNextStep">
        <Layout>
          <Content>
            <div class="col-md-12">
            <div class="col-md-12 no-padding" v-show="!isWizardFlow">
              <div class="col-md-12 widget-card no-padding" style="height:unset;">
                <launch-settings
                  :mediaPlan="selectedMediaPlan"
                  :mediaPlans="mediaPlans"
                  :submissionId="submissionId"
                  :allSubmissions="existingSubmissions"
                  :campaignConfigId="campaignConfigId"
                  v-show="!displayExistingSubmissions"
                  v-on:submissionselect="loadSubmission($event)"
                  ></launch-settings>
                <reach-and-frequency-widget v-show="isRnfConfig" :loadRnfConfig="isRnfConfig">
                </reach-and-frequency-widget>
              </div>
            </div>
            <div class="col-md-12 no-padding">
              <div v-if="showCampaignLauncherAlert" class="alert alert-warning" role="alert">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="set_showCampaignLauncherAlert(false)"
                >×</button>
                <span v-html="campaignLauncherAlertMessage"></span>
              </div>
            </div>
            <div class="col-md-12 no-padding">
              <div v-if="showCampaignLauncherError" class="alert alert-danger" role="alert">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  @click="() => {
                    set_showCampaignLauncherError(false);
                    isShowMoreError = false;
                  }"
                >×</button>
                <span v-html="processedCampaignLauncherErrorMessage" ></span>
                <div v-if = "campaignLauncherAssetsWithError.length > 0">
                  <span>Unable to fetch the following assets</span><br/>
                  <div v-for="(item, index) in displayedItems" :key="index">
                    <span style="padding: 3px">{{ item }}</span>
                  </div>
                  <p class="showMoreErrors" v-if="campaignLauncherAssetsWithError.length > 5" @click="showMoreOrLessFailedAds">{{ campaignLauncherAssetsWithError.length > displayFailedAdlimit ? "Show More" : "Show Less"}}</p>
                </div>
                <p
                  class="showMoreErrors"
                  v-if="campaignLauncherErrorMessage instanceof Array && campaignLauncherErrorMessage.length > 5" @click="isShowMoreError = !isShowMoreError">
                  {{ isShowMoreError ? "Hide" : "Show More" }}
                </p>
              </div>
            </div>
            <div class="col-md-12 no-padding" style="background: #FFFFFF;">
              <div
                  class="col-md-12 no-padding"
                  style="background: #FFFFFF;"
                >
                  <ad-sheet-widget
                    ref="adSheetWidget"
                    :fetchGroupedEntitiesURL="deltax.fetchGroupedEntitiesURL"
                    :fetchAdWordsGroupedEntitiesURL="deltax.fetchAdWordsGroupedEntitiesURL"
                    :submissionId="submissionId"
                    v-on:tabchange="toggleInstantExperienceWidget($event)"
                  >
                  </ad-sheet-widget>
                </div>
            </div>
          </div>
          </Content>
        </Layout>
        <div
          v-if="isFullIntegration()"
          id="asset-sider-trigger"
          :class="{
            'asset-sider-trigger-promotion': isWizardFlow,
            'asset-sider-trigger-cl': !isWizardFlow
          }"
          @click="toggleAssetSider"
          ref="assetSiderTrigger"
        >
          <Icon type="ios-arrow-back" v-show="assetSider.show"/>
          <Icon type="ios-arrow-forward" v-show="!assetSider.show"/>
          <div id="asset-sider-text">
            Assets
          </div>
        </div>
        <Sider
          v-show="isFullIntegration()"
          ref="assetSider"
          id="asset-sider"
          collapsible
          hide-trigger
          default-collapsed
          v-model="assetSider.show"
          reverse-arrow
          :collapsed-width="0"
          :width="assetSiderWidth"
          :style="{ 'margin-top': isWizardFlow ? '2px' : '0px' }"
        >
          <div :class="assetSiderClass">
            <div class="cla-resize-bar">
              <div class="cla-resize-bar-hov"></div>
            </div>
            <VuePerfectScrollbar class="scroll-area" :settings="{ maxScrollbarLength: 200 }">
              <page-posts
                v-if="showPagePosts"
              ></page-posts>
              <creative-library-widget
                v-if="!displayExistingSubmissions && selectedPublisher == publisher.Facebook"
                :fetchMediaUrl="deltax.fetchMediaUrl"
                :fetchVideosUrl="deltax.fetchVideosUrl"
                :saveImageURL="deltax.saveImageURL"
              ></creative-library-widget>
              <linkedin-creative-widget
                v-if="!displayExistingSubmissions && selectedPublisher == publisher.LinkedIn"
                :fetchMediaUrl="deltax.fetchMediaUrl"
                :saveImageURL="deltax.saveImageURL"
              ></linkedin-creative-widget>
              <dv360-creative-widget
                v-if="!displayExistingSubmissions && selectedPublisher == publisher.DV360"
              ></dv360-creative-widget>
              <moloco-creative-widget
              v-if="!displayExistingSubmissions && selectedPublisher == publisher.Moloco">
              </moloco-creative-widget>
              <adwords-creative-widget
              v-if="showAdWordsAsset && selectedPublisher == publisher.Adwords">
              </adwords-creative-widget>
              <instant-experience-widget ref="ie-widget" :deltaxConfig="deltaxConfig" v-show="instantExperiencesWidget.show"></instant-experience-widget>
              <target-clusters-widget v-if="isPromotionMode"/>
              <target-widget
                :getAudiences="deltax.getAudiencesURL"
                :createTarget="deltax.createTargetURL"
                :targets="accountTargets"
                v-if="!displayExistingSubmissions && selectedPublisher == publisher.Facebook"
              ></target-widget>
              <location-widget
                v-if="showLocationsWidget"
              ></location-widget>
              <product-sets-widget
                v-if="showProductSetsWidget"
              ></product-sets-widget>
              <dv360-target-widget
                v-if="!displayExistingSubmissions && selectedPublisher == publisher.DV360"
              ></dv360-target-widget>
              <ams-category-target-widget
                :publisherId="selectedPublisher"
                v-if="selectedPublisher == publisher.AMS.SponsoredProducts || selectedPublisher == publisher.AMS.SponsoredDisplay"
              ></ams-category-target-widget>
              <moloco-target-widget
                v-if="!displayExistingSubmissions && selectedPublisher == publisher.Moloco"
              ></moloco-target-widget>
              <!-- Lead forms widget -->
              <lead-forms
                v-if="showFacebookLeadForms"
                :deltaxConfig="deltaxConfig"
              ></lead-forms>
              <dpa-macros v-if="isDPAObjective(selectedCampaignLauncherConfig) || showProductSetsWidget"></dpa-macros>
              <lineitemfractions v-if="selectedPublisher != publisher.Adwords && selectedPublisher != publisher.Moloco"></lineitemfractions>
              <moloco-line-item-widget v-if="selectedPublisher == publisher.Moloco"></moloco-line-item-widget>
              <adwords-line-item-widget v-if="selectedPublisher == publisher.Adwords && !isPromotionMode"></adwords-line-item-widget>
              <linkedin-lead-form-widget v-if="showLinkedInLeadForms">
              </linkedin-lead-form-widget>
              <twitter-target-widget
                v-if="!displayExistingSubmissions && selectedPublisher == publisher.Twitter"
              ></twitter-target-widget>
              <twitter-existing-tweets v-if="showExistingTweets"/>
              <StoreVisitsMacros v-if="isStoreVisitsObjective(selectedCampaignLauncherConfig)"></StoreVisitsMacros>
              <NegativeKeywordsWidget v-if="showNegativeKeywords"></NegativeKeywordsWidget>
              <AdExtensionWidget v-if="showAdExtensions"></AdExtensionWidget>
            </VuePerfectScrollbar>
          </div>
        </Sider>
      </Layout>

      <div v-if="showNextStep">
        <div class="col-md-12">
          <bulk-export v-on:campaigns-published="getMappedLineItems()"
            :isCampaignLuncher="'true'"
            :facebookEntities="facebookEntities"
            :userCanApprove="userCanApprove"
            :submissionId="submissionId"
            :authFlow="getFlowForSelectedPublisher"
            @submissionChanged="submissionChanged"
            @readyToView="readyToViewChanged"
            @userApproved="setUserApproval"
            @backToSetupClicked="changeViewType"
            :allSubmissions="existingSubmissions"
            :viewType="viewType"
            ref="bulkExport"
          >
            <template v-slot:authFlow v-if="authFlowExists">
              <div v-show="submissionId > 0 && readyToView">
                <auth-flow-app ref="authFLow" :isEditMode="false"> </auth-flow-app>
              </div>
          </template>
          </bulk-export>
        </div>
      </div>
    </div>
    <div v-show="autoNavigateToNextStep || configChangeLoading" class="cl-spinner">
      <i class="fa fa-lg fa-spinner fa-spin" />
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import CreativeLibraryWidget from "./CreativeLibraryWidget.vue";
import TargetWidget from "./TargetWidget.vue";
import LocationWidget from "./LocationWidget.vue";
import TargetClustersWidget from "./TargetClustersWidget.vue";
import ProductSetsWidget from "./ProductSetsWidget.vue"
import { APIService } from "./../ApiService.js";
import PromotionApiService from '../../Promotion/ApiService.js';
import LaunchSettings from "./LaunchSettings.vue";
import AdSheetWidget from "./AdSheetWidget.vue";
import InstantExperienceWidget from "./InstantExperienceWidget.vue";
import PagePosts from "./PagePosts.vue";
import App from "../BulkExport/Components/App.vue";
import Authorizations from '../../../Features/AuthorizationFlow/Components/Authorizations.vue'
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { Alert, Sider, Layout, Content, Icon } from "iview";
import ReachAndFrequencyWidget from "./ReachAndFrequencyWidget.vue";
import { EventBus } from "../EventBus.js"
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import LeadForms from "./LeadForms.vue";
import FacebookCampaignLauncherConfig from "../Mixins/FacebookCampaignLauncherConfig.js";
import DpaMacros from "./DpaMacros.vue";
import Bugsnag from '@bugsnag/js';
import LinkedInCreativeWidget from './LinkedIn/CreativeWidget.vue'
import LinkedInLeadFormWidget from './LinkedIn/LeadFormWidget.vue'
import DV360CreativeWidget from "./DBM/DV360CreativeWidget.vue";
import DV360TargetWidget from "./DBM/DV360TargetWidget.vue";
import TwitterExistingTweetsWidget from "./Twitter/ExistingTweetsWidget.vue"
import TwitterTargetWidget from "./Twitter/TargetsWidget.vue"
import { objectives, targetingType, locationMode } from "../Constants/FacebookEnums.js";
import StoreVisitsMacros from "./StoreVisitsMacros.vue";
import AmsCategoryTargetWidget from "./AmsCategoryTargetWidget.vue";
import LineItemFractions from "./Facebook/LineItemFractionsWidget.vue";
import AdExtensionWidget from "./AdWords/AdExtensionWidget.vue";
import NegativeKeywordsWidget from "./AdWords/NegativeKeywordsWidget.vue";
import BatchApiHelper from "../../../Mixins/batchApiHelper.js";
import { GlobalAPIService } from "../../../Api/ApiService.js";
import CLHelpermethods from "../Mixins/CLHelperMethods.js";
import MolocoCreativeWidget from './Moloco/CreativeWidget.vue'
import MolocoTargetWidget from './Moloco/TargetWidget.vue'
import MolocoLineItemWidget from './Moloco/LineItemWidget.vue'
import AdwordsLineItemWidget from './AdWords/LineItemWidget.vue'
import AdwordsCreativeWidget from './AdWords/CreativeWidget.vue'
import { adwordsEnums } from "../Constants/AdwordsEnums.js";

export default {
  name: "app",
  components: {
    "target-widget": TargetWidget,
    "location-widget": LocationWidget,
    "target-clusters-widget": TargetClustersWidget,
    "product-sets-widget": ProductSetsWidget,
    "ad-sheet-widget": AdSheetWidget,
    LaunchSettings,
    "creative-library-widget": CreativeLibraryWidget,
    "bulk-export": App,
    'auth-flow-app': Authorizations,
    Alert,
    "reach-and-frequency-widget": ReachAndFrequencyWidget,
    Sider,
    Layout,
    Content,
    Icon,
    VuePerfectScrollbar,
    "instant-experience-widget": InstantExperienceWidget,
    "page-posts": PagePosts,
    "lead-forms": LeadForms,
    "dpa-macros": DpaMacros,
    "linkedin-creative-widget": LinkedInCreativeWidget,
    'linkedin-lead-form-widget': LinkedInLeadFormWidget,
    "dv360-creative-widget": DV360CreativeWidget,
    "dv360-target-widget": DV360TargetWidget,
    "twitter-existing-tweets": TwitterExistingTweetsWidget,
    "twitter-target-widget": TwitterTargetWidget,
    StoreVisitsMacros,
    "ams-category-target-widget": AmsCategoryTargetWidget,
    "lineitemfractions": LineItemFractions,
    AdExtensionWidget,
    NegativeKeywordsWidget,
    "moloco-creative-widget": MolocoCreativeWidget,
    "moloco-target-widget": MolocoTargetWidget,
    "adwords-creative-widget": AdwordsCreativeWidget,
    "moloco-line-item-widget": MolocoLineItemWidget,
    "adwords-line-item-widget": AdwordsLineItemWidget
  },
  props: {
    deltaxConfig: { type: Object, default: () => {} }
  },
  mixins: [FacebookCampaignLauncherConfig, BatchApiHelper, CLHelpermethods],
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleWindowScroll)
    EventBus.$off("campaignLauncherApp-selectNewMediaPlan");
  },
  async created () {
    if (window.promotionId) {
      this.set_isWizardFlow(true)
    }
    if (this.isSummaryPath()) {
      this.set_autoNavigateToNextStep(true);
    }
    Bugsnag.leaveBreadcrumb('Loaded campaign launcher');
    Bugsnag.leaveBreadcrumb('Created hook - Setting up data');
    window.addEventListener('scroll', this.handleWindowScroll)
    EventBus.$on("getReachAndFrequency", length => {
      this.rnfSuggestion()
    });
    EventBus.$on("getAmsMediaPlanLineItems", length => {
      this.getMediaPlanLineItems()
    });
    this.set_deltax(this.deltaxConfig);
    var mediaPlans = JSON.parse(this.deltaxConfig.mediaPlans);
    this.set_mediaPlans(mediaPlans);
  },
  async mounted () {
    const isPromoLocalFlag = this.$route ? (Boolean(this.$route.params.promotionId) || Boolean(window.promotionId)) : false;
    const isNewLauncherFlow = this.$route.path.includes('/review-summary') || this.$route.path.includes('/review-submission')
    try {
      Bugsnag.leaveBreadcrumb('Mounted hook - Setting up data');
      if (!isPromoLocalFlag) {
        let submissionsResponse = await APIService.getApiData(
          this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.deltaxConfig.businessProfileId +
          "/Submissions?limit=20" +
          "&isNewLauncherFlow=" +
          isNewLauncherFlow
        );
        this.existingSubmissions = submissionsResponse.data;
      }
      this.SET_BPID(deltax.businessProfileId);
      this.mediaPlans = JSON.parse(this.deltax.mediaPlans);

      await this.getPublisherAccounts(isPromoLocalFlag);
      let getAccountCalls = [this.getNomenclatureSettings()];
      this.getAuthorizationFlow()
      if (!isPromoLocalFlag) {
        getAccountCalls.push(
          this.getAccounts()
        )
      }
      if ((isPromoLocalFlag && this.selectedPublisher == this.publisher.Adwords) || !isPromoLocalFlag) {
        getAccountCalls.push(this.getGoogleAdWordsData())
      }
      await Promise.all(getAccountCalls);

      var metadata = {
        route: this.$route.fullPath,
        params: this.$route.params
      }
      Bugsnag.leaveBreadcrumb('Processing route', metadata, 'state')
      let getStoresResponse = null;
      if (!this.$store.state.dbStores && isPromoLocalFlag) {
        getStoresResponse = await APIService.getStores(true);
      }
      if (getStoresResponse && getStoresResponse.data.data) {
        let dbStores = getStoresResponse.data.data.filter(x => x.name);
        await this.$store.commit("set_dbStores", dbStores);
        await this.getLabelsTypesById();
      }
      this.processRoute(this.$route, true);

      EventBus.$on("campaignLauncherApp-selectNewMediaPlan", data => {
        this.processNewlyCreatedMediaPlan(data);
      })
    } catch (error) {
      console.log(error)
      Bugsnag.notify(error);
      if (isPromoLocalFlag) {
        this.showPromoAlert();
      } else {
        this.alert.message = "Something went wrong while processing your request";
        this.alert.type = "info";
        this.alert.show = true;
        this.isRouteProcessed = true;
      }
    }
    this.addAssetsResizeListeners();
  },
  data: function () {
    return {
      isRouteProcessed: false,
      isShowMoreError: false,
      widget: [],
      lineItemIds: "",
      mediaPlans: [],
      selectedMediaPlan: {
        Id: 0,
        Name: "",
        Objective: "",
        StartDate: "",
        EndDate: "",
        Budget: ""
      },
      alert: {
        type: "info",
        show: false,
        message: "",
        isBlocking: false
      },
      submissionId: 0,
      existingSubmissions: [],
      readyToView: false,
      accountTargets: [],
      campaignConfigId: 0,
      isRnfConfig: false,
      assetSider: {
        show: false,
        collapseWidth: 0
      },
      instantExperiencesWidget: {
        show: false
      },
      promoAlert: {
        show: false,
        message: ''
      },
      processedConfigParameterOnce: false,
      assetSiderWidth: this.isWizardFlow ? 395 : 380,
      assetSiderStartWidth: null,
      mouseStartX: null,
      authorizationFlowByEngineType: {},
      configChange: null,
      isInitialLoad: true,
      isSummaryPathBool: false,
      fbObjectives: objectives,
      publisherResponse: {},
      publishedCreativeResponse: null,
      mappedLineItemResponse: null,
      batchResponseData: {},
      viewType: "Changes",
      displayFailedAdlimit: 5,
      showAdWordsAsset: false,
      adwordsEnums: adwordsEnums,
      defaultPublisher: deltax.defaultPublisher
    };
  },
  watch: {
    isRefreshAmsTargets: function () {
      if (this.isRefreshAmsTargets) {
        this.getSavedTargets();
      }
    },
    "configChange": function (value) {
      this.$store.commit("set_tableData", null)
      this.set_publishedCreatives({});
      this.set_createSheetCreatives({});
      if (value && value > 0 && this.isSummaryPath() && !this.isInitialLoad) {
        this.$store.commit("set_configChangeLoading", true)
        this.$router.replace({ path: this.getPath(), query: { mediaPlan: this.mediaPlanId, publisher: this.selectedPublisher, config: value } });
        this.$store.commit("set_selectedCampaignLauncherConfigId", value)
        this.$store.commit("set_selectedCampaignLauncherConfig", JSON.parse(this.campaignLauncherConfigs[value].config))
        if (this.publishedCreativesByCampaignConfigId[value] && this.publishedCreativesByCampaignConfigId[value].creativeJson) {
          this.$store.commit("set_publishedCreatives", JSON.parse(this.publishedCreativesByCampaignConfigId[value].creativeJson))
          if (this.publishedCreativesByCampaignConfigId[value].editedCreativeJson) {
            this.$store.commit("set_publishedCreativesInitialState", JSON.parse(this.publishedCreativesByCampaignConfigId[value].editedCreativeJson))
          } else {
            this.$store.commit("set_publishedCreativesInitialState", {})
          }
          this.$refs.adSheetWidget.SwitchConfigFromSummary(true)
        } else {
          this.$store.commit("set_publishedCreatives", {})
          this.$store.commit("set_publishedCreativesInitialState", {})
          this.$refs.adSheetWidget.SwitchConfigFromSummary(false)
        }
      }
      if (this.isInitialLoad) {
        this.isInitialLoad = false;
      }
    },
    "selectedMediaPlan.Id": function (id) {
      if (!id) {
        this.set_mediaPlanId(0);
        if (this.isDefaultPath()) { // reset url in case of invalid media plan id
          this.$router.replace({ path: "/new" });
        }
        return;
      }
      this.$store.commit("set_editSheetLoadProgress", 0)
      this.set_selectedMediaplanName(this.mediaPlans.filter(x => x.Id == id)[0].Name)
      this.set_mediaPlanId(id);
      if (this.selectedPublisher) {
        this.resetSelectedconfigAndGetLineItems();
      }

      if (this.isDefaultPath()) {
        this.$router.replace({ path: this.getPath(), query: { mediaPlan: id, publisher: this.selectedPublisher } });
      }
    },
    '$route': function (to, from) {
      var processQueryParams = false;
      if (from && from.params && from.params.submissionId) {
        processQueryParams = true;
        this.processedConfigParameterOnce = false
      }
      this.processRoute(to, processQueryParams);
      this.isSummaryPathBool = this.isSummaryPath();
    },
    'authFlows': function (to, from) {
      // if (JSON.stringify(to) != JSON.stringify(from) && this.getFlowForFacebookBulkAdCreation != null) {
      //   this.setAuthFlows([this.getFlowForFacebookBulkAdCreation]);
      // }
    },
    isRNFWatch: {
      handler: function () {
        this.rnfSuggestion()
      }
    },
    selectedPublisher: function (value) {
      if (this.mediaPlanId) {
        this.resetSelectedconfigAndGetLineItems();
      }

      if (this.isDefaultPath()) {
        var query = {}
        if (this.mediaPlanId > 0) {
          query.mediaPlan = this.mediaPlanId;
        }
        query.publisher = value;
        this.$router.replace({ path: this.getPath(), query: query });
      }
    },
    selectedCampaignLauncherConfigId: function (value) {
      if (value && value > 0 && this.isDefaultPath()) {
        this.configChange = value;
        if (this.viewType == 'Full setup') {
          this.$router.replace({ path: this.getPath(), query: { mediaPlan: this.mediaPlanId, publisher: this.selectedPublisher, config: value, view: 'Full' } });
        } else {
          this.$router.replace({ path: this.getPath(), query: { mediaPlan: this.mediaPlanId, publisher: this.selectedPublisher, config: value } });
        }
      }
    },
    "selectedCampaignLauncherConfig": function (value) {
      if (this.selectedPublisher == this.publisher.Adwords) {
        if (value.campaign.type == this.adwordsEnums.campaignType["Performance Max"] || value.campaign.type == this.adwordsEnums.campaignType["Display"] || value.campaign.type == this.adwordsEnums.campaignType["App"] || value.campaign.type == this.adwordsEnums.campaignType["Demand Gen"]) {
          this.showAdWordsAsset = true;
        } else {
          this.showAdWordsAsset = false;
        }
      }
    }
  },
  methods: {
    copyToClipboard (value) {
      let textarea = document.createElement("textarea")
      textarea.textContent = value
      textarea.style.position = "fixed"
      document.body.appendChild(textarea)
      textarea.select()
      try {
        document.execCommand("copy")
      } catch (e) {
        alert('Unable to copy to clipboard. Please copy manually.')
      }
      document.body.removeChild(textarea)
    },
    showMoreOrLessFailedAds () {
      if (this.displayFailedAdlimit < this.campaignLauncherAssetsWithError.length) {
        this.displayFailedAdlimit = this.campaignLauncherAssetsWithError.length
      } else {
        this.displayFailedAdlimit = 5
      }
    },
    getPath () {
      return this.$router.currentRoute.path;
    },
    isDefaultPath () {
      var path = this.$router.currentRoute.path;
      return path == "/new" || path == "/summary";
    },
    isSummaryPath () {
      var path = this.$router.currentRoute.path;
      return path == "/summary";
    },
    ...mapMutations([
      "SET_BPID",
      "set_businessProfile",
      "SET_BPSEID",
      "set_accounts",
      "set_googleAccounts",
      "set_amsAccounts",
      "set_dbmAccounts",
      "set_linkedInAccounts",
      "set_twitterAccounts",
      "set_accountAssetsMapper",
      "set_campaignLauncherConfigurations",
      "set_mediaPlanId",
      "set_deltax",
      "set_savedTargetsMapperByBpseId",
      "set_campaignLauncherConfigBpseIdMapper",
      "set_mediaPlanLineItems",
      "set_campaignLauncherConfigs",
      "set_publishedCreativesByCampaignConfigId",
      "SET_selectedFbObjective",
      "set_selectedCampaignLauncherConfigId",
      "SET_FACEBOOK_ENTITIES",
      "SET_SHOW_PREVIEW",
      "set_isCreationFlow",
      "set_publishedCreatives",
      "set_usersData",
      "set_publishedCampaignsCountByMediaPlan",
      "set_labels",
      "set_showCampaignLauncherAlert",
      "set_campaignLauncherAlertMessage",
      "set_showCampaignLauncherError",
      "set_dxInboundEmails",
      "set_s3InboundMailRecipientDomain",
      "set_googleLanguages",
      "set_showSpinner",
      "set_configIdSearchEngineTypeIdMapper",
      "set_googleLanguages",
      "set_isPromotionMode",
      "set_promotionObject",
      "set_promotionId",
      "set_selectedCampaignLauncherConfig",
      "set_products",
      "set_savedTargets",
      "set_mediaPlans",
      "set_bpCurrencySymbol",
      "set_accountMacros",
      "set_autoNavigateToNextStep",
      "set_selectedMediaplanName",
      "set_selectedObjectiveIdForPublisher",
      "set_isCampaignLauncherVerificationEnabled",
      "set_nomenclatureSettings",
      "set_adLabelDimensionNomenclature",
      "set_labelDimensions",
      "setInitialLocations",
      "set_existingPostAds",
      "set_mappedLineItems",
      "set_isRefreshAmsTargets",
      "set_isWizardFlow",
      "set_dbStores",
      "set_dbLabelTypes",
      "set_selectedPublisher",
      "set_promoTargetClusters",
      "set_molocoAccounts",
      "set_isPromotionCampaignLaunch",
      "set_createSheetCreatives",
      "set_amsEntitiesResponse",
      "set_dv360EntitiesResponse",
      "set_linkedInEntitiesResponse",
      "set_twitterEntitiesResponse",
      "set_adwordsEntitiesResponse"
    ]),
    ...mapActions(["setAuthFlows", "setUserFlowApproval", "getLabelsTypesById"]),
    addAssetsResizeListeners () {
      const resizeBar = document.querySelector('.cla-resize-bar');
      if (resizeBar) {
        resizeBar.addEventListener("mousedown", (event) => {
          if (window.getSelection) { window.getSelection().removeAllRanges(); }

          this.assetSiderStartWidth = this.assetSiderWidth;
          this.mouseStartX = event.pageX;

          document.querySelector('.cla-resize-bar-hov').style.backgroundColor = "rgb(76, 154, 255)";

          document.body.addEventListener("mousemove", this.processMouseMove);
          document.body.addEventListener("mouseup", this.processMouseUp);
        });
      }
    },
    getClampedXcord (xCord) {
      if (xCord < window.innerWidth >> 1) {
        xCord = window.innerWidth >> 1
      } else if (window.innerWidth - xCord < 250) {
        xCord = window.innerWidth - 250
      }
      return xCord
    },
    processMouseUp (event) {
      const diff = this.mouseStartX - this.getClampedXcord(event.pageX);
      this.assetSiderWidth = this.assetSiderStartWidth + diff;
      this.$refs.assetSiderTrigger.style.right = this.assetSiderWidth + "px";

      this.assetSiderStartWidth = null
      this.mouseStartX = null;
      document.querySelector('.cla-resize-bar').style.transform = "";
      document.querySelector('.cla-resize-bar-hov').style.backgroundColor = "initial";

      document.body.removeEventListener("mousemove", this.processMouseMove);
      document.body.removeEventListener("mouseup", this.processMouseUp);
    },
    processMouseMove (event) {
      if (this.mouseStartX == null) { return }

      const diff = this.getClampedXcord(event.pageX) - this.mouseStartX;
      document.querySelector('.cla-resize-bar').style.transform = `translateX(${diff}px)`;
    },
    processNewlyCreatedMediaPlan (data) {
      var mediaPlan = data.mediaPlan;
      var newMediaPlan = {
        Id: mediaPlan.id,
        Name: mediaPlan.name,
        Objective: mediaPlan.objectives.selected,
        Budget: mediaPlan.cost,
        StartDate: mediaPlan.schedule[0],
        EndDate: mediaPlan.schedule[1],
        LabelIds: mediaPlan.Label ? mediaPlan.Label.map(x => x.id).join(',') : ""
      };
      // this.selectedMediaPlan.Id = newMediaPlan.Id;
      // this.selectedMediaPlan.Objective = newMediaPlan.Objective;
      // this.selectedMediaPlan.Budget = newMediaPlan.Budget;
      // this.selectedMediaPlan.StartDate = newMediaPlan.StartDate;
      // this.selectedMediaPlan.EndDate = newMediaPlan.EndDate;
      this.mediaPlans.unshift(newMediaPlan);
      this.set_mediaPlans(this.mediaPlans);
    },
    resetSelectedconfigAndGetLineItems () {
      this.SET_BPSEID(0);
      this.SET_selectedFbObjective(null);
      this.set_selectedObjectiveIdForPublisher(null);
      this.set_selectedCampaignLauncherConfigId(0);
      this.set_showSpinner(true);
      this.getMediaPlanLineItems();
    },
    resetCampaignLauncherState () {
      this.SET_SHOW_PREVIEW(false);
      this.SET_BPSEID(0);
      this.SET_selectedFbObjective(null);
      this.set_selectedObjectiveIdForPublisher(null);
      this.set_selectedCampaignLauncherConfigId(0);
      this.SET_FACEBOOK_ENTITIES([]);
      this.set_publishedCreatives({});
    },
    async processRoute (to, processQueryParams = false) {
      this.promoAlert = { show: false, message: '' };
      this.isRouteProcessed = false;
      this.set_isPromotionMode(false);

      if (to.params.submissionId) {
        this.submissionId = parseInt(to.params.submissionId);
        this.SET_SHOW_PREVIEW(true);
        this.$store.commit("set_displayExistingSubmissions", true)
      } else if (to.path == "/new" || to.path == "/summary") {
        this.submissionId = 0;
        if (processQueryParams) {
          var self = this;
          this.resetCampaignLauncherState();
          if (to.query) {
            if (to.query.isPromotionCampaignLaunch) {
              var isPromotionCampaignLaunch = Boolean(to.query.isPromotionCampaignLaunch);
              this.set_isPromotionCampaignLaunch(isPromotionCampaignLaunch);
            }
            if (to.query.publisher) {
              var publisherId = parseInt(to.query.publisher);
              if (isNaN(publisherId)) {
                publisherId = this.defaultPublisher;
              } else {
                var publisher = this.getPublisherNameById(publisherId);
                if (publisher.length == 0) {
                  publisherId = this.defaultPublisher;
                }
              }
              EventBus.$emit(
                "campaignLauncherApp-updatePublisherIdFromUrl",
                publisherId
              );
            } else {
              EventBus.$emit(
                "campaignLauncherApp-updatePublisherIdFromUrl",
                this.defaultPublisher
              );
            }

            if (to.query.mediaPlan) {
              var mediaPlanId = parseInt(to.query.mediaPlan);
              var mediaPlanExists = this.mediaPlans.find(mediaPlan => mediaPlan.Id === mediaPlanId);
              if (!mediaPlanExists) {
                let mediaPlan = await APIService.getMediaPlanById(mediaPlanId);
                mediaPlan = this.setMediaPlanProperties(mediaPlan);
                this.mediaPlans = this.mediaPlans.concat(mediaPlan);
                this.set_mediaPlans(this.mediaPlans);
              }
              if (isNaN(mediaPlanId)) {
                mediaPlanId = 0;
              }
              var mediaPlan = this.getMediaPlanById(mediaPlanId)
              if (mediaPlanId > 0 && mediaPlan.Id > 0) {
                EventBus.$emit("campaignLauncherApp-updateMediaPlanIdFromUrl", mediaPlan)
                if (to.query.config) {
                  let configId = parseInt(to.query.config);
                  let configExists = this.campaignLauncherConfigurations.find(config => config.Id === configId);
                  if (!configExists) {
                    let config = await APIService.getConfigById(configId);
                    let campaignLauncherConfig = this.setConfigProperties(config);
                    let campaignLauncherConfigsCopy = JSON.parse(JSON.stringify(this.campaignLauncherConfigs));
                    campaignLauncherConfigsCopy[config.Id] = campaignLauncherConfig;
                    this.set_campaignLauncherConfigs(campaignLauncherConfigsCopy);
                    let clMapper = JSON.parse(JSON.stringify(this.campaignLauncherConfigBpseIdMapper));
                    clMapper[config.Id] = { BpseId: config.BpseId };
                    this.set_campaignLauncherConfigBpseIdMapper(clMapper);
                    let configList = [...this.campaignLauncherConfigurations, config];
                    this.set_campaignLauncherConfigurations(configList);
                  }
                  EventBus.$on("launchSettings-mappedConfigsLoaded", function (selectedConfigs) {
                    if (self.processedConfigParameterOnce) {
                      return;
                    }
                    self.processedConfigParameterOnce = true;
                    var configId = parseInt(to.query.config);
                    if (isNaN(configId)) {
                      return;
                    }
                    var config = selectedConfigs.filter(function (e) {
                      return e.Id == configId;
                    });
                    if (config && config.length != 1) {
                      return;
                    }
                    var autoNavigateToNextStep = false;
                    if (to.path == "/summary") {
                      self.set_autoNavigateToNextStep(true);
                    }
                    EventBus.$emit("campaignLauncherApp-updateConfigIdFromUrl", config[0].Id)
                  });
                }
              } else {
                EventBus.$emit("campaignLauncherApp-updateMediaPlanIdFromUrl", { Id: undefined, Name: "" })
              }
            }

            if (to.query.view && to.query.view == 'Full') {
              this.viewType = "Full setup";
            }
          }
        }
        this.$store.commit("set_displayExistingSubmissions", false)
      } else if (to.path == "/submissions") {
        this.resetCampaignLauncherState();
        this.$nextTick(function () {
          if (!this.authFlowExists) {
            this.alert.type = "warning";
            this.alert.message = `Authorization Flow is not enabled. <a href='/App/Settings/List/${this.deltax.businessProfileId}'>Click here</a> to enable authorization`;
            this.alert.show = true;
            this.alert.isBlocking = true;
          }
        })
        this.SET_SHOW_PREVIEW(true);
        this.$store.commit("set_displayExistingSubmissions", true)
      } else if (to.params.promotionId || window.promotionId > 0) {
        Bugsnag.leaveBreadcrumb('Setting up promotion mode flow');
        this.$store.commit("SET_SHOW_PREVIEW", false);
        this.set_isCreationFlow(false);
        var promoId = to.params.promotionId ? to.params.promotionId : parseInt(window.promotionId)
        this.set_promotionId(promoId);
        try {
          await this.initializePromotion(promoId);
        } catch (error) {
          Bugsnag.notify(error);
          this.showPromoAlert();
        }
      } else {
        this.submissionId = 0;
        this.SET_SHOW_PREVIEW(false);
        this.$store.commit("set_displayExistingSubmissions", false)
      }
      if (window.deltax.AuthorizationFlow != null && this.submissionId == 0) {
        var authFlowList = window.deltax.AuthorizationFlow
        var parsedAuthFlows = []
        authFlowList.forEach(authFlow => {
          parsedAuthFlows.push(JSON.parse(authFlow))
        })
        this.setAuthFlows(parsedAuthFlows);
      }

      if (!this.isPromotionMode) {
        this.set_campaignLauncherConfigurations(
          this.campaignLauncherConfigurations.filter(config => !config.IsPromotion)
        );
      }

      if (this.promoAlert.show == false) {
        this.isRouteProcessed = true;
      }
    },
    async initializePromotion (promotionId) {
      let promotionResponse = null;
      let replaceMacrosResponse = null;
      Bugsnag.leaveBreadcrumb('Fetching required data for promotion launch flow');
      try {
        promotionResponse = await APIService.getPromotionById(promotionId);
        let latestPromoVersion = await APIService.getLatestPromotionVersion(promotionId);
        if (latestPromoVersion && latestPromoVersion.creativeJson) {
          promotionResponse.data.data.creativeJson = JSON.parse(latestPromoVersion.creativeJson);
        }
        if (latestPromoVersion && latestPromoVersion.settings) {
          promotionResponse.data.data.settings = JSON.parse(latestPromoVersion.settings)
        }
      } catch (err) {
        Bugsnag.notify(err);
        this.promoAlert.show = true;
        this.promoAlert.message = `A promotion with Id ${promotionId} was not found`;
        return;
      }
      if (promotionResponse && promotionResponse.data.data && promotionResponse.data.data.settings.publishers && promotionResponse.data.data.settings.publishers.length) {
        let currentStep = parseInt(window.location.href.charAt(window.location.href.length - 1)) - 1
        this.publishersSteps = promotionResponse.data.data.settings.publishers.sort(function (a, b) { return b - a });
        if (this.publishersSteps && (currentStep > 0 && currentStep <= this.publishersSteps.length)) {
          this.set_selectedPublisher(this.publishersSteps[currentStep - 1])
        }
      }
      try {
        replaceMacrosResponse = await APIService.replacePromotionMacros(
          promotionId,
          null,
          null,
          this.selectedPublisher
        );
      } catch (err) {
        Bugsnag.notify(err);
        this.promoAlert.show = true;
        this.promoAlert.message = `There was a problem while fetching store configuration`;
        return;
      }
      try {
        let productsResponse = await PromotionApiService.getProducts()
        this.set_products(
          productsResponse.data.data.products.reduce((productsObj, product) => {
            productsObj[product.id] = product;
            return productsObj
          }, {})
        );
      } catch (err) {
        Bugsnag.notify(err);
        this.promoAlert.show = true;
        this.promoAlert.message = `There was a problem while fetching products`;
        return;
      }
      Bugsnag.leaveBreadcrumb('Processing data for promotion launch flow');
      let promotionObj = promotionResponse.data.data;
      let replaceMacrosObj = replaceMacrosResponse.data.data;

      let clcConfigObj = JSON.parse(replaceMacrosObj.clcConfigJson)
      switch (clcConfigObj.searchEngineTypeId) {
        case this.publisher.Adwords:
          promotionObj.campaignLauncherConfiguration[clcConfigObj.searchEngineTypeId].configJson = this.handleOldAdwordsConfig(
            clcConfigObj
          );
          break;
        case this.publisher.Facebook:
          promotionObj.campaignLauncherConfiguration[clcConfigObj.searchEngineTypeId].configJson = this.handleOldFacebookConfigs(
            clcConfigObj
          );
          break;
      }
      if (replaceMacrosObj.creativeJson == "") {
        promotionObj.creativeJson = {
          facebook: {
            facebookSpec: "{}",
            handsonSpec: "{}"
          },
          google: {}
        };
      }
      if (promotionObj.settings.organicPromotionId) {
        this.$refs["adSheetWidget"].changeTab("Existing Posts");
      }
      promotionObj.replacedMacroTargets = replaceMacrosObj.targeting;

      if (!promotionObj.settings.schedule.endDate) {
        promotionObj.settings.schedule.endDate = "";
      }

      this.set_promotionObject(promotionObj);
      switch (clcConfigObj.searchEngineTypeId) {
        case this.publisher.Adwords:
          this.setPromotionPropertiesForAdwords(promotionObj, clcConfigObj)
          break;
        case this.publisher.Facebook:
          this.setPromotionPropertiesForFacebook(promotionObj, clcConfigObj)
          break;
      }
      const configObj = promotionObj.campaignLauncherConfiguration[clcConfigObj.searchEngineTypeId];
      this.campaignLauncherConfigs[configObj.id] = {
        config: JSON.stringify(configObj.configJson),
        searchEngineTypeId: configObj.configJson.searchEngineTypeId,
        isPromotion: true
      };

      let promoBudget = this.promotionObject.settings.budget.suggested;
      if (this.promotionObject.settings.budgetSplit && Object.keys(this.promotionObject.settings.budgetSplit).length) {
        switch (this.selectedPublisher) {
          case this.publisher.Facebook:
            promoBudget = promoBudget * (this.promotionObject.settings.budgetSplit.facebookBudget / 100)
            break;
          case this.publisher.Adwords:
            promoBudget = promoBudget * (this.promotionObject.settings.budgetSplit.googleBudget / 100)
            break;
        }
      }
      let start = moment(this.promotionObject.settings.schedule.startDate).format("YYYY-MM-DD");
      let end = moment(this.promotionObject.settings.schedule.endDate).format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");
      let actualStartDate = moment.max(moment(today), moment(start)).format("YYYY-MM-DD");
      if (end == "Invalid date") {
        end = moment(actualStartDate).add(29, 'd').format("YYYY-MM-DD");
      }
      if (this.promotionObject.settings.budget.type == 1) {
        promoBudget *= moment(end).diff(moment(actualStartDate), "days") + 1;
      }
      let defaultStore = this.dbStores.find(store => store.isDefault);
      let promoTargetClusterIds = this.promotionObject.settings.targeting.clusterIds;
      let promoTargetClusters = await APIService.getTargetClusters(promoTargetClusterIds.join(','))
      this.set_promoTargetClusters(promoTargetClusters);
      const generatedLineItem = {
        isLineItemMapped: true,
        id: "0",
        name: "promo line item",
        fractionId: 0,
        fractionAlias: "-",
        config: this.promotionObject.campaignLauncherConfiguration[clcConfigObj.searchEngineTypeId].id,
        targets: Array.from(new Set([...this.promotionObject.replacedMacroTargets.targetIds])),
        targetsInfo: [],
        locations: defaultStore ? [defaultStore.storeCode] : [],
        labels: [],
        labelsInfo: [],
        budget: promoBudget,
        isPublished: false,
        startDate: start,
        endDate: end,
        reachAndFrequencyPredictionId: "0",
        reachAndFrequencyPredictionReservationId: "",
        isReachAndFrequencyConfig: false,
        lineItemRows: 1
      };
      this.set_mappedLineItems([generatedLineItem]);

      this.set_isPromotionMode(true);
    },
    handleOldDV360Config (config) {
      const adGroup = {
        type: 1,
        adFormat: 1,
        maxCPV: 1,
        videoBidAdjustment: 0,
        name: "",
        conversionTracking: [
          {
            floodlightActivityId: "",
            postClickWindow: 129600,
            postViewWindow: 129600
          }
        ]
      };
      if ("adGroup" in config) {
        return config;
      }
      if ("lineItem" in config) {
        const lineItem = config.lineItem;
        const bidStratedy = config.lineItem.bidStrategy;
        adGroup.type = lineItem.type;
        adGroup.name = lineItem.adGroup.name;
        adGroup.adFormat = lineItem.adFormat;
        adGroup.maxCPV = bidStratedy.maxCPV;
        adGroup.videoBidAdjustment = bidStratedy.videoBidAdjustment;
        delete config.lineItem
      }
      config.adGroup = adGroup;
      return config;
    },
    handleOldAdwordsConfig (config) {
      if (config.campaign.type === "Search") {
        config.campaign.type = 1
      }
      config.campaign.subtype = config.campaign.subtype || 1
      config.campaign.locations = config.campaign.locations || {}
      config.campaign.targetBidAmount = config.campaign.targetBidAmount || null;
      config.campaign.isMaxCPClimitSet = config.campaign.isMaxCPClimitSet || false;
      config.campaign.maxCpcLimit = config.campaign.maxCpcLimit || null;
      config.campaign.targetImpression = config.campaign.targetImpression || null;
      config.campaign.adLocation = config.campaign.adLocation || 2;
      return config
    },
    async getPublisherBpse () {
      let accounts = this.getBatchApiResponse(this.batchResponseData, 0, "getPublisherBpse").data;
      accounts.forEach(x => {
        x.Id = x.id
        x.NickName = x.accountNickName
        x.AccountId = x.accountId
        x.AccountAssets = x.accountAssets
        x.IsOauthExpired = x.isOauthExpired
        x.FacebookBusinessId = x.facebookBusinessId
        x.CampaignLaunchMacros = x.campaignLaunchMacros
        x.TestMode = x.testMode
      });
      this.publisherResponse.accountsList = accounts
    },
    async getPublisherConfigs () {
      let configs = this.getBatchApiResponse(this.batchResponseData, 3, "getPublisherConfigs").data;
      configs.forEach(x => {
        x.Id = x.id
        x.BpseId = x.businessProfileSearchEngineId
        x.Name = x.name
        x.UserId = x.userId
        x.CreatedAt = x.createdAt
        x.UpdatedAt = x.updatedAt
        x.ConfigJson = x.configJson
        x.UpdatedByUserId = x.updatedByUserId
        x.Status = x.status
        x.IsPromotion = x.isPromotion
      });
      this.publisherResponse.campaignLauncherConfigurations = configs
    },
    async getPublisherTargets () {
      let targets = this.getBatchApiResponse(this.batchResponseData, 1, "getPublisherTargets").data;
      targets.forEach(x => {
        x.Id = x.id
        x.BpseId = x.bpseId
        x.Name = x.name
        x.EstimatedReach = x.estimatedReach || 0
        x.Type = x.type
        x.CreationType = x.creationType
        x.TargetInformation = x.targetInformation
        x.businessProfilesSearchEngineId = x.bpseId
      });
      this.publisherResponse.savedTargets = targets
    },
    async getPublisherLabels () {
      let labels = this.getBatchApiResponse(this.batchResponseData, 4, "getPublisherLabels").result;
      labels.forEach(x => {
        x.Id = x.id
        x.LabelTypeId = x.labelTypeId
        x.Name = x.name
      });
      this.publisherResponse.labels = labels
    },
    async getPublisherEmails () {
      let inboundEmails = this.getBatchApiResponse(this.batchResponseData, 5, "getPublisherEmails").data;
      inboundEmails.forEach(x => {
        x.Id = x.id
        x.Sender = x.sender
        x.Name = x.name
        x.CreatedAt = x.createdAt
        x.Images = x.images.map(imageObject => this.convertKeysToPascalCase(imageObject))
      });

      this.publisherResponse.groupedEmails = inboundEmails
    },
    async getPublisherBp () {
      this.publisherResponse.bp = this.getBatchApiResponse(this.batchResponseData, 2, "getPublisherBp").data;
      this.set_businessProfile(this.publisherResponse.bp);
    },
    async getPublisherData (isPromoLocalFlag) {
      var batchApiUrls = [
        {
          "method": "GET",
          "url": `${this.deltax.apiEndPoint}businessProfiles/${this.businessProfileId}/Accounts?searchEngineTypeId=5`
        },
        {
          "method": "GET",
          "url": `${this.deltax.apiEndPoint}businessProfiles/${this.businessProfileId}/Targets/ProcessedTargets`
        },
        {
          "method": "GET",
          "url": `${this.deltax.apiEndPoint}businessProfiles/${this.businessProfileId}`
        },
        {
          "method": "GET",
          "url": `${this.deltax.apiEndPoint}businessProfiles/${this.businessProfileId}/CampaignLauncherConfigurations`
        },
        {
          "method": "GET",
          "url": `${this.deltax.apiEndPoint}businessProfiles/${this.businessProfileId}/Labels`
        },
        {
          "method": "GET",
          "url": `${this.deltax.apiEndPoint}businessProfiles/${this.businessProfileId}/InboundEmails`
        }
      ]

      if (isPromoLocalFlag) {
        batchApiUrls.splice(3)
      }
      this.initializeBatchRequests(batchApiUrls)
      await GlobalAPIService.batchApiRequests(batchApiUrls)
        .then(batchResponse => {
          this.batchResponseData = batchResponse.data.data;
          this.getPublisherBpse();
          this.getPublisherTargets();
          this.getPublisherBp();
          if (!isPromoLocalFlag) {
            this.getPublisherConfigs();
            this.getPublisherLabels();
            this.getPublisherEmails();
          }
        }).catch(error => {
          console.log(error)
          throw error
        });

      let currency = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/Currencies/" + this.publisherResponse.bp.currencyId
      )
      currency = currency.data.data;
      this.publisherResponse.publishedCampaignsCount = "[]"
      this.publisherResponse.s3InboundMailRecipientDomain = this.deltaxConfig.s3InboundMailRecipientDomain
      this.publisherResponse.bpCurrencySymbol = currency.symbol
    },
    async getPublisherAccounts (isPromoLocalFlag) {
      await this.getPublisherData(isPromoLocalFlag)
      let response = this.publisherResponse
      let accountAssetsMapper = {};
      let savedTargetsMapper = {};
      let campaignLauncherMapper = {};
      let campaignLauncherConfigs = {};
      let accounts = response.accountsList;
      let campaignLauncherConfigurations =
        response.campaignLauncherConfigurations;
      let savedTargets = response.savedTargets;
      let bpCurrencySymbol = response.bpCurrencySymbol
      let publishedCampaignsCount = JSON.parse(response.publishedCampaignsCount);
      let publishedCampaignsCountByMediaPlan = publishedCampaignsCount.reduce((obj, item) => {
        let [configId, mediaPlanId, publishedCampaignsCount] = item
        obj[mediaPlanId] = obj[mediaPlanId] || {}
        obj[mediaPlanId][configId] = publishedCampaignsCount
        return obj
      }, {})
      let dxInboundEmails = response.groupedEmails || []
      let s3InboundMailRecipientDomain = response.s3InboundMailRecipientDomain;
      let accountMacros = {}
      if (isPromoLocalFlag) {
        // accounts = []
        // savedTargets = []
        campaignLauncherConfigurations = []
        this.set_labels([]);
      } else {
        this.set_labels(response.labels);
      }
      accounts.forEach(element => {
        let assets =
          element.AccountAssets == "" || element.AccountAssets == null
            ? "{}"
            : element.AccountAssets;
        accountAssetsMapper[element.Id] = {
          assets: JSON.parse(assets),
          accountId: element.AccountId,
          businessId: element.FacebookBusinessId
        };
        accountMacros[element.Id] = JSON.parse(element.CampaignLaunchMacros || null)
      });
      savedTargets.forEach(element => {
        savedTargetsMapper[element.BpseId] =
          savedTargetsMapper[element.BpseId] || [];
        savedTargetsMapper[element.BpseId].push(element);
      });
      this.set_savedTargets(savedTargets);
      campaignLauncherConfigurations.forEach(element => {
        if (!(element.BpseId in accountAssetsMapper)) { // for adWords
          accountAssetsMapper[element.BpseId] = { assets: {}, accountId: "AccountId", businessId: null };
        }
        let isAssetClaimRequired = JSON.stringify(accountAssetsMapper[element.BpseId].assets) === "{}";
        element.isAssetClaimRequired = isAssetClaimRequired;
        element.AccountId = accountAssetsMapper[element.BpseId].accountId;
        if (element.promotionId == null) {
          if (isPromoLocalFlag) {
            return;
          }
          let config = JSON.parse(element.ConfigJson);
          if (!("searchEngineTypeId" in config)) {
            config.searchEngineTypeId = 5;
          }
          if (config.searchEngineTypeId == 5) {
            config = this.handleOldFacebookConfigs(config)
          } else if (config.searchEngineTypeId == 1) {
            config = this.handleOldAdwordsConfig(config)
          } else if (config.searchEngineTypeId == 9) {
            config = this.handleOldDV360Config(config)
          }
          element.searchEngineTypeId = config.searchEngineTypeId;
          campaignLauncherConfigs[element.Id] = {
            config: JSON.stringify(config),
            searchEngineTypeId: config.searchEngineTypeId,
            isPromotion: false
          };
        } else {
          let config = JSON.parse(element.ConfigJson);
          element.searchEngineTypeId = config.searchEngineTypeId;
          campaignLauncherConfigs[element.Id] = {
            config: element.ConfigJson,
            searchEngineTypeId: config.searchEngineTypeId,
            isPromotion: true
          };
        }
        campaignLauncherMapper[element.Id] = { BpseId: element.BpseId };
      });
      this.set_accounts(accounts);
      this.set_s3InboundMailRecipientDomain(s3InboundMailRecipientDomain);
      this.set_campaignLauncherConfigurations(campaignLauncherConfigurations);
      this.set_accountAssetsMapper(accountAssetsMapper);
      this.set_accountMacros({
        ...this.accountMacros,
        ...{ [this.publisher.Facebook]: accountMacros }
      });
      this.set_savedTargetsMapperByBpseId(savedTargetsMapper);
      this.set_campaignLauncherConfigBpseIdMapper(campaignLauncherMapper);
      this.set_campaignLauncherConfigs(campaignLauncherConfigs);
      this.set_publishedCampaignsCountByMediaPlan(publishedCampaignsCountByMediaPlan)
      this.set_dxInboundEmails(dxInboundEmails);
      this.set_bpCurrencySymbol(bpCurrencySymbol)
      // this.getSavedTargets();
      this.setCampaignLauncherVerificationFlag();
      if (this.deltax.mediaPlanId > 0) {
        let selectedMediaPlan = this.mediaPlans.find(e => e.Id == this.deltax.mediaPlanId);
        if (selectedMediaPlan) {
          this.selectedMediaPlan.Name = selectedMediaPlan.Name;
          this.$nextTick(function () {
            this.selectedMediaPlan.Id = this.deltax.mediaPlanId;
          })
        } else {
          this.alert.message = "This media plan cannot be used to create campaigns";
          this.alert.type = "info";
          this.alert.show = true;
        }
      }
    },
    async getGoogleAdWordsData () {
      let response = await APIService.get(
        "/App/CampaignLauncher/GetGoogleAdWordsData/" +
          this.businessProfileId
      )

      let googleAccounts = response.googleAccountsList;
      let googleLanguages = JSON.parse(response.languages)
      this.set_googleAccounts(googleAccounts);
      this.set_googleLanguages(googleLanguages)
    },
    setEngineAccounts (accounts) {
      let self = this
      let amsAccounts = accounts.filter(function (account) {
        return account.searchEngineTypeId === self.publisher.AMS.id
      });
      let dbmAccounts = accounts.filter(function (account) {
        return account.searchEngineTypeId === self.publisher.DV360 && account.accountId !== "Dbm"
      });
      let linkedInAccounts = accounts.filter(function (account) {
        return account.searchEngineTypeId === self.publisher.LinkedIn
      });
      let twitterAccounts = accounts.filter(function (account) {
        return account.searchEngineTypeId === self.publisher.Twitter
      });
      let molocoAccounts = accounts.filter(function (account) {
        return account.searchEngineTypeId === self.publisher.Moloco
      });
      this.set_amsAccounts(amsAccounts);
      this.set_linkedInAccounts(linkedInAccounts);
      this.set_twitterAccounts(twitterAccounts);
      this.set_molocoAccounts(molocoAccounts)
      this.saveDV360Accounts(dbmAccounts)
    },
    saveDV360Accounts (dbmAccounts) {
      APIService.get(`/App/CampaignLauncher/GetAccounts/${this.businessProfileId}?engineId=9`)
        .then(response => {
          let isMccAccountObj = response.accountsList.reduce((obj, account) => {
            obj[account.Id] = account.UserName.toLowerCase().includes("mcc@deltax.com")
            return obj
          }, {});
          this.set_dbmAccounts(dbmAccounts.map(account => {
            let isMccAccount = isMccAccountObj[account.id];
            return {
              ...account,
              isMccAccount
            }
          }));
        });
    },
    async getAccounts () {
      let response = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/accounts"
      );

      let accounts = response.data.data
      this.setEngineAccounts(accounts);
    },
    // This will get all targets from the API Endpoint and save in set_savedTargets.
    // Need to make one call for all flows. ref DX-21960
    getSavedTargets () {
      let savedTargetsMapper = {};
      var isFacebookSavedAudienceEnabled = this.deltax.isSavedAudienceEnabled || false;
      APIService.getApiData(
        deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/targets?isSavedAudienceEnabled=" +
        isFacebookSavedAudienceEnabled
      ).then(response => {
        let savedTargets = response.data.data ? response.data.data : [];
        this.set_savedTargets(savedTargets);
        this.set_isRefreshAmsTargets(false);
      });
    },
    setCampaignLauncherVerificationFlag () {
      this.set_isCampaignLauncherVerificationEnabled(this.businessProfile.isCampaignLauncherVerificationEnabled);
    },
    getMediaPlanLineItems () {
      APIService.getMediaPlanItems(
        deltax.apiEndPoint +
        "businessProfiles/" +
        this.businessProfileId +
        "/MediaPlans/LineItems?mediaPlanIds=" +
        this.$store.state.mediaPlanId
      ).then(response => {
        let lineItems = response.data.data[0] ? response.data.data[0].lineItems : [];
        // let mediaPlanLineItems = []
        // lineItems.forEach(element => {
        //   mediaPlanLineItems.push({ id: element.id, name: element.name, config: "", targets: [] });
        // });
        // this.set_mediaPlanLineItems(mediaPlanLineItems);
        this.lineItemIds = lineItems.map(e => {
          return e.id;
        }).join();
        this.getMappedLineItems();
      });
    },
    async GetMappedLineItemsRequest () {
      this.mappedLineItemResponse = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/campaignLauncherConfigurations/GetMappedLineItems" +
          "?lineItems=" +
          this.lineItemIds +
          "&mediaPlanId=" +
          this.$store.state.mediaPlanId
      )
    },
    async GetPublishedCreatives () {
      this.publishedCreativeResponse = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/campaignLauncherConfigurations/GetPublishedCreatives" +
          "?mediaPlanId=" +
          this.$store.state.mediaPlanId
      )
    },
    async getMappedLineItems () {
      let mappedLineItemCalls = [this.GetMappedLineItemsRequest()];

      await Promise.all(mappedLineItemCalls);
      var response = this.mappedLineItemResponse

      let mappedLineItems = JSON.parse(response.data.mappedLineItems);
      let lineItemLabels = JSON.parse(response.data.lineItemLabels);
      lineItemLabels = lineItemLabels.reduce((accu, cur) => {
        var key = cur.LineItemId + "-" + cur.LineItemFractionId
        accu[key] = accu[key] || {
          labels: [],
          labelsInfo: []
        }
        accu[key].labels.push(cur.LabelId)
        accu[key].labelsInfo.push({ id: cur.LabelId, name: cur.Name })
        return accu
      }, {})

      var targetMappingNotRequired = !this.deltax.isAdwordsCampaignLaucherFlow && this.selectedPublisher == this.publisher.Adwords

      var locationsToFetch = [];
      const result = [
        ...mappedLineItems
          .reduce((r, o) => {
            var fractionId = o.LineItemFractionId;
            const key =
                o.LineItemId + "-" + fractionId + "-" + o.CampaignLauncherConfigurationId;

            const isConfigMapped = o.CampaignLauncherConfigurationId !== null;
            const isTargetsMapped = targetMappingNotRequired || o.SocialTargetId !== null;
            const isLineItemMapped = isConfigMapped && isTargetsMapped;
            const item = r.get(key) || {
              isLineItemMapped: isLineItemMapped,
              id: o.LineItemId,
              internalId: o.LineItemId,
              name: o.LineItemName,
              fractionId: fractionId,
              fractionAlias: o.LineItemFractionAlias,
              config: o.CampaignLauncherConfigurationId,
              targets: [],
              locations: [],
              targetsInfo: [],
              labels: [],
              labelsInfo: [],
              budget: o.LineItemBudget,
              isPublished: o.IsPublished || false,
              isTargetExported: o.IsTargetExported || 0,
              startDate: o.StartDate || "",
              endDate: o.EndDate || "",
              reachAndFrequencyData: [],
              isReachAndFrequencyConfig: o.IsReachAndFrequencyConfig || false,
              dpaSettings: o.DpaSettings && o.DpaSettings != "" ? JSON.parse(o.DpaSettings) : null,
              targetTypeId: o.TargetTypeId || 1,
              asins: o.Asins || "",
              keymetricId: o.KpiMetricId || "",
              keymetricValue: o.KpiMetricValue || "",
              budgetType: 1,
              scheduleDays: "",
              pageSetSettings: o.PageSetSettings ? JSON.parse(o.PageSetSettings) : null
            };
            if (o.SocialTargetId != null) {
              item.targetsInfo.push({ id: o.SocialTargetId, name: o.TargetName, IsExported: o.IsTargetExported, Asins: o.asins });
              item.targets.push(o.SocialTargetId);
              if (o.LocationsJson) {
                item.locations = JSON.parse(o.LocationsJson)
                if (this.selectedPublisher == this.publisher.Facebook) {
                  item.locations = item.locations.map(l => l.toString())
                }
                if (!this.isHyperLocalConfig(item.config)) {
                  locationsToFetch.push(...item.locations)
                }
              } else {
                item.locations = []
              }
              if (o.IsReachAndFrequencyConfig) {
                item.reachAndFrequencyData.push({
                  targetId: o.SocialTargetId,
                  reachAndFrequencyPredictionId: o.ReachAndFrequencyPredictionId || "",
                  reachAndFrequencyPredictionReservationId: o.ReachAndFrequencyPredictionReservationId || "",
                  reachAndFrequencySettings: o.ReachAndFrequencySettings && o.ReachAndFrequencySettings != "" ? JSON.parse(o.ReachAndFrequencySettings) : null
                });
              }
            }
            if (o.LineItemRowCount == 1) {
              if (o.LineItemFractionId == 0 && o.FractionsCount > 0) {
                item.lineItemRows = o.FractionsCount + 1;
              } else {
                item.lineItemRows = o.FractionsCount == 0 ? 1 : o.FractionsCount;
              }
            }
            return r.set(key, item);
          }, new Map())
          .values()
      ];
      if (this.selectedPublisher == this.publisher.Adwords && !this.$store.state.dbStores) {
        let response = await APIService.getStores();
        if (response.data.data) {
          let dbStores = response.data.data.filter(x => x.name);
          await this.$store.commit("set_dbStores", dbStores);
          await this.getLabelsTypesById();
        }
      }
      if (this.fetchLocations && locationsToFetch && locationsToFetch.length > 0) {
        var params = {}
        if (this.selectedPublisher == this.publisher.Adwords) {
          locationsToFetch = locationsToFetch.filter(l => /^\d+$/.test(l));
        }
        params[this.locationKey] = [...new Set(locationsToFetch)].join(',')
        var fetchedLocations = await APIService.getDbLocations(params)
        this.setInitialLocations(fetchedLocations)
      }
      result.forEach(lineItem => {
        var key = lineItem.id + "-" + lineItem.fractionId
        if (key in lineItemLabels) {
          lineItem.labels = lineItemLabels[key].labels
          lineItem.labelsInfo = lineItemLabels[key].labelsInfo
        }
        if (lineItem.labels.length > 0) {
          lineItem.isLineItemMapped = true
        }
      });
      this.set_mediaPlanLineItems(result);
      this.set_showSpinner(false);
    },
    isHyperLocalConfig (configId) {
      var campaignConfig = this.campaignLauncherConfigurations.find(cc => cc.Id == configId);
      if (campaignConfig) {
        var config = JSON.parse(campaignConfig.ConfigJson)
        return config.searchEngineTypeId == this.publisher.Facebook && config.campaign.locationMode == locationMode.Hyperlocal
      }
      return false;
    },
    async getNomenclatureSettings () {
      let response = await APIService.getNomenclatureSettings();
      this.set_nomenclatureSettings(response.data.data);

      let channelMacros = this.getFacebookNomenclature;
      let labelDimensionNames = [];
      if (channelMacros && channelMacros.Ad) {
        let labelDimensionRegex = /{{LabelDimension\.(.*?)}}/g;
        let matches = channelMacros.Ad.matchAll(labelDimensionRegex);
        labelDimensionNames = [...matches].map(x => x[1]);
      }

      let labelDimensionsResponse = await APIService.getLabelDimensions();
      let labelDimensions = labelDimensionsResponse.data.result.reduce((obj, ld) => {
        ld.labels = [];
        obj[ld.id] = ld;
        return obj;
      }, {});

      let ldNomenclature = labelDimensionsResponse.data.result
        .filter((ld) => {
          let ldName = ld.name.replace(/\s/g, "");
          return labelDimensionNames.includes(ldName);
        })
        .map((ld) => ld.id);
      this.set_adLabelDimensionNomenclature(ldNomenclature);

      this.labels.forEach(label => {
        if (!labelDimensions[label.LabelTypeId]) { return }

        labelDimensions[label.LabelTypeId].labels.push(label.Name);
      })

      /**
       * labelDimensions = {
       *   "LabelDimensionId": {
       *     "name": "label dimension name",
       *     "labels": ["Label name 1", "label name 2" ...]
       *   }
       * }
       * key = label dimension id, value = label dimension object with mapped labels
       */
      this.set_labelDimensions(
        labelDimensions
      );
    },
    submissionChanged: function (submission) {
      // this.submissionId = submission.Id;
      if (submission.Id > 0 && submission.AuthorizationFlow) {
        this.setAuthFlows([JSON.parse(submission.AuthorizationFlow)]);
      } else {
        this.setAuthFlows([this.getFlowForSelectedPublisher]);
      }
    },
    readyToViewChanged: function (value) {
      this.readyToView = value;
    },
    setUserApproval: async function ({ approval, comment }) {
      await this.setUserFlowApproval({ index: 0, userId: window.currentUserId, approval, comment });
      this.$refs.bulkExport.updateSubmission();
    },
    loadSubmission: function (id) {
      APIService.getSubmission(id).then(e => {
        e = e.data
        let submission = JSON.parse(e.submission);
        let campaignLauncherConfigurationId = submission.CampaignLauncherConfigurationId
        this.submissionChanged(submission);
        this.SET_BPSEID(this.campaignLauncherConfigBpseIdMapper[campaignLauncherConfigurationId].BpseId);
        this.set_selectedCampaignLauncherConfigId(campaignLauncherConfigurationId);
        this.SET_FACEBOOK_ENTITIES(JSON.parse(submission.PublishJson));
        this.SET_SHOW_PREVIEW(true);
        if (submission.SearchEngineTypeId == this.publisher.Facebook) {
          APIService.setLivePublishStatus(this.requestId, submission.PublishJson)
        }
        // if (this.publishedCreativesByCampaignConfigId[campaignLauncherConfigurationId]) {
        //   let creativeJson = this.publishedCreativesByCampaignConfigId[campaignLauncherConfigurationId]
        //     .creativeJson;
        //   this.set_publishedCreatives(JSON.parse(submission.CreativeJson));
        // } else {
        //   this.set_publishedCreatives({});
        // }
        // this.set_selectedCampaignLauncherConfigId(submission.CampaignLauncherConfigurationId);
      })
    },
    showSubmissions () {
      this.$store.commit("set_displayExistingSubmissions", true);
      this.SET_FACEBOOK_ENTITIES([]);
      this.$router.replace({ path: `/submissions` });
    },
    hideSubmissions () {
      this.resetEntitiesResponse();
      this.$router.replace({ path: `/new` });
      this.$store.commit("set_displayExistingSubmissions", false);
      this.resetCampaignLauncherState();
      this.submissionId = 0;
      this.selectedMediaPlan.Id = 0;
    },
    rnfSuggestion () {
      if (this.selectedMediaPlan.Id != 0 && this.selectedCampaignLauncherConfigId != 0) {
        let config = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config)
        let isMappedToLineItem = this.mediaPlanLineItems.filter(x => x.config == this.selectedCampaignLauncherConfigId)
        if (config.campaign && config.campaign.buyType == 4 && isMappedToLineItem.length > 0) {
          this.isRnfConfig = true
        } else {
          this.isRnfConfig = false
        }
      } else {
        this.isRnfConfig = false
      }
    },
    handleWindowScroll () {
      if (this.selectedPublisher == 5) {
        let optionsContainer = document.getElementById('asset-sider-trigger')
        if (optionsContainer && optionsContainer.style) {
          if (this.isWizardFlow) {
            if (typeof window.scrollY === "undefined" ? window.pageYOffset : window.scrollY > 80) {
              optionsContainer.style.top = '151px'
            } else {
              optionsContainer.style.top = '210px'
            }
          } else {
            if (typeof window.scrollY === "undefined" ? window.pageYOffset : window.scrollY > 80) {
              optionsContainer.style.top = '48px'
            } else {
              optionsContainer.style.top = '118px'
            }
          }
        }
      }
    },
    toggleAssetSider () {
      if (this.assetSider.show) {
        this.$refs.assetSiderTrigger.style.right = this.assetSiderWidth + "px";
      } else {
        this.$refs.assetSiderTrigger.style.right = "0px"
      }
      this.$refs.assetSider.toggleCollapse();
    },
    toggleInstantExperienceWidget (tab) {
      this.instantExperiencesWidget.show = this.selectedFbObjective && tab == "Collection";
    },
    showPromoAlert () {
      this.promoAlert.show = true;
      this.promoAlert.message = `Something went wrong while processing your request.`;
    },
    async getAuthorizationFlow () {
      let response = await APIService.getApiData(
        this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/authorizationflow"
      );
      let data = response.data.data;
      this.$set(this.authorizationFlowByEngineType, [this.engineTypes.Social], data.find(x => x.name === this.getKeyByValue(this.engineTypes, this.engineTypes.Social)))
      this.$set(this.authorizationFlowByEngineType, [this.engineTypes.Search], data.find(x => x.name === this.getKeyByValue(this.engineTypes, this.engineTypes.Search)))
      this.$set(this.authorizationFlowByEngineType, [this.engineTypes.AMS], data.find(x => x.name === this.getKeyByValue(this.engineTypes, this.engineTypes.AMS)))
    },
    getKeyByValue (object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    setPromotionPropertiesForFacebook (promotionObj, clcConfigObj) {
      this.set_publishedCreativesByCampaignConfigId({
        ...this.publishedCreativesByCampaignConfigId,
        [promotionObj.campaignLauncherConfiguration[clcConfigObj.searchEngineTypeId].id]: {
          creativeJson: promotionObj.creativeJson.facebook.handsonSpec
        }
      });
      let handsonSpec = JSON.parse(promotionObj.creativeJson.facebook.handsonSpec);
      if (handsonSpec["existing posts"] && handsonSpec["existing posts"].length > 0 && promotionObj.settings.organicPromotionId) {
        let post = {};
        post.pagePostId = handsonSpec["existing posts"][0]["HOT_Post ID"];
        post.title = "AD1";
        this.set_existingPostAds([post]);
      }
    },
    setPromotionPropertiesForAdwords (promotionObj, clcConfigObj) {
      this.set_publishedCreativesByCampaignConfigId({
        ...this.publishedCreativesByCampaignConfigId,
        [promotionObj.campaignLauncherConfiguration[clcConfigObj.searchEngineTypeId].id]: {
          creativeJson: promotionObj.creativeJson.google
        }
      });
    },
    setConfigProperties (config) {
      config.Id = config.id
      config.BpseId = config.businessProfileSearchEngineId
      config.Name = config.name
      config.UserId = config.userId
      config.CreatedAt = config.createdAt
      config.UpdatedAt = config.updatedAt
      config.ConfigJson = config.configJson
      config.UpdatedByUserId = config.updatedByUserId
      config.Status = config.status
      config.IsPromotion = config.isPromotion

      let clConfig = {}
      if (!(config.BpseId in this.accountAssetsMapper)) { // for adWords
        this.accountAssetsMapper[config.BpseId] = { assets: {}, accountId: "AccountId", businessId: null };
      }
      let isAssetClaimRequired = JSON.stringify(this.accountAssetsMapper[config.BpseId].assets) === "{}";
      config.isAssetClaimRequired = isAssetClaimRequired;
      config.AccountId = this.accountAssetsMapper[config.BpseId].accountId;
      if (config.promotionId == null) {
        let isPromoLocalFlag = this.$route ? (Boolean(this.$route.params.promotionId) || Boolean(window.promotionId)) : false;
        if (isPromoLocalFlag) {
          return;
        }
        let configJson = JSON.parse(config.ConfigJson);
        if (!("searchEngineTypeId" in configJson)) {
          configJson.searchEngineTypeId = 5;
        }
        if (configJson.searchEngineTypeId == 5) {
          configJson = this.handleOldFacebookConfigs(configJson)
        } else if (configJson.searchEngineTypeId == 1) {
          configJson = this.handleOldAdwordsConfig(configJson)
        } else if (configJson.searchEngineTypeId == 9) {
          configJson = this.handleOldDV360Config(configJson)
        }
        config.searchEngineTypeId = configJson.searchEngineTypeId;
        clConfig = {
          config: JSON.stringify(configJson),
          searchEngineTypeId: config.searchEngineTypeId,
          isPromotion: false
        };
      } else {
        config.searchEngineTypeId = 5;
        clConfig = {
          config: config.ConfigJson,
          searchEngineTypeId: 5,
          isPromotion: true
        };
      }
      return clConfig;
    },
    setMediaPlanProperties (mediaPlan) {
      if (mediaPlan.labels.length) {
        mediaPlan.LabelIds = mediaPlan.labels.map(label => label.id).join(',')
      }
      return this.convertKeysToPascalCase(mediaPlan);
    },
    changeViewType () {
      this.viewType = 'Changes'
    },
    resetEntitiesResponse () {
      this.SET_FACEBOOK_ENTITIES(null);
      this.set_selectedCampaignLauncherConfig({})
      this.set_amsEntitiesResponse({});
      this.set_dv360EntitiesResponse({});
      this.set_linkedInEntitiesResponse({});
      this.set_twitterEntitiesResponse({});
      this.set_adwordsEntitiesResponse({});
    }
  },
  computed: {
    ...mapState([
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "accounts",
      "accountAssetsMapper",
      "mediaPlanId",
      "savedTargetsMapperByBpseId",
      "campaignLauncherConfigBpseIdMapper",
      "mediaPlanLineItems",
      "businessProfilesSearchEngineId",
      "businessProfileId",
      "deltax",
      "accounts",
      "showNextStep",
      "facebookEntities",
      "authFlows",
      "displayExistingSubmissions",
      "isCreationFlow",
      "selectedCampaignLauncherConfigId",
      "campaignLauncherConfigurations",
      "campaignLauncherConfigs",
      "labels",
      "selectedFbObjective",
      "showCampaignLauncherAlert",
      "campaignLauncherAlertMessage",
      "showCampaignLauncherError",
      "campaignLauncherErrorMessage",
      "campaignLauncherAssetsWithError",
      "selectedPublisher",
      "publishedCreativesByCampaignConfigId",
      "isPromotionMode",
      "publisher",
      "accountMacros",
      "engineTypes",
      "mediaPlanConfigs",
      "autoNavigateToNextStep",
      "selectedMediaplanName",
      "configChangeLoading",
      "selectedObjectiveIdForPublisher",
      "labelDimensions",
      "promotionObject",
      "dbStores",
      "selectedCampaignLauncherConfig",
      "isRefreshAmsTargets",
      "isWizardFlow",
      "businessProfile",
      "publishersSupportingNewLauncherFlow"
    ]),
    ...mapGetters([
      "getFlowForFacebookBulkAdCreation",
      "getFlowForPublisher",
      "getAllUsersInFirstFlowUnApprovedFactions",
      "getIfSelectedConfigIsRnF",
      "getMediaPlanById",
      "getPublisherNameById",
      "getCampaignConfigById",
      "getEngineTypeFromPublisherId",
      "isPublisherSupportedForSubmission",
      "isFullIntegration",
      "getFacebookNomenclature"
    ]),
    displayedItems () {
      return this.campaignLauncherAssetsWithError.slice(0, this.displayFailedAdlimit)
    },
    processedCampaignLauncherErrorMessage: function () {
      if (!(this.campaignLauncherErrorMessage instanceof Array)) {
        return this.campaignLauncherErrorMessage;
      }
      return this.campaignLauncherErrorMessage.filter((_, i) => this.isShowMoreError ? true : i < 5).join("");
    },
    userCanApprove: function () {
      return this.getAllUsersInFirstFlowUnApprovedFactions.includes(window.currentUserId);
    },
    authFlowExists: function () {
      return this.getFlowForSelectedPublisher && !this.isPromotionMode;
    },
    isRNFWatch () {
      return [this.mediaPlanId, this.selectedCampaignLauncherConfigId].join()
    },
    assetSiderClass () {
      return !this.assetSider.show ? ["open-sider"] : ["collapsed-sider"]
    },
    showPagePosts () {
      if ((this.isPromotionMode || this.selectedMediaPlan.Id != 0) && this.selectedCampaignLauncherConfigId != 0 && this.selectedPublisher == this.publisher.Facebook) {
        return (
          this.$refs["adSheetWidget"] &&
          this.$refs["adSheetWidget"].selectedTab == "Existing Posts"
        );
      }
      return false;
    },
    showFacebookLeadForms () {
      return this.selectedPublisher == this.publisher.Facebook && this.selectedCampaignLauncherConfigId !== 0 && this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig) && !this.isPromotionMode;
    },
    showLinkedInLeadForms () {
      return this.selectedPublisher == this.publisher.LinkedIn && this.selectedCampaignLauncherConfigId !== 0 && this.selectedObjectiveIdForPublisher === 5 && !this.isPromotionMode;
    },
    showExistingTweets () {
      return this.selectedPublisher == this.publisher.Twitter && this.selectedCampaignLauncherConfigId !== 0 && !this.isPromotionMode;
    },
    getFlowForSelectedPublisher () {
      if (this.selectedPublisher) {
        var engineId = this.getEngineTypeFromPublisherId(this.selectedPublisher)
        var flow = this.getFlowForPublisher(engineId)
        if (!this.deltaxConfig.isAuthFlowDisabledForPortal && flow) {
          return flow;
        }
      }
      return null;
    },
    showLocationsWidget () {
      if (this.selectedPublisher == this.publisher.AMS.SponsoredProducts || this.selectedPublisher == this.publisher.Adwords) {
        return false
      }
      if (this.selectedCampaignLauncherConfigId != 0) {
        let config = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config)
        return !this.displayExistingSubmissions && this.selectedPublisher == 5 && (!this.isStoreVisitsObjective(this.selectedCampaignLauncherConfig) || config.adset.targetingType != targetingType.PageSets);
      }
      return true;
    },
    showProductSetsWidget () {
      if (this.selectedPublisher == this.publisher.Facebook) {
        let self = this
        let configDetails = this.campaignLauncherConfigurations.filter(e => {
          return e.Id == self.selectedCampaignLauncherConfigId
        });
        if (configDetails.length > 0) {
          let config = JSON.parse(configDetails[0].ConfigJson)
          if ((config.campaign.objective == objectives.Sales || config.campaign.objective == objectives['App Promotion']) && !config.campaign.productCatalog && !this.isPromotionMode) {
            return true;
          }
        }
      }
      return false;
    },
    fetchLocations () {
      return this.selectedPublisher == this.publisher.Facebook || (this.selectedPublisher == this.publisher.Adwords && this.deltax.isAdwordsCampaignLaucherFlow)
    },
    locationKey () {
      switch (this.selectedPublisher) {
        case this.publisher.Facebook:
          return "facebookKeys";
        case this.publisher.Adwords:
          return "googleCodes";
        default:
          return "";
      }
    },
    showNegativeKeywords () {
      return this.selectedPublisher == this.publisher.Adwords && this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign && this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType.Search
    },
    showAdExtensions () {
      return this.selectedPublisher == this.publisher.Adwords && this.selectedCampaignLauncherConfig && this.selectedCampaignLauncherConfig.campaign && (this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType.Search || this.selectedCampaignLauncherConfig.campaign.type == this.adwordsEnums.campaignType["Performance Max"])
    }
  }
};
</script>

<style scoped>
.customSelect {
  border: none;
  background: none;
  outline: none;
}
.customOption {
  background: #f8f8f8;
}
.cl-spinner {
  font-size: 3rem;
  margin-top: 2rem;
  line-height: 5rem;
  text-align: center;
}
.widget-card {
  height: 200px;
  width: 100%;
  background: #ffffff;
  margin-bottom: 15px;
}
</style>

<style>
.widget {
  margin-top: 10px;
}
.widget-header {
  padding-left: 10px;
  padding-right: 10px;
}
.widget-body {
  overflow-y: auto;
  height: 139px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 12px;
}
.ivu-message-success, .ivu-alert-success {
  color: #719E32 !important;
  background-color: #F4F9EC !important;
  border-color: #C6E29F !important;
  padding: 10px
}
.ivu-message-error, .ivu-alert-error {
  background-color: #F5D9DA !important;
  border-color: #E08C8F !important;
  color: #CC3F44 !important;
  padding: 10px !important;
}
.ivu-message-info, .ivu-alert-info {
  background-color: #ECF8FD !important;
  border-color: #A0DDF4 !important;
  color: #40BBEA !important;
  padding: 10px !important;
}
.ivu-message-warning, .ivu-alert-warning {
  color: #E6A700 !important;
  background-color: #FFF8E6 !important;
  border-color: #ffdc80 !important;
  padding: 10px !important;
}
.ivu-alert a {
  color: inherit !important;
  text-decoration: underline !important;
}
.ivu-alert .ivu-alert-desc {
  color: inherit !important;
  font-size: 13px !important;
}
.ivu-message-notice-content {
  padding: 0 !important;
}

.dx-tab-style .ivu-tabs-tab {
  font-size: 12px !important;
  color: #555 !important;
  border-radius: 0px !important;
}
.dx-tab-style .ivu-tabs-tab:hover {
  border-top: 2px solid var(--main-bg-color) !important;
}
.dx-tab-style .ivu-tabs-tab-active, .dx-tab-style .ivu-tabs-tab-focused {
  border-top: 2px solid var(--main-bg-color) !important;
  font-weight: bold !important;
}
.dx-tab-style .ivu-tabs-nav-container:focus .ivu-tabs-tab-focused {
  border-color: var(--main-bg-color) !important;
}
.toggle-submission-mode {
  float: right;
  margin-bottom: 0px;
  margin-top: -8px;
}
.ivu-alert-close .ivu-icon-ios-close {
  top: 2px !important
}
.panel {
  padding: 10px;
  padding-top:5px;
}
.ivu-layout-sider {
  background: #e9eaed !important;
}
.ivu-layout {
  background: #e9eaed !important;
}
#asset-sider-trigger {
  right: 0px;
  position:fixed;
  background: #f5f5f5;
  text-align: center;
  border-color: #ddd;
  border-style: solid;
  border-width: 1px 0px 1px 1px;
  height: 70px;
  cursor: pointer;
  z-index: 500;
  transition: all .3s;
}
.asset-sider-trigger-promotion {
  top: 210px;
}
.asset-sider-trigger-cl {
  top: 118px;
}
#asset-sider-text {
  -ms-transform: rotate(270deg); /* IE 9 */
  -webkit-transform: rotate(270deg); /* Safari 3-8 */
  transform: rotate(270deg);
  margin-top:15px
}
/* #asset-sider section.ps-container {
  height: 568px;
} */
.cla-resize-bar {
  bottom: 0px;
  left: -10px;
  position: absolute;
  top: 0px;
  transform: translateZ(0px);
  width: 20px;
  z-index: 1000;
  cursor: ew-resize;
  will-change: transform;
  text-align: center;
}
.cla-resize-bar-hov {
  display: inline-block;
  height: 100%;
  left: 3px;
  transition: background-color 200ms ease 0s;
  width: 2px;
}
.cla-resize-bar:hover > .cla-resize-bar-hov {
  background-color: rgb(76, 154, 255) !important;
}
.open-sider {
  display: block;
  padding-right: 15px;
}
.collapsed-sider {
  display: none;
}
.scroll-area {
  position: relative;
  margin: auto;
  height: 670px;
}
.action-icon {
  cursor: pointer;
  color: #aaa;
}
.action-icon:hover {
  color: #5a5d5f;
}
.action-icon:active {
  transform: translateY(1px);
}
.cl-widget {
  margin-bottom: 10px;
}
.cl-widget .ivu-card-head {
  padding-bottom: 0px !important;
}
.cl-widget .ivu-card-body {
  padding: 10px !important;
}
.cl-widget .ivu-card {
  font-size: 13px !important;
  border-radius:0;
}
.cl-widget .cl-ivu-row {
  padding-bottom: 10px !important;
}
.cl-widget-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.showMoreErrors{
   display: inline;
   margin: 0px !important;
   padding: 0px !important;
}
.showMoreErrors:hover {
  text-decoration: underline;
  cursor: pointer !important;
}
</style>
