<template>
    <div
    class="attachment-display">
        <div
        :class="{'font-color-red': file.status=='Rejected'}"
        v-for="(file, index) in files"
        :key="file.id">
          <div
          class="attachment-block-wrapper"
          v-if="!file.isDeleted">

            <div class="align-tag-right attachment-icon">
                <i v-bind:class="attachmentIcon(file)"></i>
            </div>

            <i-tooltip
            class="tool-tip"
            :content="file.name"
            :disabled="!file.name"
            v-if="!file.isDeleted">
                <div class="align-tag-center">
                    {{uploadedDate(file)}}
                </div>
            </i-tooltip>

            <div class="align-tag-right">
                {{getFileSize(file.size)}}
            </div>
            <div
            class="align-tag-center"
            :class="{'font-color-gray': file.path==''}">
                  <i
                  class="fas fa-download download-file"
                  @click="downloadFile(file)"></i>
            </div>
            <div
            class="align-tag-center"
            :class="{'font-color-gray': file.path==''}">
                  <i
                  class="fa fa-trash delete-file"
                  @click="deleteFile(file, index)"></i>
            </div>

          </div>
        </div>
    </div>
</template>
<script src="~/Content/DeltaX/jquery.fileDownload.js"></script>
<script>
import moment from 'moment';
import {
  locale,
  Tooltip
} from "iview";
import lang from "iview/dist/locale/en-US";
locale(lang);
var apiService = require("../Services/InputListingApiServices.js");

export default {
  components: {
    "i-tooltip": Tooltip
  },
  data() {
    return {
      imageFileExtensions: ['jpg', 'jpeg', 'png', 'gif', 'tiff', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif', 'image/tiff'],
      videoFileExtensions: ['mpeg', 'mov', 'mp4', 'video/mpeg', 'video/mov', 'video/mp4']
    }
  },
  props: {
    files: Array,
    inputId: 0
  },
  methods: {
    attachmentIcon: function (file) {
      if (
        this.imageFileExtensions.includes(file.type)
      ) {
        return 'fas fa-image';
      } else if (
        this.videoFileExtensions.includes(file.type)
        ) {
        return 'fas fa-video-camera';
      } else {
        return 'fas fa-file-pdf-o';
      }
    },
    uploadedDate: function(file) {
      return moment(file.uploadedAt).format('Do MMM, YYYY LT');
    },
    downloadFile: function(file) {
      if (file.path && file.path.trim().length > 0) {
        const downloadFilePromise = apiService.DownloadFile(file.path, file.type);
        Promise.all([downloadFilePromise])
        .then(value => {});
      }
    },
    deleteFile: function(file, index) {
      if (this.inputId == 0) {
        file.isDeleted = true;
        this.$set(this.files, index, file);
        return;
      }

      if (file.id != 0) {
        const deleteFilePromise = apiService.DeleteFile(this.inputId, file.id);
        Promise.all([deleteFilePromise])
        .then(value => {
          file.isDeleted = true;
          this.$set(this.files, index, file);
        });
      }
    },
    getFileSize: function (size) {
      if (!size) {
        return;
      }
      const conversionSize = 1024;
      var formattedSize = size;
      var sizeList = [' B', ' KB', ' MB', ' GB', ' TB'];
      var sizePosition = 0;
      do {
        sizePosition++;
        formattedSize = size;
        size /= conversionSize;
      } while(size > 1 && sizePosition <= 4);
      sizePosition--;

      return formattedSize.toFixed(0) + sizeList[sizePosition];
    }
  }
}
</script>
<style scoped>
.attachment-display > * {
    margin-bottom: 8px;
}

.align-tag-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.align-tag-right {
  text-align: right;
}

.font-color-red {
  color: red;
}

.font-color-gray {
  color: gray;
}

.attachment-block-wrapper {
  display: grid;
  grid-template-columns: 1fr 5fr 2fr 2fr 1fr;
  row-gap: 4px;
}

.tool-tip {
  width: 100% !important;
}

.download-file {
  cursor: pointer;
}

.delete-file {
  cursor: pointer;
}

.attachment-icon {
  margin-right: -16px;
}
</style>
