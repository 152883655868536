import * as fbEnums from "../Constants/FacebookEnums.js";

export default {
  methods: {
  /*
    (╯°□°）╯
      -> When Adding a new property to CampaignConfiguration
      -> Make sure that property is also handled in handleOldFacebookConfigs (handleOldFacebookConfigs.js Ln-no:160)
    (╯°□°）╯
  */
    getDefaultFormConfig () {
      return {
        searchEngineTypeId: 5,
        bpseId: "",
        facebookAccountId: "",
        facebookPageId: "",
        instagramAccountId: "",
        isAdsetBudget: false,
        campaign: {
          name: "",
          buyType: 1,
          bidStrategy: 1,
          deliveryType: "Standard",
          budgetType: 1,
          objective: "",
          budget: 10,
          grouping: 1,
          status: "1",
          productCatalog: "",
          adgroupPerLocation: "all",
          locationMode: "Standard",
          spendCap: undefined,
          isCatalogSegment: false,
          isAdvantagePlusShoppingCampaign: false
        },
        adset: {
          name: "",
          grouping: ["Audience and Placement", "Ad Schedule"],
          status: "1",
          adScheduling: true,
          schedule: {
            type: 1,
            dayParting_schedules: []
          },
          scheduling: {
            option: "Run continuously starting today",
            startDate: "",
            endDate: ""
          },
          bidCap: 0,
          costCap: 0,
          spendLimit: {
            option: 1,
            min: "",
            max: ""
          },
          isTargetOptimizationEnabled: false,
          isDynamicCreative: false,
          mobileDevices: "All Mobile devices",
          onlyConnectedToWifi: false,
          selectedPlacementOption: "Automatic",
          targetSelection: "Individually",
          placements: [
            {
              title: "Facebook",
              checked: true,
              disabled: false,
              children: [
                { title: "Feed" },
                { title: "Search" },
                { title: "Right hand column" },
                { title: "Marketplace" },
                { title: "Story" },
                { title: "Video feeds" },
                { title: "Instream video" },
                { title: "Reels" }
              ]
            },
            {
              title: "Instagram",
              checked: false,
              disabled: false,
              children: [
                { title: "Stream" },
                { title: "Story" },
                { title: "Explore" },
                { title: "Reels" }
              ]
            },
            {
              title: "Audience network",
              disabled: false,
              checked: false,
              children: [
                { title: "Classic" },
                { title: "Instream video" },
                { title: "Rewarded video" }
              ]
            },
            {
              title: "Messenger",
              disabled: false,
              checked: false,
              children: [
                { title: "Home" },
                { title: "Sponsored messages" },
                { title: "Story" }
              ]
            }
          ],
          selectedAudienceType: "Prospecting",
          targetProductAudience: {
            inclusions: [
              {
                value: "ViewContent",
                days: 1
              }
            ],
            exclusions: [
              {
                value: "Purchase",
                days: 1
              }
            ]
          },
          devices: ["Mobile", "Desktop"],
          optimizeFor: "",
          billingEvent: "",
          trackingPixel: "",
          useAdLinkForConvDomain: true,
          conversionDomain: "",
          appEvent: "",
          offlineEvent: "",
          conversionPixel: "",
          customEventType: "",
          customConversion: {
            id: "",
            name: "",
            pixelId: "",
            customEventType: "",
            pixelRule: ""
          },
          app: {
            appId: null,
            storeUrl: ""
          },
          reachAndFrequencyBalance: {
            type: 2,
            cap: "2",
            days: "7"
          },
          adformat: ["Image or Carousel"],
          targeting: {
            userDevice: [],
            userOs: [],
            excludedUserDevice: [],
            isAndroidOs: null,
            minOsVersion: "2.0",
            maxOsVersion: ""
          },
          destination: 10,
          clickAttributionWindow: null,
          viewAttributionWindow: null,
          existingCustomersBudgetPercentage: null,
          dsaBeneficiary: "",
          dsaPayor: "",
          isPayorDifferent: false,
          excludeInstreamSkippableAds: false,
          roas: 0
        },
        ad: {
          dynamicAdVoice: "1",
          status: "1",
          messageTemplate: {
            id: "",
            name: "",
            messageBody: {}
          }
        },
        name: "",
        budgetLevel: 1,
        kpiMetricId: null,
        storeLeadFormId: 0
      };
    },
    handleOldFacebookConfigs (config) {
      // this is to support older configs
      try {
        config.adset.placements
          .find(x => x.title == "Instagram")
          .children.find(x => x.title == "Feed")
          .title = "Stream"
      } catch (err) { }
      this.handleOldPlacement(config.adset.placements, 'Instagram', 'Reels')
      this.handleOldPlacement(config.adset.placements, 'Facebook', 'Reels')
      config.adset.pageActorOverride = config.adset.pageActorOverride || false;
      config.adset.status = config.adset.status == undefined ? "1" : config.adset.status
      config.adset.isTargetOptimizationEnabled = config.adset.isTargetOptimizationEnabled || false;
      config.adset.adformat = config.adset.adformat || ["Video"];
      config.adset.spendLimit.option = config.adset.spendLimit.option || 1;
      config.adset.app = config.adset.app || { appId: null, storeUrl: "" }
      config.adset.targetSelection = config.adset.targetSelection || "Individually";
      config.adset.isDynamicCreative = config.adset.isDynamicCreative || false;
      config.adset.reachAndFrequencyBalance = config.adset.reachAndFrequencyBalance != undefined ? config.adset.reachAndFrequencyBalance : { type: 2, cap: "", days: "" };
      config.ad = config.ad ? config.ad : { status: "1" }
      config.ad.dynamicAdVoice = config.ad.dynamicAdVoice || "1";
      config.campaign.status = config.campaign.status ? config.campaign.status : "1"
      config.campaign.adgroupPerLocation = config.campaign.adgroupPerLocation ? config.campaign.adgroupPerLocation : "all"
      config.campaign.locationMode = config.campaign.locationMode ? config.campaign.locationMode : "Standard"
      config.adset.targeting = config.adset.targeting || { userDevice: [], userOs: [], excludedUserDevice: [], isAndroidOs: null, minOsVersion: "2.0", maxOsVersion: "" }
      config.adset.destination = config.adset.destination || fbEnums.destinationType.Undefined
      config.adset.adScheduling = config.adset.adScheduling != false
      config.adset.clickAttributionWindow = config.adset.clickAttributionWindow ? config.adset.clickAttributionWindow : null
      config.adset.viewAttributionWindow = config.adset.viewAttributionWindow ? config.adset.viewAttributionWindow : null
      config.adset.costCap = config.adset.costCap || 0;
      config.adset.conversionDomain = config.adset.conversionDomain || "";
      config.adset.dsaBeneficiary = config.adset.dsaBeneficiary || "";
      config.adset.dsaPayor = config.adset.dsaPayor || "";
      config.adset.isPayorDifferent = config.adset.isPayorDifferent || false;
      config.campaign.spendCap = config.campaign.spendCap || undefined;
      config.campaign.isCatalogSegment = config.campaign.isCatalogSegment || false;
      config.isAdsetBudget = config.isAdsetBudget || false;
      config.budgetLevel = config.budgetLevel || 1;
      config.campaign.isAdvantagePlusShoppingCampaign = config.campaign.isAdvantagePlusShoppingCampaign != undefined ? config.campaign.isAdvantagePlusShoppingCampaign : false;
      config.adset.existingCustomersBudgetPercentage = config.adset.existingCustomersBudgetPercentage != undefined ? config.adset.existingCustomersBudgetPercentage : null;
      if (config.adset.targetingType == "1") {
        config.campaign.locationMode = "PageSets";
        delete config.adset.targetingType;
      }
      if (!config.adset.hasOwnProperty('useAdLinkForConvDomain')) {
        if (config.adset.conversionDomain) {
          config.adset.useAdLinkForConvDomain = false;
        } else {
          config.adset.useAdLinkForConvDomain = true;
        }
      }
      if (config.adset.adScheduling) {
        if (config.adset.grouping) {
          if (config.adset.grouping.indexOf("Ad Schedule") < 0) {
            config.adset.grouping.push("Ad Schedule")
          }
        } else {
          config.adset.grouping = ["Audience and Placement", "Ad Schedule"]
        }
      } else {
        var index = config.adset.grouping.indexOf("Ad Schedule");
        if (index > -1) {
          config.adset.grouping.splice(index, 1);
        }
      }
      if (
        config.adset.destination == fbEnums.destinationType.Undefined &&
        [fbEnums.objectives.Traffic, fbEnums.objectives.Conversions].includes(config.campaign.objective)
      ) {
        config.adset.destination = fbEnums.destinationType.Page;
      }
      config.ad.messageTemplate = config.ad.messageTemplate || { id: "", name: "", messageBody: {} }
      config.adset.schedule = config.adset.schedule || { type: 1,
        dayParting_schedules: [] }

      var customConversion = {
        id: "",
        name: "",
        pixelId: "",
        customEventType: "",
        pixelRule: ""
      }
      config.adset.customConversion = config.adset.customConversion || customConversion;

      if (config.adset.placements
        .find(x => x.title == "Facebook")
        .children.find(x => x.title == "Instant article")) {
        config.adset.placements.find(x => x.title == "Facebook").children = config.adset.placements
          .find(x => x.title == "Facebook").children.filter(x => x.title != "Instant article")
      }

      return config;
    },
    isInstagramPlacementSelected (config) {
      if (config.adset.selectedPlacementOption == 'Automatic') {
        return true;
      }
      let instaPlacement = config.adset.placements.find((placement) => {
        return placement.title == "Instagram"
      });
      return instaPlacement.checked || instaPlacement.children.some(child => child.checked);
    },
    getAttributionClicks (config, optimizationGoalsEnum) {
      let goal = config.adset.optimizeFor;
      var windowValues = [];
      if (!config.campaign.objective || !goal) {
        return windowValues;
      }

      var goalName = optimizationGoalsEnum[goal]
      windowValues = [1];
      if (this.isConversionsObjective(config) || this.isDPAObjective(config)) {
        if (goalName == "Conversions" || goalName == "Value") {
          windowValues = [1, 7]
        }
      }
      if (this.isAppInstallsObjective(config)) {
        if (goalName == "Conversions") {
          windowValues = [1, 7]
        }
        if (goalName == "App Installs") {
          windowValues = [1]
        }
      }
      return windowValues
    },
    getAttributionViews (config, optimizationGoalsEnum) {
      let goal = config.adset.optimizeFor;
      var windowValues = [];
      if (!config.campaign.objective || !goal) {
        return windowValues;
      }

      var goalName = optimizationGoalsEnum[goal]
      if (this.isConversionsObjective(config) || this.isDPAObjective(config)) {
        if (goalName == "Conversions") {
          windowValues = [1]
        }
      }
      if (this.isAppInstallsObjective(config)) {
        if (goalName == "App Installs") {
          windowValues = [1]
        }
      }
      return windowValues
    },
    invalidBidStratergy (bidStrategy, config) {
      return bidStrategy == this.fbEnums.bidStrategy['Lowest Cost with Cost Cap'] && (this.isBrandAwarenessObjective(config) || this.isReachObjective(config) || this.isMessagesObjective(config) || this.isStoreVisitsObjective(config))
    },
    invalidDeliveryType (deliveryType, bidStrategy) {
      return (bidStrategy === this.fbEnums.bidStrategy['Lowest Cost with Cost Cap'] && deliveryType != 'Standard') || (bidStrategy === this.fbEnums.bidStrategy['Lowest Cost'] && deliveryType == 'Accelerated')
    },
    getValidAdsetPlacements (placementsConfig) {
      var allPlacements = this.getDefaultFormConfig().adset.placements;
      var newPlacements = [];
      allPlacements.forEach(e => {
        if (placementsConfig[e.title]) {
          var clonedPlacementObject = JSON.parse(JSON.stringify(e));
          clonedPlacementObject.children = clonedPlacementObject.children.filter(f => placementsConfig[e.title].includes(f.title))
          newPlacements.push(clonedPlacementObject);
        }
      })
      return newPlacements;
    },
    isCatalogSupportedForConfig (configObject, isObjectiveCheckOnly) {
      return (configObject && configObject.campaign && configObject.campaign.objective &&
        (this.isAppInstallsObjective(configObject) ||
        this.isDPAObjective(configObject, isObjectiveCheckOnly) ||
        this.isConversionsObjective(configObject)))
    },
    getConfigWithMacros (config, promoName) {
      const macrosToInsert = {
        bpseId: "{{store.bpseId}}",
        facebookAccountId: "{{store.adaccountid}}",
        facebookPageId: "{{store.facebookpageid}}",
        instagramAccountId: "{{store.instagramaccountid}}",
        "adset.trackingPixel": "{{store.trackingPixel}}",
        "adset.conversionDomain": "{{store.conversionDomain}}",
        "adset.appEvent": "{{store.appId}}"
      };

      let configCopy = JSON.parse(JSON.stringify(config));

      for (let [path, macroStr] of Object.entries(macrosToInsert)) {
        let toReplace = path.split('.').slice(0, -1).reduce((cur, key) => cur[key], configCopy);
        toReplace[path.split('.').slice(-1)[0]] = macroStr
      }

      return JSON.stringify(configCopy)
    },
    isStoreVisitsObjective (config) {
      return config && config.campaign && config.campaign.objective == fbEnums.objectives["Store Visits"]
    },
    isVideoViewsObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Video Views"] ||
        (config.campaign.objective == fbEnums.objectives.Engagement && config.adset &&
          config.adset.destination == fbEnums.destinationType.Video))
    },
    isLeadGenerationObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Lead Generation"] ||
        (config.campaign.objective == fbEnums.objectives.Leads &&
          (config.adset && config.adset.destination == fbEnums.destinationType.PhoneCall)) ||
          (config.campaign.objective == fbEnums.objectives.Leads &&
            (config.adset && config.adset.destination == fbEnums.destinationType.Ad)) ||
            (config.campaign.objective == fbEnums.objectives.Leads &&
              (config.adset && config.adset.destination == fbEnums.destinationType.LeadFormMessenger)))
    },
    isAppInstallsObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["App Installs"] ||
          config.campaign.objective == fbEnums.objectives["App Promotion"])
    },
    isDPAObjective (config, isObjectiveCheckOnly) {
      if (isObjectiveCheckOnly) {
        return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Product Catalog Sales"] ||
          (config.campaign.objective == fbEnums.objectives.Sales))
      } else {
        return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Product Catalog Sales"] ||
          (config.campaign.objective == fbEnums.objectives.Sales && config.campaign.productCatalog))
      }
    },
    isAdlevelProductSetConfig (config) {
      return config && config.campaign && (config.campaign.objective == fbEnums.objectives.Sales && config.campaign.productCatalog)
    },
    isPostEngagementObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Post Engagement"] ||
        (config.campaign.objective == fbEnums.objectives.Engagement &&
          config.adset.destination == fbEnums.destinationType.Post))
    },
    isTrafficObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives.Traffic ||
          config.campaign.objective == fbEnums.objectives["Traffic Outcome"])
    },
    isConversionsObjective (config) {
      if (config && config.campaign) {
        if (config.campaign.objective == fbEnums.objectives.Conversions) { return true; }
        if (config.adset) {
          if (config.campaign.objective == fbEnums.objectives.Engagement &&
              (config.adset.destination == fbEnums.destinationType.Page ||
              config.adset.destination == fbEnums.destinationType.App)) {
            return true;
          }
          if ((config.campaign.objective == fbEnums.objectives.Leads ||
              (config.campaign.objective == fbEnums.objectives.Sales && !config.campaign.productCatalog)) &&
                (config.adset.destination == fbEnums.destinationType.Page ||
                config.adset.destination == fbEnums.destinationType.App ||
                config.adset.destination == fbEnums.destinationType.Messenger ||
                config.adset.destination == fbEnums.destinationType.WhatsApp)) {
            return true;
          }
        }
      }
      return false;
    },
    isReachObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives.Reach ||
          config.campaign.objective == fbEnums.objectives.Awareness)
    },
    isBrandAwarenessObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Brand Awareness"] ||
          config.campaign.objective == fbEnums.objectives.Awareness)
    },
    isPageLikesObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Page Likes"] ||
        (config.campaign.objective == fbEnums.objectives.Engagement &&
          config.adset.destination == fbEnums.destinationType.OnPage))
    },
    isEventResponsesObjective (config) {
      return config && config.campaign &&
        (config.campaign.objective == fbEnums.objectives["Event Responses"] ||
        (config.campaign.objective == fbEnums.objectives.Engagement &&
          config.adset.destination == fbEnums.destinationType.OnEvent))
    },
    isMessagesObjective (config) {
      return config && config.campaign && (config.campaign.objective == fbEnums.objectives.Messages ||
        (config.campaign.objective == fbEnums.objectives.Engagement &&
          (config.adset.destination == fbEnums.destinationType.Messenger ||
            config.adset.destination == fbEnums.destinationType.WhatsApp)))
    },
    getOptimizationGoalsByObjective (config) {
      if (!config || !config.campaign) return null;
      switch (config.campaign.objective) {
        case fbEnums.objectives["Page Likes"]: return { "auction": [12] }
        case fbEnums.objectives["Event Responses"]: return { "auction": [6, 7, 13, 14] }
        case fbEnums.objectives["Post Engagement"]: return { "auction": [7, 13, 14], "reachAndFrequency": [13] }
        case fbEnums.objectives.Conversions: return { "auction": [7, 8, 10, 14, 20], "reachAndFrequency": [10] }
        case fbEnums.objectives.Traffic: return { "auction": [7, 8, 14, 20], "reachAndFrequency": [8] }
        case fbEnums.objectives["Video Views"]: return { "auction": [24, 27], "reachAndFrequency": [24, 27] }
        case fbEnums.objectives["Product Catalog Sales"]: return { "auction": [7, 8, 10, 13, 14] }
        case fbEnums.objectives["Lead Generation"]: return { "auction": [17, 30] }
        // case fbEnums.objectives["Leads"]: return { "auction": [7, 8, 10, 20] }
        case fbEnums.objectives["App Installs"]:
          if (config.campaign.isAutomatedAppAdsEnabled) {
            return { "auction": [29, 2, 8, 22] };
          } else {
            return { "auction": [22, 31, 2, 26] }
          }
        case fbEnums.objectives["Brand Awareness"]: return { "auction": [21], "reachAndFrequency": [21] }
        case fbEnums.objectives["Store Visits"]: return { "auction": [14, 25] }
        case fbEnums.objectives.Reach: return { "auction": [7, 14], "reachAndFrequency": [7] }
        case fbEnums.objectives.Messages: return { "auction": [17, 23] }
        case fbEnums.objectives.Awareness: return { "auction": [7, 14, 21, 24, 27], "reachAndFrequency": [21, 7, 14] }
        case fbEnums.objectives.Engagement:
          if (!config.adset) return null;
          switch (config.adset.destination) {
            case fbEnums.destinationType.Video: return { "auction": [27, 24] }
            case fbEnums.destinationType.Post: return { "auction": [7, 13] }
            case fbEnums.destinationType.OnEvent: return { "auction": [7, 13, 6] }
            case fbEnums.destinationType.Messenger: return { "auction": [8, 23] }
            case fbEnums.destinationType.WhatsApp: return { "auction": [8] }
            case fbEnums.destinationType.Page: return { "auction": [7, 20, 8, 10] }
            case fbEnums.destinationType.App: return { "auction": [29, 8] }
            case fbEnums.destinationType.OnPage: return { "auction": [12] }
            default: return null
          }
        case fbEnums.objectives.Leads:
          if (!config.adset) return null;
          switch (config.adset.destination) {
            case fbEnums.destinationType.Page: return { "auction": [7, 20, 8, 10] }
            case fbEnums.destinationType.PhoneCall: return { "auction": [30] }
            case fbEnums.destinationType.App: return { "auction": [29, 8] }
            case fbEnums.destinationType.Ad: return { "auction": [17] }
            case fbEnums.destinationType.LeadFormMessenger: return { "auction": [17] }
            default: return { "auction": [7, 20, 8, 10, 17, 29, 30] }
          }
        case fbEnums.objectives.Sales:
          if (!config.adset) return null;
          if (config.campaign.productCatalog) return { "auction": [8, 10] }
          switch (config.adset.destination) {
            case fbEnums.destinationType.Page: return { "auction": [7, 20, 8, 10, 22] }
            case fbEnums.destinationType.Messenger: return { "auction": [7, 23, 8, 10] }
            case fbEnums.destinationType.WhatsApp: return { "auction": [7, 8, 10] }
            case fbEnums.destinationType.App: return { "auction": [29, 8] }
            default: return null
          }
        case fbEnums.objectives["Traffic Outcome"]:
          if (!config.adset) return null;
          switch (config.adset.destination) {
            case fbEnums.destinationType.Page: return { "auction": [7, 20, 8] }
            case fbEnums.destinationType.Messenger: return { "auction": [7, 8] }
            case fbEnums.destinationType.WhatsApp: return { "auction": [7, 8] }
            case fbEnums.destinationType.App: return { "auction": [7, 8] }
            case fbEnums.destinationType.PhoneCall: return { "auction": [30, 8] }
            default: return null
          }
        case fbEnums.objectives["App Promotion"]:
          if (config.campaign.isAutomatedAppAdsEnabled) {
            return { "auction": [2, 29] };
          } else {
            return { "auction": [2, 29, 4] }
          }
        default: return null
      }
    },
    getBillingEventsByObjective (config) {
      if (!config || !config.campaign) return null;
      switch (config.campaign.objective) {
        case fbEnums.objectives["Page Likes"]: return { "auction": [3, 6] }
        case fbEnums.objectives["Event Responses"]: return { "auction": [3] }
        case fbEnums.objectives["Post Engagement"]: return { "auction": [3], "reachAndFrequency": [3] }
        case fbEnums.objectives.Conversions: return { "auction": [3], "reachAndFrequency": [3] }
        case fbEnums.objectives.Traffic: return { "auction": [3, 4], "reachAndFrequency": [3] }
        case fbEnums.objectives["Video Views"]: return { "auction": [3, 13, 15], "reachAndFrequency": [3, 13, 15] }
        case fbEnums.objectives["Product Catalog Sales"]: return { "auction": [3, 4] }
        case fbEnums.objectives["Lead Generation"]: return { "auction": [3, 4] }
        case fbEnums.objectives["App Installs"]: return { "auction": [3, 4] }
        case fbEnums.objectives["Brand Awareness"]: return { "auction": [3], "reachAndFrequency": [3] }
        case fbEnums.objectives["Store Visits"]: return { "auction": [3] }
        case fbEnums.objectives.Reach: return { "auction": [3], "reachAndFrequency": [3] }
        case fbEnums.objectives.Messages: return { "auction": [3] }
        case fbEnums.objectives.Awareness: return { "auction": [3, 13, 15], "reachAndFrequency": [3] }
        case fbEnums.objectives.Engagement:
          if (!config.adset) return null;
          switch (config.adset.optimizeFor) {
            case 27: return { "auction": [3, 15] } // thru play
            case 24: return { "auction": [3, 13] } // 2 second views
            case 12: return { "auction": [3, 6] } // page likes
            case 8: return { "auction": [3, 4] } // link clicks
            default: return { "auction": [3] }
          }
        case fbEnums.objectives.Leads:
          if (!config.adset) return null;
          switch (config.adset.optimizeFor) {
            case 8: return { "auction": [3, 4] } // link clicks
            default: return { "auction": [3] }
          }
        case fbEnums.objectives.Sales:
          if (!config.adset) return null;
          switch (config.adset.optimizeFor) {
            case 8: return { "auction": [3, 4] } // link clicks
            default: return { "auction": [3] }
          }
        case fbEnums.objectives["Traffic Outcome"]:
          if (!config.adset) return null;
          switch (config.adset.optimizeFor) {
            case 8: return { "auction": [3, 4] } // link clicks
            default: return { "auction": [3] }
          }
        case fbEnums.objectives["App Promotion"]: return { "auction": [3] }
        default: return null
      }
    },
    getDynamicCreatives (config) {
      if (!config || !config.campaign) return null;
      switch (config.campaign.objective) {
        case fbEnums.objectives.Conversions: return ['single']; // conversions
        case fbEnums.objectives.Traffic: return ['single']; // traffic
        case fbEnums.objectives["Lead Generation"]: return ['single']; // Lead gen
        case fbEnums.objectives["App Installs"]: return ['single']; // App Install
        case fbEnums.objectives["App Promotion"]:
          if (!config.campaign.productCatalog && config.campaign.isAutomatedAppAdsEnabled) return ['single'] // App Promotion
          else return null
        case fbEnums.objectives.Engagement:
        case fbEnums.objectives.Sales:
          if (!config.campaign.productCatalog) return ['single']
          else return null
        case fbEnums.objectives.Reach: return ['single'];
        case fbEnums.objectives.Awareness: return ['single'];
        case fbEnums.objectives.Leads: return ['single'];
        case fbEnums.objectives["Traffic Outcome"]:
          if (!config.adset) return null;
          switch (config.adset.destination) {
            case fbEnums.destinationType.Page:
            case fbEnums.destinationType.App: return ['single']
            default: return null
          }
        default: return null
      }
    },
    getPlacementCustomizations (config) {
      if (!config || !config.campaign) return null;
      switch (config.campaign.objective) {
        case fbEnums.objectives.Conversions: return ['single']; // conversions
        case fbEnums.objectives.Traffic: return ['single']; // traffic
        case fbEnums.objectives["Video Views"]: return ['single']; // videoviews
        case fbEnums.objectives["App Installs"]: return ['single']; // App Install
        case fbEnums.objectives.Reach: return ['single'] // Reach
        case fbEnums.objectives.Awareness:
        case fbEnums.objectives["Brand Awareness"]: return ['single'] // Awareness
        case fbEnums.objectives["App Promotion"]:
          if (!config.campaign.productCatalog) return ['single'] // App Promotion
          else return null
        case fbEnums.objectives.Engagement:
        case fbEnums.objectives.Sales:
        case fbEnums.objectives.Leads: return ['single']
        case fbEnums.objectives["Traffic Outcome"]:
          if (!config.adset) return null;
          switch (config.adset.destination) {
            case fbEnums.destinationType.Page:
            case fbEnums.destinationType.App: return ['single']
            default: return null
          }
        default: return null
      }
    },
    isOdaxConfig (config) {
      if (config && config.campaign) {
        switch (config.campaign.objective) {
          case fbEnums.objectives.Awareness:
          case fbEnums.objectives.Engagement:
          case fbEnums.objectives.Leads:
          case fbEnums.objectives.Sales:
          case fbEnums.objectives["Traffic Outcome"]:
          case fbEnums.objectives["App Promotion"]:
            return true;
          default: return false;
        }
      }
      return false;
    },
    getLanguageCustomizations (config) {
      if (!config || !config.campaign) return null;
      switch (config.campaign.objective) {
        case fbEnums.objectives.Traffic: return ['single']; // traffic
        default: return null
      }
    },
    convertKeysToPascalCase (object) {
      var newObject = {};
      Object.keys(object).forEach(key => {
        var arr = []
        key.split('_').forEach(str => {
          arr.push(str.charAt(0).toUpperCase() + str.slice(1))
        })
        var keyName = arr.join('')
        newObject[keyName] = object[key]
      })
      return newObject;
    },
    isInstantExperiencesSupported (config) {
      return this.isTrafficObjective(config) ||
        this.isConversionsObjective(config) ||
        this.isDPAObjective(config, true);
    },
    creativeEnhancements (row, spec) {
      var headerValMap = {}
      for (let i = 0; i < this.colHeaders.length; i++) {
        headerValMap[this.colHeaders[i]] = row[i];
      }
      if (!this.isPromotionMode && this.isTrafficObjective(this.selectedCampaignLauncherConfig)) {
        const creativeFeatureSpec = {
          "creative_features_spec": {}
        }
        const ManualOPTIN = {
          "action_metadata": {
            "type": "MANUAL"
          },
          "enroll_status": "OPT_IN"
        }
        const ManualOPTOUT = {
          "action_metadata": {
            "type": "MANUAL"
          },
          "enroll_status": "OPT_OUT"
        }
        const DefaultOPTIN = {
          "action_metadata": {
            "type": "MANUAL"
          },
          "enroll_status": "OPT_IN"
        }
        const DefaultOPTOUT = {
          "action_metadata": {
            "type": "MANUAL"
          },
          "enroll_status": "OPT_OUT"
        }

        if (headerValMap["HOT_Standard_Enhancements"] == 'Enable') {
          creativeFeatureSpec.creative_features_spec["standard_enhancements"] = ManualOPTIN;
          creativeFeatureSpec.creative_features_spec["image_templates"] = ManualOPTIN;
          creativeFeatureSpec.creative_features_spec["image_touchups"] = ManualOPTIN;
          creativeFeatureSpec.creative_features_spec["text_optimizations"] = ManualOPTIN;
          creativeFeatureSpec.creative_features_spec["inline_comment"] = ManualOPTIN;
        } else if (headerValMap["HOT_Standard_Enhancements"] == 'Disable') {
          creativeFeatureSpec.creative_features_spec["standard_enhancements"] = ManualOPTOUT;
          creativeFeatureSpec.creative_features_spec["image_templates"] = ManualOPTOUT;
          creativeFeatureSpec.creative_features_spec["image_touchups"] = ManualOPTOUT;
          creativeFeatureSpec.creative_features_spec["text_optimizations"] = ManualOPTOUT;
          creativeFeatureSpec.creative_features_spec["inline_comment"] = ManualOPTOUT;
        }

        if (headerValMap["HOT_Image_Enhancements"] == 'Enable') {
          creativeFeatureSpec.creative_features_spec["image_enhancement"] = DefaultOPTIN;
        } else if (headerValMap["HOT_Image_Enhancements"] == 'Disable') {
          creativeFeatureSpec.creative_features_spec["image_enhancement"] = DefaultOPTOUT;
        }

        if (headerValMap["HOT_3d_Animation"] == 'Enable') {
          creativeFeatureSpec.creative_features_spec["cv_transformation"] = DefaultOPTIN;
        } else if (headerValMap["HOT_3d_Animation"] == 'Disable') {
          creativeFeatureSpec.creative_features_spec["cv_transformation"] = DefaultOPTOUT;
        }

        if (headerValMap["HOT_Music"] == 'Enable') {
          if (spec["creative"]["asset_feed_spec"]) {
            spec["creative"]["asset_feed_spec"]["audios"] = [ { "type": "RANDOM" } ];
          } else {
            spec["creative"]["asset_feed_spec"] = { "audios": [ { "type": "RANDOM" } ] };
          }
        } else if (headerValMap["HOT_Music"] == 'Disable') {
          if (spec["creative"]["asset_feed_spec"]) {
            spec["creative"]["asset_feed_spec"]["audios"] = [ { "type": "OPTED_OUT" } ];
          } else {
            spec["creative"]["asset_feed_spec"] = { "audios": [ { "type": "OPTED_OUT" } ] };
          }
        }

        if (Object.keys(creativeFeatureSpec.creative_features_spec).length > 0) {
          spec["creative"]["degrees_of_freedom_spec"] = creativeFeatureSpec;
        }
      }
    },
    handleOldPlacement (placementObject, publisher, placement) {
      var instagramPlacements = placementObject
        .find(x => x.title == publisher).children;
      var reels = instagramPlacements.find(x => x.title == placement);
      var reelsExists = reels && reels.title;
      if (!reelsExists) {
        instagramPlacements.push({ title: placement });
      }
    }
  }
}
