<template>
<div>
  <Button
  class="btn btn-white button-wrapper"
  @click="openGoogleDrive">
    <i-icon
    size="20"
    color="#7d7f7f"
    type="logo-google"
    class="picker-api-auth-btn"/>
    <span
    class="drive-tag"
    id="result">Drive</span>
  </Button>
</div>
</template>

<script>
/* global gapi, google */
import {
  Icon
} from "iview";

export default {
  components: {
    "i-icon": Icon
  },
  data () {
    return {
      developerKey: deltax.gSignInDeveloperKey,
      clientId: deltax.gSignInClientId,
      appId: deltax.gSignInAppId,
      scope: [
        "https://www.googleapis.com/auth/drive",
        "https://www.googleapis.com/auth/drive.file",
        "https://www.googleapis.com/auth/drive.readonly",
        "https://www.googleapis.com/auth/drive.metadata.readonly",
        "https://www.googleapis.com/auth/drive.appdata",
        "https://www.googleapis.com/auth/drive.apps.readonly",
        "https://www.googleapis.com/auth/drive.metadata",
        "https://www.googleapis.com/auth/drive.photos.readonly"
      ],
      pickerApiLoaded: false,
      oauthToken: ""
    };
  },
  mounted () {
    let gDrive = document.createElement("script");
    gDrive.setAttribute("type", "text/javascript");
    gDrive.setAttribute("src", "https://apis.google.com/js/api.js");
    document.head.appendChild(gDrive);
  },
  methods: {
    downloadFile (url, fileName) {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.setRequestHeader("Authorization", "Bearer " + this.oauthToken);
      xhr.responseType = "blob";
      xhr.onload = () => {
        let file = new File([xhr.response], fileName, {
          type: xhr.response.type
        });
        this.$emit("filesDownloaded", file);
      };
      xhr.onerror = () => {
        console.log(`An error occurred while downloading ${fileName} - ${url}`);
      };
      xhr.send();
    },
    openGoogleDrive () {
      gapi.load('auth', { 'callback': this.onAuthApiLoad });
      gapi.load('picker', { 'callback': this.onPickerApiLoad });
    },
    onAuthApiLoad () {
      window.gapi.auth.authorize(
        {
          'client_id': this.clientId,
          'scope': this.scope,
          'immediate': false
        },
        this.handleAuthResult);
    },
    onPickerApiLoad () {
      this.pickerApiLoaded = true;
    },
    handleAuthResult (authResult) {
      if (authResult && !authResult.error) {
        this.oauthToken = authResult.access_token;
        this.createPicker();
      }
    },
    createPicker () {
      if (this.pickerApiLoaded && this.oauthToken) {
        var view = new google.picker.View(google.picker.ViewId.DOCS);
        view.setMimeTypes("image/png,image/jpeg,image/jpg");
        var picker = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          .setAppId(this.appId)
          .setOAuthToken(this.oauthToken)
          .addView(view)
          .addView(new google.picker.DocsUploadView())
          .setDeveloperKey(this.developerKey)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },
    pickerCallback (data) {
      if (data.action == google.picker.Action.PICKED) {
        data.docs.forEach(doc => {
          let fileId = doc.id;
          let fileName = doc.name;
          this.downloadFile(
            `https://www.googleapis.com/drive/v2/files/${fileId}?alt=media`,
            fileName
          );
        });
      }
    }

  }
}
</script>
<style scoped>
.button-wrapper {
  margin-top: 24px;
}
.picker-api-auth-btn {
  margin-right: 8px !important;
  display: inline;
}
.drive-tag {
  vertical-align: middle !important;
  position: relative;
  top: 1px;
}
.button-wrapper {
    cursor: pointer;
}
</style>
